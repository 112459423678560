import React, {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import "./PolicyStageOne.css";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import TextareaInput from "../../../../components/input-components/textarea-input/TextareaInput";
import { getSumInsured } from "../../../../utils/getSumInsured";
import { allConveyances } from "../../../../utils/allConveyances";
import {
  ISingleUser,
  UserContext,
} from "../../../../components/contexts/UserContext";
import isEmpty from "../../../../validation/isEmpty";
import { ISinglePolicyPlan } from "../policy-stage-three/PolicyStageThree";
import { allClauseTypes } from "../../../../utils/allClauseTypes";
import { allVessels } from "../../../../utils/allVessels";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getSailLocations } from "../../../../redux/actions/sailLocationActions";
import {
  IAllCargosInputStates,
  ICargoXlsxJson,
  IDropdownOption,
  ISailLocation,
  SetErrorHandlerType,
} from "../../../../Types";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import { allCurrencyOptionArr } from "../../../../utils/currencyOptionsArr";
import { handleParseXlsxToJson } from "../../../../utils/handleParseXlsxToJson";
import { handleFormatNumberInputs } from "../../../../utils/handleFormatNumberInputs";
import { getInsurancePremium } from "../../../../utils/generatePremium";
import { Link } from "react-router-dom";

// Interfaces
interface IProps {
  companies: [] | ISingleUser[];
  companyName: string;
  selectedCompanyFromBroker: any;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  subsidiaryName: string;
  setSubsidiaryName: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCompanyFromBroker: React.Dispatch<
    React.SetStateAction<ISingleUser | null>
  >;
  setBrokerCompanyId: React.Dispatch<React.SetStateAction<string>>;
  allCargosInputStates: IAllCargosInputStates[];
  policyType: string;
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  setPolicyType: React.Dispatch<React.SetStateAction<string>>;
  currentActiveCargoIndex: number;
  allCompaniesNames: IDropdownOption[] | null;
  allSubsidiariesNames: IDropdownOption[] | null;
  setCargoJSONArr: React.Dispatch<React.SetStateAction<[] | ICargoXlsxJson[]>>;
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

function PolicyStageOne({
  companies,
  companyName,
  selectedCompanyFromBroker,
  setCompanyName,
  subsidiaryName,
  setSubsidiaryName,
  setSelectedCompanyFromBroker,
  setBrokerCompanyId,
  allCargosInputStates,
  policyType,
  setAllCargosInputStates,
  setPolicyType,
  currentActiveCargoIndex,
  allCompaniesNames,
  allSubsidiariesNames,
  setCargoJSONArr,
  pageContent,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  const sailLocations = useAppSelector(
    (state) => state.sailLocations.sailLocations
  );
  // const companies: ISingleUser[] | [] = useAppSelector(
  //   (state) => state.companies.companies
  // );
  const dispatch = useAppDispatch();
  // States
  const [allConveyancesArr, setAllConveyancesArr] = useState<
    IDropdownOption[] | null
  >(null);
  const [allUsersClauses, setAllUsersClauses] = useState<
    ISinglePolicyPlan[] | null
  >(null);
  const [allClausesArr, setAllClausesArr] = useState<IDropdownOption[] | null>(
    null
  );
  const [allVesselArr, setAllVesselArr] = useState<IDropdownOption[] | null>(
    null
  );
  const [allPortsLocation, setAllPortsLocation] = useState<
    IDropdownOption[] | null
  >(null);
  const [totalNairaEquivalent, setTotalNairaEquivalent] = useState<
    string | number
  >("");
  const [isLoading, setIsLoading] = useState(false);
  //

  //   Functions
  //   Handle get premium price on cargo
  // const handleGetPremiumAmountOnCargo = function (
  //   currentCargoSumInsuredAmount: number,
  //   cargoClauseRate: number
  // ) {
  //   const premiumAmount =
  //     currentCargoSumInsuredAmount * (cargoClauseRate / 100);
  //   return premiumAmount.toFixed();
  // };

  //   Handle get sum insured amount on cargo
  const handleGetSumInsuredAmountOnCargo = function (
    cargoValue: number,
    cargoExchangeRate: number,
    cargoCurrency: string
  ) {
    const sumInsuredAmount = getSumInsured(
      cargoValue,
      cargoExchangeRate,
      cargoCurrency === "NGN"
    );
    return sumInsuredAmount;
  };

  // handle Navigate To Page Two
  const handleNavigateToPageTwo = function (e: MouseEvent<HTMLButtonElement>) {
    setErrorHandlerObj({
      hasError: false,
      message: "",
    });
    // For non-cargo input states
    // For all cargo inputs
    const currentActiveCargo = allCargosInputStates[currentActiveCargoIndex];
    const isAllCargoFormEmpty = Object.keys(currentActiveCargo)?.some(
      (inputKeys) => {
        return (
          currentActiveCargo[inputKeys as keyof typeof currentActiveCargo] ===
          ""
        );
      }
    );

    // Is cargo Owner Name Empty (company for brokers and subsidiary for company)
    const isCargoOwnerNameEmpty =
      user?.customerType === "Broker"
        ? companyName === ""
        : subsidiaryName === "";

    if (!isAllCargoFormEmpty && !isCargoOwnerNameEmpty) {
      e.preventDefault();
      // Update the sum insured for the current cargo
      setAllCargosInputStates((prevStateInputs) => {
        // Get Update all cargo inputs in case exchange rate changes. (sum insured is dependent on exchange rate)
        const newCargoStateInputs = prevStateInputs?.map((cargoStateInputs) => {
          // Sum insured
          const currentCargoSumInsuredAmount = handleGetSumInsuredAmountOnCargo(
            Number(cargoStateInputs.valueOfGoods),
            Number(cargoStateInputs.exchangeRates),
            cargoStateInputs.currency
          );

          //   Premium Amount
          const currentCargoPremiumAmount = getInsurancePremium(
            currentCargoSumInsuredAmount,
            parseFloat(cargoStateInputs.rate)
          );

          return {
            ...cargoStateInputs,
            sumInsured: currentCargoSumInsuredAmount.toString(),
            premium: Number(currentCargoPremiumAmount.toFixed()),
            amount: cargoStateInputs.valueOfGoods,
          };
        });
        // console.log(newCargoStateInputs, "heree");
        // Update the current cargo object
        return newCargoStateInputs;
      });

      // Check if the clause rate(modified or not) is not less than the original clause rate
      if (
        parseFloat(currentActiveCargo.rate) >=
        currentActiveCargo.selectedClauseRate
      ) {
        // If the rate is >=  the selected plan's rate
        // Navigate to page two
        setPageContent("stage-two");
      } else {
        // If the rate is < the selected plan's rate, throw a prompt error
        setErrorHandlerObj({
          hasError: true,
          message: `Selected clause rate cannot be less than ${currentActiveCargo.selectedClauseRate}`,
        });
      }
    }
  };

  // Handle Get Naira Equivalent
  const handleGetNairaEquivalent = function (
    valueOfGoods: string,
    exchangeRate: string
  ) {
    if (valueOfGoods === "" || exchangeRate === "") {
      setTotalNairaEquivalent("");
      return;
    }

    const currentNairaEquivalent = Number(valueOfGoods) * Number(exchangeRate);
    setTotalNairaEquivalent(currentNairaEquivalent);
  };

  // handle Upload Bulk Excel Sheet
  const handleUploadBulkExcelSheet = function (
    e: ChangeEvent<HTMLInputElement>
  ) {
    // Set Error Handler to default false.
    setErrorHandlerObj({ hasError: false, message: "" });
    handleParseXlsxToJson(
      e,
      setCargoJSONArr,
      setErrorHandlerObj,
      setPageContent
    );
  };

  // UseEffects
  useEffect(() => {
    if (!isEmpty(user)) {
      if (user.customerType === "Broker") {
        // If customerType is a BROKER
        if (policyType === "SingleTransit") {
          // IF user is a broker but policy type is SINGLE TRANSIT, use the BROKERS's rate
          const plans = allClauseTypes?.map((clauseType: IDropdownOption) => {
            return {
              value: clauseType.value,
              key: clauseType.key,
              rate: user?.rate[clauseType.key] || 0,
            };
          });
          // console.log(plans);
          setAllUsersClauses(plans);
        } else {
          // IF user is a broker but policy type is OPEN COVER, use the SELECTED CUSTOMER's rate
          if (
            selectedCompanyFromBroker &&
            !isEmpty(selectedCompanyFromBroker)
          ) {
            // If customer type is a broker, get the plans and clause rate from the selected customer
            const plans = allClauseTypes?.map((clauseType: IDropdownOption) => {
              return {
                value: clauseType.value,
                key: clauseType.key,
                rate: selectedCompanyFromBroker?.rate[clauseType.key] || 0,
              };
            });
            setAllUsersClauses(plans);
          }
        }
      } else {
        // IF customerType is a CUSTOMER
        const plans = allClauseTypes?.map((clauseType: IDropdownOption) => {
          return {
            value: clauseType.value,
            key: clauseType.key,
            rate: user?.rate[clauseType.key] || 0,
          };
        });
        // console.log(plans);
        setAllUsersClauses(plans);
      }

      // Dropdown Option Arr
      const plansDropdownOptionArr: IDropdownOption[] = allClauseTypes?.map(
        (clauseValue, index) => {
          return {
            value: clauseValue.value,
            key: clauseValue.key,
          };
        }
      );
      plansDropdownOptionArr.unshift({
        key: "",
        value: "",
        default: "default",
      });
      setAllClausesArr(plansDropdownOptionArr);
    }
  }, [user, selectedCompanyFromBroker]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (user.customerType === "Broker") {
        // If customerType is a BROKER
        if (policyType === "SingleTransit") {
          // IF user is a broker but policy type is SINGLE TRANSIT, use the BROKERS's rate
          const plans = allVessels?.map((vesselType: IDropdownOption) => {
            return {
              value: vesselType.value,
              key: vesselType.key,
              rate: user?.rate[vesselType.key] || 0,
            };
          });
          setAllVesselArr(plans);
        } else {
          // IF user is a broker but policy type is OPEN COVER, use the SELECTED CUSTOMER's rate
          if (
            selectedCompanyFromBroker &&
            !isEmpty(selectedCompanyFromBroker)
          ) {
            // If customer type is a broker, get the plans and clause rate from the selected customer
            const plans = allVessels?.map((vesselType: IDropdownOption) => {
              return {
                value: vesselType.value,
                key: vesselType.key,
                rate: selectedCompanyFromBroker?.rate[vesselType.key] || 0,
              };
            });
            setAllVesselArr(plans);
          }
        }
      } else {
        // IF customerType is a CUSTOMER
        const plans = allVessels?.map((vesselType: IDropdownOption) => {
          return {
            value: vesselType.value,
            key: vesselType.key,
            rate: user?.rate[vesselType.key] || 0,
          };
        });
        setAllVesselArr(plans);
      }

      // Dropdown Option Arr
      const plansDropdownOptionArr: IDropdownOption[] = allVessels?.map(
        (clauseValue, index) => {
          return {
            value: clauseValue.value,
            key: clauseValue.key,
          };
        }
      );
      plansDropdownOptionArr.unshift({
        key: "",
        value: "",
        default: "default",
      });
      setAllVesselArr(plansDropdownOptionArr);
    }
  }, [user, selectedCompanyFromBroker]);

  useEffect(() => {
    // Set All Conveyances arr
    const newConvenyancesArr: IDropdownOption[] = [...allConveyances];
    newConvenyancesArr.unshift({
      key: "",
      value: "",
      default: "default",
    });
    setAllConveyancesArr(newConvenyancesArr);
  }, []);

  useEffect(() => {
    if (pageContent === "stage-one")
      handleGetNairaEquivalent(
        allCargosInputStates[currentActiveCargoIndex].valueOfGoods,
        allCargosInputStates[currentActiveCargoIndex].exchangeRates
      );
  }, [pageContent]);

  useEffect(() => {
    // Set All Ports || Sails location arr
    const allSailLocationsArr: IDropdownOption[] = sailLocations?.map(
      (location: ISailLocation) => {
        return {
          key: location.ID,
          value: location.NAME,
        };
      }
    );

    allSailLocationsArr.unshift({
      key: "",
      value: "",
      default: "default",
    });
    setAllPortsLocation(allSailLocationsArr);
  }, [sailLocations]);

  useEffect(() => {
    dispatch(getSailLocations(setErrorHandlerObj, setIsLoading));
  }, []);

  useEffect(() => {
    // This will run once the component is mounted
    if(user.loadingPort) {
      setAllCargosInputStates((prevStateInputs) => {
        const newCargoStateInputs = [...prevStateInputs];
  
        let updatedCargoInput: IAllCargosInputStates = {
          ...allCargosInputStates[currentActiveCargoIndex],
          loadingPort: user?.loadingPort,
          loadingPortID: user?.loadingPortID,
          destinationPort: user?.destinationPort,
          destinationPortID: user?.destinationPortID,
          currency: user?.currency,
          ...(user?.currency === "NGN" && { exchangeRates: "1" }),
          exchangeRates: user?.exchangeRate,
          clause: user?.clause,
          conveyanceID: "60779",
          conveyance: "BY SEA",
        };
  
        //CLAUSE UPDATE
        if (allUsersClauses && user?.clause) {
          const selectedPlan = allUsersClauses.filter(
            (clause: ISinglePolicyPlan) => clause.key === user?.clause
          )[0];
          updatedCargoInput = {
            ...updatedCargoInput,
            selectedPremiumPlan: selectedPlan.key,
            selectedClauseRate: selectedPlan.rate,
            rate: selectedPlan.rate.toString(),
          };
        }
  
        newCargoStateInputs.splice(currentActiveCargoIndex, 1, updatedCargoInput);
        console.log(newCargoStateInputs);
        return newCargoStateInputs;
      });
    }
  }, [allUsersClauses]);

  return (
    <div className="policy-stage-container policy-stage-one-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          {user?.customerType === "Broker" && (
            <button onClick={() => setPageContent("stage-zero-five")}>
              <img src={arrowLeftIcon} alt="" />
            </button>
          )}
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">1/7</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        {/* Top Wrapper */}
        <div className="policy-processing-form--top-wrapper">
          <div className="onboarding-form-title-wrapper">
            Complete the form below
          </div>

          {/* Bulk upload option wrapper */}
          <div className="bulk-upload-option-container">
            <div className="bulk-upload-option-wrapper">
              Bulk upload&gt;
              {/* Import excel sheet */}
              <div className="import-excel-button-wrapper">
                <input
                  type="file"
                  onChange={(e) => handleUploadBulkExcelSheet(e)}
                  id="excel-sheet-input"
                  accept=".xls,.xlsx"
                />
                <label htmlFor="excel-shhet-input">Import excel sheet</label>
              </div>
            </div>
            <div className="excel-template-button-wrapper">
              <Link
                to={
                  process.env.PUBLIC_URL + "/doc/HIL_template_policy_sheet.xlsx"
                }
                target="_blank"
              >
                Download excel template
              </Link>
            </div>
          </div>
        </div>

        <form autoComplete="off">
          {/* Company Name form group */}
          {user?.customerType === "Broker" && (
            <div className="form-group company-name-form-group">
              <DropdownInput
                className={"company-name-text-input"}
                placeholder={"Company name"}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  const selectedCompany: ISingleUser[] = companies?.filter(
                    (company: ISingleUser) =>
                      company.companyName === e.target.value
                  );
                  setBrokerCompanyId(selectedCompany[0].id);
                  setSelectedCompanyFromBroker(selectedCompany[0]);
                }}
                optionArray={allCompaniesNames ? allCompaniesNames : []}
                required={true}
              />
            </div>
          )}

          {/* Subsidiary Name form group */}
          {user?.customerType === "Customer" && (
            <div className="form-group subsidiary-name-form-group">
              <DropdownInput
                className={"subsidiary-name-text-input"}
                placeholder={"Subsidiary name"}
                value={subsidiaryName}
                onChange={(e) => {
                  setSubsidiaryName(e.target.value);
                }}
                optionArray={allSubsidiariesNames ? allSubsidiariesNames : []}
                required={true}
              />
            </div>
          )}

          {/* Invoice Number form group */}
          <div className="form-group invoice-number-form-group">
            <TextInput
              type={"text"}
              className={"invoice-number-text-input"}
              placeholder={"C of Q/Pro forma invoice number"}
              value={
                allCargosInputStates[currentActiveCargoIndex].invoiceNumber
              }
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Update the current cargo object
                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    invoiceNumber: e.target.value,
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              required={true}
            />
          </div>

          {/* Value of goods form group */}
          <div className="form-group value-of-goods-form-group">
            <TextInput
              type={"text"}
              className={"value-of-goods-text-input"}
              placeholder={"Value of goods"}
              value={handleFormatNumberInputs(
                allCargosInputStates[currentActiveCargoIndex].valueOfGoods
              )}
              onChange={(e) => {
                // Remove all the commas from the number value
                let value = e.target.value;
                let valueWithoutCommas = value.split(",").join("");
                // Get Equivalent Naira Value
                handleGetNairaEquivalent(
                  valueWithoutCommas,
                  allCargosInputStates[currentActiveCargoIndex].exchangeRates
                );
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Update the current cargo object
                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    valueOfGoods: valueWithoutCommas,
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              required={true}
            />
          </div>

          {/* Loading port form group */}
          <div className="form-group loading-port-form-group">
            <DropdownInput
              className={"loading-port-text-input"}
              placeholder={"Loading port"}
              value={
                user?.loadingPortID ||
                allCargosInputStates[currentActiveCargoIndex].loadingPortID
              }
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Get Current Selected Port Object
                  if (allPortsLocation) {
                    const currentSelectedPort = allPortsLocation?.filter(
                      (location: IDropdownOption) =>
                        location.key.toString() === e.target.value
                    ); // Update the current cargo object
                    const updatedCargoInput: IAllCargosInputStates = {
                      ...allCargosInputStates[currentActiveCargoIndex],
                      loadingPort: currentSelectedPort[0].value,
                      loadingPortID: e.target.value,
                    };
                    // Splice the updated cargo object into the new cargo states arr
                    newCargoStateInputs.splice(
                      currentActiveCargoIndex,
                      1,
                      updatedCargoInput
                    );
                  }
                  return newCargoStateInputs;
                });
              }}
              optionArray={allPortsLocation ? allPortsLocation : []}
              required={true}
              disabled={user?.loadingPortID !== 0}
              // disabled={user?.loadingPortID}
            />
          </div>

          {/* Destination port form group */}
          <div className="form-group destination-port-form-group">
            <DropdownInput
              className={"destination-port-text-input"}
              placeholder={"Destination port"}
              value={
                user?.destinationPortID ||
                allCargosInputStates[currentActiveCargoIndex].destinationPortID
              }
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Get Current Selected Port Object
                  if (allPortsLocation) {
                    const currentSelectedPort = allPortsLocation?.filter(
                      (location: IDropdownOption) =>
                        location.key.toString() === e.target.value
                    );
                    // Update the current cargo object
                    const updatedCargoInput: IAllCargosInputStates = {
                      ...allCargosInputStates[currentActiveCargoIndex],
                      destinationPort: currentSelectedPort[0].value,
                      destinationPortID: e.target.value,
                    };
                    // Splice the updated cargo object into the new cargo states arr
                    newCargoStateInputs.splice(
                      currentActiveCargoIndex,
                      1,
                      updatedCargoInput
                    );
                  }
                  return newCargoStateInputs;
                });
              }}
              optionArray={allPortsLocation ? allPortsLocation : []}
              required={true}
              disabled={user?.destinationPortID !== 0}
            />
          </div>

          {/* Currency form group */}
          <div className="form-group currency-form-group">
            <DropdownInput
              className={"currency-text-input"}
              placeholder={"Select currency"}
              value={
                user?.currency ||
                allCargosInputStates[currentActiveCargoIndex].currency
              }
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // IF CURRENCY === NGN, SET XCNG RATE TO 1
                  if (e.target.value === "NGN")
                    handleGetNairaEquivalent(
                      allCargosInputStates[currentActiveCargoIndex]
                        .valueOfGoods,
                      "1"
                    );
                  // Update the current cargo object
                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    currency: e.target.value,
                    ...(e.target.value === "NGN" && { exchangeRates: "1" }),
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              optionArray={allCurrencyOptionArr}
              required={true}
              disabled={user?.currency !== ""}
            />
          </div>

          {/* Exchange Rate form group */}
          <div className="form-group exchange-rate-form-group">
            <TextInput
              type={"text"}
              className={"exchange-rate-text-input"}
              placeholder={"Exchange rate"}
              value={
                user?.exchangeRate? "1":
                // handleFormatNumberInputs(
                // )
                allCargosInputStates[currentActiveCargoIndex].exchangeRates
              }
              onChange={(e) => {
                // Remove all the commas from the number value
                let value = e.target.value;
                let valueWithoutCommas = value.split(",").join("");
                // Handle Get Naira Equivalent
                handleGetNairaEquivalent(
                  allCargosInputStates[currentActiveCargoIndex].valueOfGoods,
                  valueWithoutCommas
                );
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Update the current cargo object
                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    exchangeRates: valueWithoutCommas,
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              min={"1"}
              required={true}
              disabled={user?.exchangeRate !== 0}
            />
          </div>

          {/* Conveyance form group */}
          <div className="form-group conveyance-form-group">
            <DropdownInput
              className={"conveyance-text-input"}
              placeholder={"Select conveyance"}
              value={allCargosInputStates[currentActiveCargoIndex].conveyanceID}
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Get selected conveyance name / value
                  const currentSelectedConveyance = allConveyances?.filter(
                    (conveyance: IDropdownOption) =>
                      conveyance.key === e.target.value
                  );
                  // Update the current cargo object
                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    conveyanceID: e.target.value,
                    conveyance: currentSelectedConveyance[0]?.value || "",
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              optionArray={allConveyancesArr ? allConveyancesArr : []}
              required={true}
            />
          </div>

          {/* Clause form group */}
          <div className="form-group clause-form-group">
            <DropdownInput
              className={"clause-text-input"}
              placeholder={"Select clause"}
              value={
                // user?.clause ||
                allCargosInputStates[currentActiveCargoIndex].clause
              }
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Get selected conveyance name / value
                  if (allUsersClauses) {
                    const selectedPlan = allUsersClauses.filter(
                      (clause: ISinglePolicyPlan) =>
                        clause.key === e.target.value
                    )[0];
                    // Update the current cargo object

                    const updatedCargoInput: IAllCargosInputStates = {
                      ...allCargosInputStates[currentActiveCargoIndex],
                      clause: e.target.value,
                      selectedPremiumPlan: selectedPlan.key,
                      selectedClauseRate: selectedPlan.rate,
                      rate: selectedPlan.rate.toString(),
                    };
                    // Splice the updated cargo object into the new cargo states arr
                    newCargoStateInputs.splice(
                      currentActiveCargoIndex,
                      1,
                      updatedCargoInput
                    );
                  } else {
                    setErrorHandlerObj({
                      hasError: true,
                      message:
                        "Select a customer first before selecting the clause!",
                    });
                  }
                  return newCargoStateInputs;
                });
              }}
              optionArray={allClausesArr ? allClausesArr : []}
              required={true}
              disabled={user?.clause !== ""}
            />
          </div>

          {/* Clause Rate form group */}
          {user?.customerType === "Broker" && (
            <div className="form-group clause-rate-form-group">
              <TextInput
                type={"number"}
                className={"exchange-rate-text-input"}
                value={allCargosInputStates[currentActiveCargoIndex].rate}
                onChange={(e) => {
                  setAllCargosInputStates((prevStateInputs) => {
                    // Get a copy / duplicate of the all cargo input states
                    const newCargoStateInputs = [...prevStateInputs];
                    // Update the current cargo object
                    const updatedCargoInput: IAllCargosInputStates = {
                      ...allCargosInputStates[currentActiveCargoIndex],
                      rate: e.target.value,
                    };
                    // Splice the updated cargo object into the new cargo states arr
                    newCargoStateInputs.splice(
                      currentActiveCargoIndex,
                      1,
                      updatedCargoInput
                    );
                    return newCargoStateInputs;
                  });
                }}
                placeholder={"Clause rate (%)"}
                required
              />
            </div>
          )}

          <div className="form-group clause-form-group">
            <DropdownInput
              className={"vessel-text-input"}
              placeholder={"Select vessel"}
              value={allCargosInputStates[currentActiveCargoIndex].vessel}
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];

                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    vessel: e.target.value,
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              optionArray={allVesselArr ? allVesselArr : []}
              required={true}
              // disabled={user?.clause !== undefined}
            />
          </div>

          {/* Cargo Description form group */}
          <div className="form-group cargo-description-form-group">
            <TextareaInput
              className="cargo-description-textarea-input"
              value={
                allCargosInputStates[currentActiveCargoIndex].cargoDescription
              }
              onChange={(e) => {
                setAllCargosInputStates((prevStateInputs) => {
                  // Get a copy / duplicate of the all cargo input states
                  const newCargoStateInputs = [...prevStateInputs];
                  // Update the current cargo object
                  const updatedCargoInput: IAllCargosInputStates = {
                    ...allCargosInputStates[currentActiveCargoIndex],
                    cargoDescription: e.target.value,
                  };
                  // Splice the updated cargo object into the new cargo states arr
                  newCargoStateInputs.splice(
                    currentActiveCargoIndex,
                    1,
                    updatedCargoInput
                  );
                  return newCargoStateInputs;
                });
              }}
              placeholder={"Describe cargo"}
              required
            />
          </div>

          {/* Naira Equivalent Wrapper */}
          {user?.customerType === "Broker" && (
            <div className="form-group naira-equiv-form-group">
              <div className="naira-equiv-box-wrapper">
                <div className="naira-equiv-key-wrapper">Naira equivalent</div>
                <div className="naira-equiv-key-wrapper">
                  {totalNairaEquivalent?.toLocaleString()}
                </div>
              </div>
            </div>
          )}

          <div className="policy-form-page-bottom-wrapper">
            {/* Fields mandatory wrapper */}
            <div className="field-are-mandatory-wrapper">
              All fields are mandatory*
            </div>

            {/* Submit Policy Form Button Wrapper */}
            <div className="submit-onboarding-form-button-wrapper">
              <button onClick={(e) => handleNavigateToPageTwo(e)}>
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PolicyStageOne;
