export const paymentMethods = [
  // { name: "Use credit note option", pageValue: "credit-note-option" },
  // { name: "Use payment in real time", pageValue: "real-time" },
  {
    name: "Use wallet",
    pageValue: "Wallet",
  },
  {
    name: "Use units",
    pageValue: "Unit",
  },
  // {
  //   name: "Use credit note / Payment in arrears",
  //   pageValue: "payment-in-arrears",
  // },
];
