import React from "react";
import "./CertificateInfoModal.css";
import { Modal } from "reactstrap";
import contractIcon from "../../../images/contract-icon-wrapper.svg";
import { ISingleCertificate } from "../../../pages/certificates/Certificates";
import { truncateString } from "../../../utils/truncateString";

// Interfaces
interface IProps {
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCertificate: ISingleCertificate | null;
}
function CertificateInfoModal({
  isModalOpened,
  setIsModalOpened,
  selectedCertificate,
}: IProps) {
  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(!isModalOpened);
      }}
      centered={true}
      size="lg"
      className={"certificate_info_modal_container"}
    >
      {/* Modal header */}
      <div className="modal-header-container">
        {/* Title */}
        <div className="modal-title--row-wrapper">Certificate Details</div>

        {/* Close Modal Button  */}
        <div className="close-modal-button-wrapper">
          <button onClick={() => setIsModalOpened(false)}>
            <img src={contractIcon} alt="" />
          </button>
        </div>
      </div>
      {selectedCertificate && (
        <div className="certificate_info_container">
          {/* Premium plan*/}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Premium plan:</div>
            <div className="certificate-info--value-wrapper">
              {selectedCertificate.clause}
            </div>
          </div>

          {/* Sum Insured*/}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Sum insured:</div>
            <div className="certificate-info--value-wrapper">
              &#8358;
              {selectedCertificate.sumInsured.toLocaleString()}
            </div>
          </div>

          {/*  Premium*/}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Premium:</div>
            <div className="certificate-info--value-wrapper">
              &#8358;
              {selectedCertificate.premium.toLocaleString()}
            </div>
          </div>

          {/* Invoice number */}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Invoice number:</div>
            <div className="certificate-info--value-wrapper">
              {selectedCertificate.invoiceNumber}
            </div>
          </div>

          {/* Value of goods */}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Value of goods:</div>
            <div className="certificate-info--value-wrapper">
              &#8358;
              {selectedCertificate.valueOfGoods?.toLocaleString()}
            </div>
          </div>

          {/* Exchange rate */}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Exchange rate:</div>
            <div className="certificate-info--value-wrapper">
              {selectedCertificate.exchangeRate?.toLocaleString()}
            </div>
          </div>

          {/* Port of loading */}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">Loading port:</div>
            <div className="certificate-info--value-wrapper">
              {truncateString(selectedCertificate.loadingPort, 20)}
            </div>
          </div>

          {/* Port of destination */}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">
              Destination port:
            </div>
            <div className="certificate-info--value-wrapper">
              {truncateString(selectedCertificate.destinationPort, 20)}
            </div>
          </div>

          {/* Cargo description */}
          <div className="certificate-info--key-value-wrapper">
            <div className="certificate-info--key-wrapper">
              Cargo description:
            </div>
            <div className="certificate-info--value-wrapper">
              {selectedCertificate.cargoDescription}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CertificateInfoModal;
