import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import envelopeIcon from "../../images/envelope-icon.svg";
import newspaperIcon from "../../images/newspaper-icon.svg";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import { ISingleCertificate } from "../certificates/Certificates";
import LatestCertificatesTable from "./latest-certificates-table/LatestCertificatesTable";
import { UserContext } from "../../components/contexts/UserContext";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getAllBrokerCertificates,
  getAllCustomerCertificates,
} from "../../redux/actions/customerPoliciesActions";
import { useNavigate } from "react-router-dom";
import copyIcon from "../../images/copy-icon.svg";
import SuccessHandler from "../../components/success/SuccessHandler";
import isEmpty from "../../validation/isEmpty";

function Dashboard() {
  // Functions, states and variables
  const { user }: any = useContext(UserContext);
  const certificates: ISingleCertificate[] | null = useAppSelector((state) => {
    return state.policies.certificates;
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // States
  const [latestCertificates, setLatestCertificates] = useState<
    ISingleCertificate[] | null
  >(null);
  const [amountInsuredDateFilter, setAmountInsuredDateFilter] = useState("");
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // Get total amount insured for all cargos
  const getTotalSumInsuredAmount = function () {
    const totalSumInsuredValue = certificates?.reduce(
      (acc, obj) => {
        return {
          sumInsured: acc.sumInsured + obj.sumInsured,
        };
      },
      { sumInsured: 0 }
    );
    return totalSumInsuredValue?.sumInsured || 0;
  };

  // handle Copy Code To Clipboard
  const handleCopyReferralLinkToClipboard = function () {
    if (user) {
      const referralLink = `${process.env.REACT_APP_URL}/signup/?ref_id=${user.id}`;
      navigator.clipboard.writeText(referralLink);
      setSuccessHandlerObj({
        isSuccess: true,
        message: "Referral Link copied to clipboard!",
      });
    }
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    if (certificates) {
      const firstFiveCertificates = certificates.slice(0, 5);
      setLatestCertificates(firstFiveCertificates);
    }
  }, [certificates]);

  useEffect(() => {
    if (!isEmpty(user)) {
      // Check if user is a broker or a customer
      if (user?.customerType === "Broker") {
        // For Brokers
        dispatch(getAllBrokerCertificates(setErrorHandlerObj, setIsLoading));
        // console.log("Fetching certificates for broker");
      } else {
        // For Customers
        dispatch(getAllCustomerCertificates(setErrorHandlerObj, setIsLoading));
        // console.log("Fetching certificates for customer");
      }
    }
  }, [user]);

  return (
    <div className="dashboard-container">
      <Top
        pageSummaryMessage="Here’s an overview of your account"
        hasOnboardingButton={true}
      />
      <div className="dashboard-container--inner">
        <Sidebar activeName={"Dashboard"} />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app-error-success-message-wrapper"
        />

        <section className="dashboard-container-main-section">
          {/* Row One Section */}
          <section className="dashboard--row-one-section">
            {/* Total certificates generated box */}
            <div className="dashboard-top-box-wrapper">
              <div className="dashboard-top-box-wrapper--inner">
                {/* Top Wrapper */}
                <div className="dashboard-top-box--top-wrapper">
                  <div className="box-icon-wrapper">
                    <img src={envelopeIcon} alt="" />
                  </div>

                  <div className="box-title-wrapper dashboard--box-title-wrapper">
                    Total certificates generated
                  </div>
                </div>

                {/* Middle Wrapper */}
                <div className="dashboard-top-box--middle-wrapper">
                  <div>{certificates?.length || 0}</div>
                </div>

                {/* Bottom Wrapper */}
                {/* <div className="dashboard-top-box--bottom-wrapper">
                  <div className="date-filter-dropdown-form-group">
                    <select
                      value={certificatesGeneratedDateFilter}
                      onChange={(e) =>
                        setCertificatesGeneratedDateFilter(e.target.value)
                      }
                    >
                      {allMonths.map((optionValue, i) => (
                        <option
                          key={i + 1}
                          disabled={optionValue.default ? true : false}
                          value={optionValue.key}
                          hidden={optionValue.default ? true : false}
                        >
                          {optionValue.fullValue}
                        </option>
                      ))}
                    </select>
                    <div className="angle-img-wrapper">
                      <img src={angleDown} alt="" />
                    </div>
                  </div>

                  certificates generated percent change--- Commented
                  <div className="certificates-generated-percent-change">
                    +10%{" "}
                    <div className="arrow-display-img-wrapper">
                      <img src={arrowImg} alt="" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* Total amount insured box */}
            <div className="dashboard-top-box-wrapper">
              <div className="dashboard-top-box-wrapper--inner">
                {/* Top Wrapper */}
                <div className="dashboard-top-box--top-wrapper">
                  <div className="box-icon-wrapper">
                    <img src={newspaperIcon} alt="" />
                  </div>

                  <div className="box-title-wrapper dashboard--box-title-wrapper">
                    Total amount insured
                  </div>
                </div>

                {/* Middle Wrapper */}
                <div className="dashboard-top-box--middle-wrapper more">
                  <span className="currency">
                    {user?.currency === "USD" ? "$" : "₦"}
                  </span>
                  <div>
                    {
                    user?.currency === "USD"
                      ? (Math.round((getTotalSumInsuredAmount()/user?.exchangeRate)*10)/10)?.toLocaleString()
                      : 
                      getTotalSumInsuredAmount()?.toLocaleString()}
                  </div>
                </div>

                {/* Bottom Wrapper */}
                {/* <div className="dashboard-top-box--bottom-wrapper">
                  <div className="date-filter-dropdown-form-group">
                    <select
                      value={amountInsuredDateFilter}
                      onChange={(e) =>
                        setAmountInsuredDateFilter(e.target.value)
                      }
                    >
                      {allMonths.map((optionValue, i) => (
                        <option
                          key={i + 1}
                          disabled={optionValue.default ? true : false}
                          value={optionValue.key}
                          hidden={optionValue.default ? true : false}
                        >
                          {optionValue.fullValue}
                        </option>
                      ))}
                    </select>
                    <div className="angle-img-wrapper">
                      <img src={angleDown} alt="" />
                    </div>
                  </div>

                  certificates generated percent change-- Commented
                  <div className="certificates-generated-percent-change">
                    +10%{" "}
                    <div className="arrow-display-img-wrapper">
                      <img src={arrowImg} alt="" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* Units box */}
            <div className="dashboard-top-box-wrapper">
              <div className="dashboard-top-box-wrapper--inner">
                {/* Top Wrapper */}
                <div className="dashboard-top-box--top-wrapper">
                  <div className="box-icon-wrapper">
                    <img src={newspaperIcon} alt="" />
                  </div>

                  <div className="box-title-wrapper dashboard--box-title-wrapper">
                    Units <span>(1 unit = 1 certificate)</span>
                  </div>
                </div>

                {/* Middle Wrapper */}
                <div className="dashboard-top-box--middle-wrapper">
                  <div>{user?.unit.toLocaleString() || 0}</div>

                  <div className="view-history-button-wrapper">
                    <button
                      // className="referral-link-clipboard-wrapper"
                      onClick={() =>
                        navigate("/company/unit-history", {
                          state: {
                            subsidiaryId: user.id,
                            subsidiaryName: user.companyName,
                            unit: user?.unit,
                          },
                        })
                      }
                    >
                      View History
                    </button>
                  </div>
                </div>

                {/* Bottom Wrapper */}
                <div className="dashboard-top-box--bottom-wrapper">
                  {/* Request units button wrapper */}
                  {/* <div className="request-units-button-wrapper">
                    <button>Request units</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* Row Two Section */}
          <section className="dashboard--row-two-section">
            {/* Left Container */}
            <div className="dashboard--row-two-left-container">
              {/* Latests Certificates Tables Section*/}
              <section className="latest-certificates-table-section">
                <div className="latest-certificates-table--top-wrapper">
                  {/* Table Title wrapper */}
                  <div className="table-title-wrapper">Policies</div>

                  <div className="view-more-certificates-button-wrapper">
                    <button onClick={() => navigate("/certificates")}>
                      View more
                    </button>
                  </div>
                </div>

                <LatestCertificatesTable
                  latestCertificates={latestCertificates}
                  isLoading={isLoading}
                />
              </section>
            </div>
            {/* Right Container */}
            <div className="dashboard--row-two-right-container">
              {/* Premium deposit box wrapper */}
              <div className="premium-deposit-box-wrapper">
                {/* title wrapper */}
                <div className="premium-deposit-box-title-wrapper">
                  Premium Deposit
                </div>
                {/* Inner Wrapper */}
                <div className="premium-deposit-box--inner-wrapper">
                  <div className="wallet-balance-title-wrapper">
                    Wallet balance
                  </div>

                  <div className="wallet-balance-view-box-wrapper">
                    <div className="wallet-balance-view-wrapper">
                      <span className="currency">
                        {user?.currency === "USD" ? "$" : "₦"}
                      </span>
                      {user?.currency === "USD" ?
                      isBalanceVisible
                      ? `${(Math.round((user?.walletBalance / user?.exchangeRate)*10)/10).toLocaleString() || 0}`
                      : "*********"
                      :
                      isBalanceVisible
                        ? `${user?.walletBalance.toLocaleString() || 0}`
                        : "*********"}
                    </div>

                    <img
                      src={isBalanceVisible ? eyeOpenIcon : eyeCloseIcon}
                      alt=""
                      className="hidden-balance-icon-wrapper"
                      onClick={() => setIsBalanceVisible(!isBalanceVisible)}
                    />
                  </div>
                  {/* Top up wallet */}
                  {/* <div className="top-up-wallet-button-wrapper">
                    <button>Top up wallet</button>
                  </div> */}

                  <div className="view-history-button-wrapper">
                    <button
                      // className="referral-link-clipboard-wrapper"
                      onClick={() =>
                        navigate("/company/wallet-history", {
                          state: {
                            subsidiaryId: user.id,
                            subsidiaryName: user.companyName,
                            walletBalance: user?.walletBalance,
                          },
                        })
                      }
                    >
                      View History
                    </button>
                  </div>
                </div>
              </div>

              {/* Referral Link Clipboard Container */}
              {user && user?.customerType === "Broker" && (
                <div className="referral-link-clipboard-container">
                  <div className="referral-link-title-wrapper">
                    Referral Link
                  </div>

                  <div className="referral-link-clipboard-container--inner">
                    <div className="referral-link-clipboard-wrapper">
                      https://mybrokerreferallink{" "}
                      <div className="referral-link-clipboard-button-wrapper">
                        <button
                          onClick={() => handleCopyReferralLinkToClipboard()}
                        >
                          <img src={copyIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Rewards and Referrals Box container */}
              {user && user?.customerType === "Broker" && (
                <div className="rewards-referrals-box-container"></div>
              )}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
