import { GET_ERRORS } from "../redux/Constants";
import { SetErrorHandlerType } from "../Types";
import isEmpty from "../validation/isEmpty";

// Handle API Error Fxn
export const handleAPIError = function (
  err: any,
  dispatch: any,
  setErrorHandlerObj: SetErrorHandlerType
) {
  if (!isEmpty(err) && !isEmpty(err.response) && !isEmpty(err.response.data)) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response,
    });
    // console.log(err.response);
  } else {
    dispatch({
      type: GET_ERRORS,
      payload: {
        message:
          "Something Went Wrong. Please Check your Connection and try again",
      },
    });
  }
  //   Set the error handdler state
  setErrorHandlerObj({
    hasError: true,
    message:
      err?.response?.data?.detail ||
      err?.response?.data?.message ||
      err?.response?.data ||
      "Something Went Wrong. Please Check your Connection and try again",
  });
};
