import axios from "axios";
import { api } from "../../api/config";
import {
  GET_A_COMPANY_CERTIFICATES_FOR_BROKER,
  GET_BROKER_CERTIFICATES,
  GET_CUSTOMER_CERTIFICATES,
} from "../Constants";
import {
  ISubmittedCertificateData,
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { ISavedIDArrayType } from "../../pages/marine-policy/MarinePolicy";
import { ISingleCertificate } from "../../pages/certificates/Certificates";

// interfaces
interface ICustomerPolicyData {
  // sumInsured: number;
  // premium: number;
  customerId: string;
  brokerId: string;
  policyType: string;
  sumInsured?: number;
  subsidiaryId: string;
  cargos: {
    clause: string;
    exchangeRate: number;
    currency: string;
    valueOfGoods: number;
    bankName: string;
    cargoDescription: string;
    loadingPort: string;
    destinationPort: string;
    loadingPortID: number;
    destinationPortID: number;
    invoiceNumber: string;
    lcNumber: string;
    sumInsured: number;
    conveyance: string;
    conveyanceID: number;
    rate: number;
    vesselName: string;
  }[];
}

interface IPaymentMethod {
  paymentMethod: string;
  creditNoteUrl: string;
  certificateIds: string[];
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;
// Get All Certificates for a CUSTOMER
export const getAllCustomerCertificates =
  (setErrorHandlerObj: SetErrorHandlerType, setIsLoading: SetIsLoadingType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/customer/certificate`)
      .then((res) => {
        setIsLoading(false);
        // console.log(res.data);
        // Dispatch the data
        dispatch({
          type: GET_CUSTOMER_CERTIFICATES,
          payload: res.data?.reverse(),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get All Certificates for a BROKER
export const getAllBrokerCertificates =
  (setErrorHandlerObj: SetErrorHandlerType, setIsLoading: SetIsLoadingType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/broker/certificate`)
      .then((res) => {
        setIsLoading(false);
        // console.log(res.data);
        // Dispatch the data
        dispatch({
          type: GET_BROKER_CERTIFICATES,
          payload: res.data?.reverse(),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Each Company Certificates for a broker
export const getACompanyCerificatesForBroker =
  (
    companyId: string,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/broker/company-certificate/${companyId}`)
      .then((res) => {
        setIsLoading(false);
        // console.log(res.data);
        // Dispatch the data
        dispatch({
          type: GET_A_COMPANY_CERTIFICATES_FOR_BROKER,
          payload: res.data?.reverse(),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Save A Customer Certificate
export const saveACertificate =
  (
    data: ICustomerPolicyData,
    setErrorHandlerObj: SetErrorHandlerType,
    setPageContent: (value: React.SetStateAction<string>) => void,
    setIsLoading: SetIsLoadingType,
    setSavedCertificateId: React.Dispatch<
      React.SetStateAction<ISavedIDArrayType>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/customer/save-certificate`, data)
      .then((res) => {
        setIsLoading(false);
        const currentCargoIDs = res.data?.data?.map(
          (cargo: ISingleCertificate) => {
            return cargo.id;
          }
        );
        // console.log(currentCargoIDs);
        // Set the certificate Id for the saved certficate(s) from the res
        setSavedCertificateId(currentCargoIDs);
        // Set the page content to policy process successful
        setPageContent("stage-five");
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Edit A Certificate
export const editCertificate =
  (
    certificateId: string,
    data: ICustomerPolicyData,
    setErrorHandlerObj: SetErrorHandlerType,
    setPageContent: (value: React.SetStateAction<string>) => void,
    setIsLoading: SetIsLoadingType,
    setSavedCertificateId: React.Dispatch<
      React.SetStateAction<ISavedIDArrayType>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/customer/edit-certificate/${certificateId}`, data)
      .then((res) => {
        setIsLoading(false);
        // Set the certificate Id for the saved certficate(s) from the res
        setSavedCertificateId([certificateId]);
        // Set the page content to policy process successful
        setPageContent("stage-five");
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Register A Customer Certificate
export const registerACertificate =
  (
    data: IPaymentMethod,
    setErrorHandlerObj: SetErrorHandlerType,
    setPageContent: (value: React.SetStateAction<string>) => void,
    setIsLoading: SetIsLoadingType,
    setSubmittedCertificatesData: React.Dispatch<
      React.SetStateAction<ISubmittedCertificateData[] | null>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/customer/register-certificate`, data)
      .then((res) => {
        setIsLoading(false);
        // console.log(res.data);
        // Update the submitted cert. state with the response data
        setSubmittedCertificatesData(res.data);
        // Set the page content to policy process successful
        setPageContent("process-successful");
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Cancel A Customer Certificate
export const cancelACertificate =
  (
    data: { certificateId: string },
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType,
    setIsCertificateOptionsModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/customer/cancel-certificate`, data)
      .then((res) => {
        setIsLoading(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Certificate cancelled successfully!",
        });
        setIsCertificateOptionsModalOpened(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsCertificateOptionsModalOpened(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Flow with this API is muted
// Get All Customer Certificate With Interests
// export const getCertificateWithInterests =
//   (
//     certificateId: string,
//     setErrorHandlerObj: SetErrorHandlerType,
//     setIsLoading: SetIsLoadingType
//   ) =>
//   (dispatch: any) => {
//     setIsLoading(true);
//     axios
//       .get(`${url}/customer/certificate/${certificateId}`)
//       .then((res) => {
//         setIsLoading(false);
//         // Dispatch the data
//         dispatch({
//           type: GET_CERTIFICATE_WITH_INTERESTS,
//           payload: res.data,
//         });
//       })
//       .catch((err) => {
//         setIsLoading(false);
//         handleAPIError(err, dispatch, setErrorHandlerObj);
//       });
//   };
