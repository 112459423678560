import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import searchIcon from "../../images/search-icon.svg";
import isEmpty from "../../validation/isEmpty";
import { Pagination } from "../../components/pagination/Pagination";
import { UserContext } from "../../components/contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch } from "../../hooks";
import { getAllCompaniesForBroker } from "../../redux/actions/companyActions";
import { ISingleSubsidiary } from "../../Types";
import SubsidiariesTable from "./subsidiaries-table/SubsidiariesTable";

// Interfaces
function Subsidiaries() {
  // Functions, states and Variables
  const { user }: any = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  //   States
  const [allSubsidiaries, setAllSubsidiaries] = useState<
    ISingleSubsidiary[] | null
  >(null);
  const [currentSubsidiariesPosts, setCurrentSubsidiariesPosts] = useState<
    ISingleSubsidiary[] | null
  >(null);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  //   Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!allSubsidiaries) return false;
    const subsidiariesData = user?.subCompanies?.filter(
      (subsidiary: ISingleSubsidiary) =>
        subsidiary.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        subsidiary.tin.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllSubsidiaries(subsidiariesData);
    // Update table state to all subsidiaries rows
    if (!subsidiariesData) return false;
    const currentRows = subsidiariesData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    setCurrentSubsidiariesPosts(currentRows);
  };

  //   handle Navigate To Add New Subsidiary
  const handleNavigateToAddNewSubsidiary = function () {
    if (!user?.isApproved) {
      setErrorHandlerObj({
        hasError: true,
        message: "Your account is not yet approved!",
      });
      return;
    }
    // Navigate to add new company page
    navigate("/subsidiary/add-new");
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    // TODO Get all Subsidiaries for customers
    dispatch(getAllCompaniesForBroker(setErrorHandlerObj, setIsLoading));
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) setAllSubsidiaries(user?.subCompanies);
  }, [user]);

  useEffect(() => {
    if (allSubsidiaries) {
      const currentRows = allSubsidiaries?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentSubsidiariesPosts(currentRows);
    }
  }, [allSubsidiaries, currentPage]);

  useEffect(() => {
    handleFilterTable();
  }, [searchedValue]);

  useEffect(() => {
    if (location.state) {
      const { isNewSubsidiaryAdded }: any = location.state;
      if (isNewSubsidiaryAdded)
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Subsidiary added successfully!",
        });
    }
  }, [location]);

  return (
    <div className="companies-container">
      <Top pageSummaryMessage="Check subsidiaries attached to you" />

      <div className="companies-container--inner">
        <Sidebar activeName={"Subsidiaries"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app-error-success-message-wrapper"
        />
        <section className="companies-container-main-section">
          {/* companies table section */}
          <section className="companies-table-section">
            <div className="companies-table--top-wrapper">
              {/* Table Title wrapper */}
              {/* <div className="table-title-wrapper">Certificates</div> */}

              {/* Top Left Wrapper */}
              <div className="companies-table--top--left-wrapper">
                {/* Table Search Input form group */}
                <div className="table-search-form-group">
                  <input
                    type="search"
                    value={searchedValue}
                    onChange={(e) => setSearchedValue(e.target.value)}
                    placeholder="search here"
                  />
                  <img src={searchIcon} alt="" />
                </div>

                {/* Add a New Company button */}
                <div className="add-company-button-wrapper">
                  <button onClick={() => handleNavigateToAddNewSubsidiary()}>
                    Add a subsidiary
                  </button>
                </div>
              </div>
            </div>

            {/* Subsidiaries Table Container */}
            <SubsidiariesTable
              currentPosts={currentSubsidiariesPosts}
              isLoading={isLoading}
            />
            {allSubsidiaries && !isEmpty(currentSubsidiariesPosts) && (
              <>
                <Pagination
                  paginate={paginate}
                  rowsPerPage={rowsPerPage}
                  totalPosts={allSubsidiaries.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          </section>
        </section>
      </div>
    </div>
  );
}

export default Subsidiaries;
