import React, { useContext, useEffect, useState } from "react";
import "./PolicyStageFive.css";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import boxEdgeVector from "../../../../images/box-edge-vector.svg";
import {
  IAllCargosInputStates,
  IDropdownOption,
  IPaystackReference,
  IStageFourPolicyFormStates,
  ISubmittedCertificateData,
  SetErrorHandlerType,
} from "../../../../Types";
import {
  ISingleUser,
  UserContext,
} from "../../../../components/contexts/UserContext";
import { usePaystackPayment } from "react-paystack";
import { ISavedIDArrayType } from "../../MarinePolicy";
import { registerACertificate } from "../../../../redux/actions/customerPoliciesActions";
import { useAppDispatch } from "../../../../hooks";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";

// Interfaces
interface IProps {
  allCargosInputStates: IAllCargosInputStates[];
  stageFourInputStates: IStageFourPolicyFormStates;
  savedCertificateId: ISavedIDArrayType;
  selectedCompanyFromBroker: ISingleUser | null;
  setStageFourInputStates: React.Dispatch<
    React.SetStateAction<IStageFourPolicyFormStates>
  >;
  policyType: string;
  pageContent: string;
  isRoutedToRegisterCertificateOnly: boolean;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setSubmittedCertificatesData: React.Dispatch<
    React.SetStateAction<ISubmittedCertificateData[] | null>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
}

function PolicyStageFive({
  allCargosInputStates,
  stageFourInputStates,
  savedCertificateId,
  selectedCompanyFromBroker,
  setStageFourInputStates,
  policyType,
  pageContent,
  isRoutedToRegisterCertificateOnly,
  setPageContent,
  setSubmittedCertificatesData,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  const dispatch = useAppDispatch();
  // States
  const [allPaymentMethodsArr, setAllPaymentMethodsArr] = useState<
    IDropdownOption[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  //   Functions
  // handle Navigate To Payment page Two / Navigate to pay by selected mode of payment
  const handleNavigateToPaymentPageTwo = function (paymentMethodValue: string) {
    const updatedStageFourInputStates: IStageFourPolicyFormStates = {
      ...stageFourInputStates,
      paymentMethod: paymentMethodValue,
    };
    setStageFourInputStates(updatedStageFourInputStates);

    // Navigate to next page
    setPageContent("stage-six");
  };

  // Get total Premium price from selected plan for all cargos
  const getTotalPremiumPlanPrice = function () {
    const totalPremiumPlanPrice = allCargosInputStates?.reduce(
      (acc, obj, index) => {
        return {
          premium: acc.premium + obj.premium,
        };
      },
      { premium: 0 }
    );
    return totalPremiumPlanPrice.premium;
  };

  // Paystack
  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email || "",
    amount: Number(getTotalPremiumPlanPrice()) * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY
      ? process.env.REACT_APP_PAYSTACK_KEY
      : "",
  };

  // Initialize payment for visa processing straight
  const initializePayment: any = usePaystackPayment(config);

  // On Paystack Success
  const onPaystackSuccess = (reference: IPaystackReference) => {
    const { reference: referenceValue } = reference;
    let data = {
      paymentMethod: "Paystack",
      creditNoteUrl: "",
      certificateIds: savedCertificateId ? savedCertificateId : [],
      reference: referenceValue,
      amount: Number(getTotalPremiumPlanPrice()),
    };
    //  Call the API to register policy with the saved policy Id
    dispatch(
      registerACertificate(
        data,
        setErrorHandlerObj,
        setPageContent,
        setIsLoading,
        setSubmittedCertificatesData
      )
    );
  };

  // On Paystack Close
  const onPaystackClose = (reference: any) => {
    setErrorHandlerObj({ hasError: true, message: "Payment failed!" });
  };

  const handleProceedToPay = function () {
    setErrorHandlerObj({ hasError: false, message: "" });
    //  Initialize paystack API then create investment on success
    initializePayment(onPaystackSuccess, onPaystackClose);
  };

  // For Customers
  const handleReturnPaymentMethodArrForCustomers = function () {
    const paymentMethodsArr = [
      user?.paymentAccess?.isWalletEnabled && {
        value: "Use wallet",
        key: "Wallet",
      },
      user?.paymentAccess?.isUnitEnabled && {
        value: "Use units",
        key: "Unit",
      },
    ];
    return paymentMethodsArr;
  };

  // For Brokers
  const handleReturnPaymentMethodArrForBrokers = function () {
    const paymentMethodsArr: any = [
      selectedCompanyFromBroker?.paymentAccess?.isWalletEnabled && {
        value: "Use wallet",
        key: "Wallet",
      },
      selectedCompanyFromBroker?.paymentAccess?.isUnitEnabled && {
        value: "Use units",
        key: "Unit",
      },
    ];
    return paymentMethodsArr;
  };

  // UseEffects
  useEffect(() => {
    // console.log(selectedCompanyFromBroker);
    // console.log(pageContent, policyType, user, selectedCompanyFromBroker);
    if (pageContent === "stage-five" && policyType && user) {
      // Set All Payment Methods arr
      let paymentMethodsArr: { key: string; value: string }[] = [];
      if (policyType === "OpenCover") {
        // For OpenCover Certificates, payment === "unit" || "wallet"
        if (user?.customerType === "Broker") {
          // If user is a BROKER
          paymentMethodsArr = handleReturnPaymentMethodArrForBrokers();
        } else {
          // If user is a CUSTOMER
          paymentMethodsArr = handleReturnPaymentMethodArrForCustomers();
        }
      } else {
        // For SingleTransit Certificates, payment === "creditnote"
        // Note that single transits is for only brokers, so no need to validate array for customers
        paymentMethodsArr = [
          {
            value: "Credit Note",
            key: "CreditNote",
          },
          // user?.paymentAccess?.isWalletEnabled && {
          user?.paymentAccess?.isRealTimePaymentEnabled && {
            value: "Pay now",
            key: "Paystack",
          },
        ];
      }
      // console.log(paymentMethodsArr);
      setAllPaymentMethodsArr(paymentMethodsArr);
    }
  }, [pageContent, policyType, user]);

  return (
    // Payment stage one page
    <div className="policy-stage-container policy-stage-one-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          {!isRoutedToRegisterCertificateOnly && (
            <button onClick={() => setPageContent("stage-four")}>
              <img src={arrowLeftIcon} alt="" />
            </button>
          )}
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">6/7</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        {/* Form Four Header Box Wrapper */}
        <div className="policy-form-three-header-box-wrapper">
          {/* vector wrapper */}
          <div className="box-edge-vector-wrapper">
            <img src={boxEdgeVector} alt="" />
          </div>
          <div className="form-header-box--sub-text">
            {/* {getSelectedPremiumPlanName()} */}
            Total Premium Price
          </div>
          <div className="form-header-box--main-text">
            <span className="currency">
              {user?.currency === "USD" ? "$" : "₦"}
            </span>
            {user?.currency === "USD" ?
            (Math.round((getTotalPremiumPlanPrice()/user?.exchangeRate) * 10 ) / 10)
            ?.toLocaleString() || "" :
            getTotalPremiumPlanPrice()?.toLocaleString() || ""}
          </div>
        </div>

        {/* Ploicy Plan Selection Container */}
        <div className="policy-plan-selection-container">
          {/* Title Wrapper */}
          <div className="policy-plan-selection-title-wrapper">
            Choose a payment method
          </div>

          {/* Boxes Wrapper */}
          <div className="payment-method-selection-boxes-wrapper">
            {allPaymentMethodsArr?.map((paymentMethod, index) =>
              paymentMethod ? (
                <div
                  key={index + 1}
                  className="payment-method-button-box-wrapper"
                >
                  <button
                    onClick={() => {
                      if (paymentMethod.key !== "Paystack") {
                        handleNavigateToPaymentPageTwo(paymentMethod?.key);
                      } else {
                        // Trigger paystack API
                        handleProceedToPay();
                      }
                    }}
                    className="payment-method-button-box-wrapper--inner"
                    disabled={isLoading}
                  >
                    {paymentMethod?.value}
                  </button>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>

        <div style={{ marginTop: "15px" }}>
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default PolicyStageFive;
