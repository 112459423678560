import React, { useContext, useEffect, useState } from "react";
import "./Certificates.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import searchIcon from "../../images/search-icon.svg";
import filterIcon from "../../images/filter-icon.svg";
import CertificatesTable from "./certificates-table/CertificatesTable";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getACompanyCerificatesForBroker,
  getAllBrokerCertificates,
  getAllCustomerCertificates,
} from "../../redux/actions/customerPoliciesActions";
import ErrorHandler from "../../components/error/ErrorHandler";
import isEmpty from "../../validation/isEmpty";
import { Pagination } from "../../components/pagination/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import arrowLeftIcon from "../../images/arrow-left-icon.svg";
import SuccessHandler from "../../components/success/SuccessHandler";
import {
  ISingleUser,
  UserContext,
} from "../../components/contexts/UserContext";
import CertificateInfoModal from "../../components/modals/certificate-info-modal/CertificateInfoModal";

// Interfaces
export interface ISingleCertificate {
  id: string;
  currency: string;
  exchangeRate: number;
  customerId: string;
  policyId: string;
  premium: number;
  bankName: string;
  cargoDescription: string;
  loadingPort: string;
  loadingPortID: number;
  destinationPort: string;
  destinationPortID: number;
  invoiceNumber: string;
  lcNumber: string;
  sumInsured: number;
  valueOfGoods: number;
  conveyanceID: number;
  conveyance: string;
  createdAt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  clause: string;
  certificateNo: number;
  customer: ISingleUser;
  eskaCertificateInfo: {
    EffectiveDate: string;
    SegmentCode: string;
  }[];
  policyType: string;
  paymentMethod: string;
  status: string;
  subsidiaryId: string;
  policy: {
    id: string;
    customerId: string;
    eskaPolicyInfo: {
      SegmentCode: string;
    };
    createdAt: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
    policyType: string;
  };
}

function Certificates() {
  // Functions, states and variables
  const { user }: any = useContext(UserContext);
  const certificates: ISingleCertificate[] | [] = useAppSelector(
    (state) => state.policies.certificates
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [allCertificates, setAllCertificates] = useState<
    ISingleCertificate[] | null
  >(null);
  const [currentCertificatesPosts, setCurrentCertificatesPosts] = useState<
    ISingleCertificate[] | null
  >(null);
  const [certificateStatusFilter, setCertificateStatusFilter] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [policyTypeFilterOption, setPolicyTypeFilterOption] =
    useState("OpenCover");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  //
  const [isCertificateOptionsModalOpened, setIsCertificateOptionsModalOpened] =
    useState(false);
  const [isCertificateFilterModalOpened, setIsCertificateFilterModalOpened] =
    useState(false);

  // From Companies History States
  const [isRoutedFromCompaniesHistory, setIsRoutedFromCompaniesHistory] =
    useState(false);
  // From Subsidiaries History States
  const [isRoutedFromSubsidiariesHistory, setIsRoutedFromSubsidiariesHistory] =
    useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  // Cerficate View Modal
  const [isCertificateInfoModalOpened, setIsCertificateInfoModalOpened] =
    useState(false);
  const [selectedCertificateForModalView, setSelectedCertificateForModalView] =
    useState<ISingleCertificate | null>(null);
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function (filterParam: string) {
    if (!certificates) return false;
    if (filterParam === "all") {
      const certificatesData = certificates
        ?.filter(
          (certificate) => certificate.policyType === policyTypeFilterOption
        )
        ?.filter(
          (certificate) =>
            certificate.loadingPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.destinationPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.cargoDescription
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state to all certificate rows
      if (!certificatesData) return false;
      setAllCertificates(certificatesData);
      const currentRows = certificatesData.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentCertificatesPosts(currentRows);
    } else if (filterParam === "processed") {
      // Update the certificates state to accomodate only processed certificates
      const certificatesData = certificates
        ?.filter(
          (certificate) =>
            certificate.policyType === policyTypeFilterOption &&
            certificate.status === "Processed"
        )
        ?.filter(
          (certificate) =>
            certificate.loadingPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.destinationPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.cargoDescription
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state to approved rows
      if (!certificatesData) return false;
      setAllCertificates(certificatesData);
      const currentRows = certificatesData.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentCertificatesPosts(currentRows);
    } else if (filterParam === "in-trade-portal") {
      // Update the certificates state to accomodate only In-Trade-Portal certificates
      const certificatesData = certificates
        ?.filter(
          (certificate) =>
            certificate.policyType === policyTypeFilterOption &&
            certificate.status === "In-Trade-Portal"
        )
        ?.filter(
          (certificate) =>
            certificate.loadingPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.destinationPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.cargoDescription
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state to in-transit rows
      if (!certificatesData) return false;
      setAllCertificates(certificatesData);
      const currentRows = certificatesData.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentCertificatesPosts(currentRows);
    } else if (filterParam === "pending") {
      // Update the certificates state to accomodate only pending certificates
      const certificatesData = certificates
        ?.filter(
          (certificate) =>
            certificate.policyType === policyTypeFilterOption &&
            certificate.status === "Pending"
        )
        ?.filter(
          (certificate) =>
            certificate.loadingPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.destinationPort
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) ||
            certificate.cargoDescription
              .toLowerCase()
              .includes(searchedValue.toLowerCase())
        );

      // Update table state to rejected rows
      if (!certificatesData) return false;
      setAllCertificates(certificatesData);
      const currentRows = certificatesData.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentCertificatesPosts(currentRows);
    }
  };

  // handle Change Filter Option
  const handleChangeFilterOption = function (filterParam: string) {
    setPolicyTypeFilterOption(filterParam);
    setSearchedValue("");
    // setCertificateStatusFilter("all");
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
      if (!isRoutedFromCompaniesHistory) {
        // Check if user is a broker or a customer
        if (user?.customerType === "Broker") {
          // For Brokers
          dispatch(getAllBrokerCertificates(setErrorHandlerObj, setIsLoading));
          // console.log("Fetching certificates for broker");
        } else {
          // For Customers
          dispatch(
            getAllCustomerCertificates(setErrorHandlerObj, setIsLoading)
          );
          // console.log("Fetching certificates for customer");
        }
      } else {
        //  Dispatch call to get certificates for the linked company ID
        const { companyId }: any = location.state;
        if (companyId)
          dispatch(
            getACompanyCerificatesForBroker(
              companyId,
              setErrorHandlerObj,
              setIsLoading
            )
          );
        // console.log("Fetching certificates for selected broker company");
      }
    }
  }, [successHandlerObj]);

  useEffect(() => {
    setPolicyTypeFilterOption("OpenCover");
  }, [certificates]);

  useEffect(() => {
    handleFilterTable(certificateStatusFilter || "all");
  }, [
    certificates,
    searchedValue,
    certificateStatusFilter,
    policyTypeFilterOption,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [policyTypeFilterOption, searchedValue, certificateStatusFilter]);

  useEffect(() => {
    setIsRoutedFromCompaniesHistory(false);
    setSelectedCompanyName("");
    if (location.state) {
      // FOR COMPANIES, viewing certificate history of  companies
      const { isCompaniesHistory, companyId, companyName }: any =
        location.state;
      // Check if its navigated from companies hisstory
      if (isCompaniesHistory) {
        //
        setIsRoutedFromCompaniesHistory(true);
        setSelectedCompanyName(companyName);
        // Dispatch call to get certificates for the linked company ID
        dispatch(
          getACompanyCerificatesForBroker(
            companyId,
            setErrorHandlerObj,
            setIsLoading
          )
        );
        //
        // console.log(
        //   "Fetching certificates for selected broker company",
        //   companyId
        // );
      }

      // FOR CUSTOMERS, viewing certificate history of subsidiaries
      const { isSubsidiariesHistory, subsidiaryId, subsidiaryName }: any =
        location.state;
      // Check if its navigated from subsidiaries hisstory
      if (isSubsidiariesHistory) {
        //
        setIsRoutedFromSubsidiariesHistory(true);
        setSelectedCompanyName(subsidiaryName);
        // Dispatch call to get certificates for the linked subsidiary ID
        dispatch(
          getACompanyCerificatesForBroker(
            subsidiaryId,
            setErrorHandlerObj,
            setIsLoading
          )
        );
        //
        // console.log(
        //   "Fetching certificates for selected subsidiary company",
        //   companyId
        // );
      }
    } else {
      if (!isEmpty(user)) {
        // Else display users normal certificates
        // Check if user is a broker or a customer
        if (user?.customerType === "Broker") {
          // For Brokers
          dispatch(getAllBrokerCertificates(setErrorHandlerObj, setIsLoading));
          //
          // console.log("Fetching certificates for broker");
        } else {
          // For Customers
          dispatch(
            getAllCustomerCertificates(setErrorHandlerObj, setIsLoading)
          );
          //
          // console.log("Fetching certificates for customer");
        }
      }
    }
  }, [user, location]);

  return (
    <div
      className={`certificates-container ${
        isCertificateFilterModalOpened || isCertificateOptionsModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      {/* Certificate Info Modal */}
      <CertificateInfoModal
        isModalOpened={isCertificateInfoModalOpened}
        setIsModalOpened={setIsCertificateInfoModalOpened}
        selectedCertificate={selectedCertificateForModalView}
      />
      <Top
        pageSummaryMessage={
          isRoutedFromCompaniesHistory
            ? ""
            : isRoutedFromSubsidiariesHistory
            ? "Track certificates generated for subsidiary here"
            : "Track your generated certificates here"
        }
      />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsCertificateFilterModalOpened(false);
          setIsCertificateOptionsModalOpened(false);
        }}
        className={`gray-overlay ${
          isCertificateFilterModalOpened || isCertificateOptionsModalOpened
            ? ""
            : "none"
        }`}
      ></div>
      <div className="certificates-container--inner">
        <Sidebar
          activeName={
            isRoutedFromCompaniesHistory
              ? "Companies"
              : isRoutedFromSubsidiariesHistory
              ? "Subsidiaries"
              : "Certificates"
          }
        />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        {/* Sucess Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app-error-success-message-wrapper"
        />

        {isRoutedFromCompaniesHistory && (
          <div className="selected-company-name-wrapper">
            <div className="certificates--back-button-wrapper">
              <button onClick={() => navigate("/companies")}>
                <img src={arrowLeftIcon} alt="" />
              </button>
            </div>
            {selectedCompanyName}
          </div>
        )}
        <section className="certificates-container-main-section">
          {/* certificates filter section */}
          <div className="certificates-filter-container">
            {/* Pending filter option */}
            <div
              className={`certificates-filter-option filter-option--open-cover ${
                policyTypeFilterOption === "OpenCover"
                  ? "active-filter-option"
                  : ""
              }`}
              onClick={() => handleChangeFilterOption("OpenCover")}
            >
              Open Cover
            </div>
            {/* Completed filter option */}
            <div
              className={`certificates-filter-option filter-option--single-transit ${
                policyTypeFilterOption === "SingleTransit"
                  ? "active-filter-option"
                  : ""
              }`}
              onClick={() => handleChangeFilterOption("SingleTransit")}
            >
              Single Transit
            </div>
          </div>
          {/* Certificates table section */}
          <section className="certificates-table-section">
            <div className="certificates-table--top-wrapper">
              {/* Table Title wrapper */}
              <div className="table-title-wrapper">Certificates</div>

              {/* Top Left Wrapper */}
              <div className="certificates-table--top--left-wrapper">
                {/* Table Search Input form group */}
                <div className="table-search-form-group">
                  <input
                    type="search"
                    value={searchedValue}
                    onChange={(e) => setSearchedValue(e.target.value)}
                    placeholder="search here"
                  />
                  <img src={searchIcon} alt="" />
                </div>

                <div className="filter-button-wrapper">
                  <button
                    onClick={() => setIsCertificateFilterModalOpened(true)}
                  >
                    <img src={filterIcon} alt="" />
                  </button>
                  {isCertificateFilterModalOpened && (
                    <div className={`certificate-more-options-wrapper`}>
                      {/* All filter Button Wrapper */}
                      <div className="more-options-modal-button-wrapper">
                        <button
                          className={`${
                            certificateStatusFilter === "all" &&
                            "active-filter-button"
                          }`}
                          onClick={() => {
                            setCertificateStatusFilter("all");
                            setIsCertificateFilterModalOpened(false);
                          }}
                        >
                          All
                        </button>
                      </div>
                      {/* Processed Button Wrapper */}
                      <div className="more-options-modal-button-wrapper">
                        <button
                          className={`${
                            certificateStatusFilter === "processed" &&
                            "active-filter-button"
                          }`}
                          onClick={() => {
                            setCertificateStatusFilter("processed");
                            setIsCertificateFilterModalOpened(false);
                          }}
                        >
                          Processed
                        </button>
                      </div>
                      {/* In-Trade-Portal Button Wrapper */}
                      <div className="more-options-modal-button-wrapper">
                        <button
                          className={`${
                            certificateStatusFilter === "in-trade-portal" &&
                            "active-filter-button"
                          }`}
                          onClick={() => {
                            setCertificateStatusFilter("in-trade-portal");
                            setIsCertificateFilterModalOpened(false);
                          }}
                        >
                          In-Trade-Portal
                        </button>
                      </div>
                      {/* Pending Button Wrapper */}
                      <div className="more-options-modal-button-wrapper">
                        <button
                          className={`${
                            certificateStatusFilter === "pending" &&
                            "active-filter-button"
                          }`}
                          onClick={() => {
                            setCertificateStatusFilter("pending");
                            setIsCertificateFilterModalOpened(false);
                          }}
                        >
                          Pending
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Bulk download */}
                
                {/* <div className="bulk-download-button-wrapper">
                  <button>Bulk download</button>
                </div> */}

                {/* Invite Company button */}
                {/* {isRoutedFromCompaniesHistory && (
                  <div className="invite-company-button-wrapper">
                    <button>Invite company</button>
                  </div>
                )} */}
              </div>
            </div>

            {/* Certificates Table Container */}
            <CertificatesTable
              currentPosts={currentCertificatesPosts}
              isLoading={isLoading}
              isCertificateOptionsModalOpened={isCertificateOptionsModalOpened}
              setIsCertificateOptionsModalOpened={
                setIsCertificateOptionsModalOpened
              }
              setIsCertificateInfoModalOpened={setIsCertificateInfoModalOpened}
              setSelectedCertificateForModalView={
                setSelectedCertificateForModalView
              }
              setSuccessHandlerObj={setSuccessHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
            />
            {!isLoading &&
              allCertificates &&
              !isEmpty(currentCertificatesPosts) && (
                <>
                  <Pagination
                    paginate={paginate}
                    rowsPerPage={rowsPerPage}
                    totalPosts={allCertificates.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
          </section>
        </section>
      </div>
    </div>
  );
}

export default Certificates;
