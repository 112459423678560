import React, {
  ChangeEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import "./OnboardingStageTwo.css";
import {
  IStageOneOnboardingStates,
  IStageTwoOnboardingStates,
} from "../../OnboardingStatus";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import { convertToBase64 } from "../../../../utils/convertToBase64";
import axios from "axios";
import { api } from "../../../../api/config";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UserContext } from "../../../../components/contexts/UserContext";
import { SetErrorHandlerType } from "../../../../Types";
// Interfaces
interface IProps {
  stageOneInputStates: IStageOneOnboardingStates;
  stageTwoInputStates: IStageTwoOnboardingStates;
  setStageTwoInputStates: React.Dispatch<
    React.SetStateAction<IStageTwoOnboardingStates>
  >;
  pageContent: string;
  isAddNewCompanyToBrokers: boolean;
  isAddNewSubsidiaryToCustomers: boolean;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        maxWidth: "500px",
        width: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: "14px",
            borderRadius: "20px",
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35, position: "absolute", left: "45%" }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function OnboardingStageTwo({
  stageOneInputStates,
  stageTwoInputStates,
  setStageTwoInputStates,
  pageContent,
  isAddNewCompanyToBrokers,
  isAddNewSubsidiaryToCustomers,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  //   States
  const [cacDocumentProgress, setCacDocumentProgress] = useState(100);
  const [naicomLicenseProgress, setNaicomLicenseProgress] = useState(100);
  const [utilityBillDocumentProgress, setUtilityBillDocumentProgress] =
    useState(100);
  const [directorsIdCardProgress, setDirectorsIdCardProgress] = useState([100]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState("");
  const [uploadingFileIndex, setUploadingFileIndex] = useState(0);

  // Functions
  // handle Navigate To Page Three
  const handleNavigateToPageThree = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    const isFormEmpty = Object.keys(stageTwoInputStates)?.some(
      (inputKeys) =>
        stageTwoInputStates[inputKeys as keyof typeof stageTwoInputStates] ===
        ""
    );

    const isDirectorsIDCardFieldEmpty =
      stageTwoInputStates.directorsIdCard.some(
        (directorIdCard) => directorIdCard === ""
      );

    // console.log(stageTwoInputStates);
    if (!isFormEmpty && !isDirectorsIDCardFieldEmpty) {
      e.preventDefault();
      // Go to page three
      setPageContent("stage-three");
    }
  };

  const handleUploadDocuments = function (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
    directorsIndex: number | null = null
  ) {
    // Set Error to default false state
    setErrorHandlerObj({ hasError: false, message: "" });
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      // Convert file to Base 64
      convertToBase64(selectedFile)
        .then(async (result) => {
          // //    Call the API to upload file and get the link as data
          const fileData = {
            url: result,
            use: "idcard",
          };
          setUploadingFileName(type);
          // Setting Upload progress bar to default 0 based on type
          type === "cacDocument"
            ? setCacDocumentProgress(0)
            : type === "utilityBill"
            ? setUtilityBillDocumentProgress(0)
            : type === "naicomLicense"
            ? setNaicomLicenseProgress(0)
            : setDirectorsIdCardProgress((prevIdCardProgress) => {
                if (directorsIndex === null) return prevIdCardProgress;
                directorsIdCardProgress[directorsIndex] = 0;
                return prevIdCardProgress;
              });

          directorsIndex !== null && setUploadingFileIndex(directorsIndex);
          setIsLoading(true);
          //
          const url = `${api}/marineportal/upload`;

          // Call the API to upload file and get data as response
          const { data } = await axios.post(url, fileData, {
            // Get the upload progress through the on Upload progress method on axios and set it per upload type
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                const progress =
                  (progressEvent.loaded / progressEvent.total) * 100;
                type === "cacDocument"
                  ? setCacDocumentProgress(progress)
                  : type === "utilityBill"
                  ? setUtilityBillDocumentProgress(progress)
                  : type === "naicomLicense"
                  ? setNaicomLicenseProgress(progress)
                  : setDirectorsIdCardProgress((prevIdCardProgress) => {
                      if (directorsIndex === null) return prevIdCardProgress;
                      directorsIdCardProgress[directorsIndex] = progress;
                      return prevIdCardProgress;
                    });
              }
            },
          });
          setIsLoading(false);

          // For Directors Id Card,
          let directorsIdCardArr = [...stageTwoInputStates.directorsIdCard];
          if (type === "directorsIdCard" && directorsIndex !== null) {
            directorsIdCardArr.splice(directorsIndex, 1, data.imageUrl);
            // console.log(directorsIdCardArr);
          }
          // Update the directorsIdCard
          setStageTwoInputStates((prevStateInputs) => {
            const updatedStateInputs: IStageTwoOnboardingStates = {
              ...prevStateInputs,
              ...(type === "cacDocument" && { cacDocument: data.imageUrl }),
              ...(type === "utilityBill" && { utilityBill: data.imageUrl }),
              ...(type === "directorsIdCard" && {
                directorsIdCard: directorsIdCardArr,
              }),
              ...(type === "naicomLicense" && { naicomLicense: data.imageUrl }),
            };
            // console.log(updatedStateInputs);
            return updatedStateInputs;
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorHandlerObj({ hasError: true, message: "Upload failed!" });
        });
    }
  };

  useEffect(() => {
    if (
      pageContent === "stage-two" &&
      !stageTwoInputStates.directorsIdCard[0]
    ) {
      setDirectorsIdCardProgress((prevIdCardProgress) => {
        const directorsIdCardProgressArr =
          stageTwoInputStates.directorsIdCard?.map((idCard) => {
            return 0;
          });
        return directorsIdCardProgressArr;
      });
    } else {
      setNaicomLicenseProgress(100);
      setCacDocumentProgress(100);
      setCacDocumentProgress(100);
      setDirectorsIdCardProgress((prevIdCardProgress) => {
        const directorsIdCardProgressArr =
          stageTwoInputStates.directorsIdCard?.map((idCard) => {
            return 100;
          });
        return directorsIdCardProgressArr;
      });
    }
  }, [pageContent]);

  return (
    <div className="onboarding-form-page-container onboarding-form-page-two-container">
      {/* Onboardng form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          <button onClick={() => setPageContent("stage-one")}>
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">2/3</div>
      </div>

      {/* Onboarding Form Wrapper */}
      <div className="onboarding-form-wrapper">
        <div className="onboarding-form-title-wrapper">
          Upload the documents below
        </div>

        <form autoComplete="off">
          {/* CAC Document Input Wrapper */}
          <div className="signup--upload-file-form-group">
            <div className="upload-file-form-group--top-wrapper">
              <div className="file-input-title">Upload CAC document</div>
              <div className="upload-input-wrapper">
                <input
                  id="cac-document-file-input"
                  type="file"
                  onChange={(e) => handleUploadDocuments(e, "cacDocument")}
                  required
                />
                <label htmlFor="cac-document-file-input">
                  {stageTwoInputStates.cacDocument ? "Change" : "Upload"}
                </label>
              </div>
            </div>
            {/* Progress Bar Wrapper */}
            {((isLoading && uploadingFileName === "cacDocument") ||
              stageTwoInputStates.cacDocument) && (
              <div className="file-upload-progress-bar-wrapper">
                <div className="linear-progress-bar-wrapper">
                  <LinearProgressWithLabel value={cacDocumentProgress} />
                </div>
                {stageTwoInputStates.cacDocument && (
                  <div className="view-uploaded-file-wrapper">
                    <a
                      href={stageTwoInputStates.cacDocument}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Utility Bill Input Wrapper */}
          {(user?.customerType === "Customer" ||
            isAddNewCompanyToBrokers ||
            isAddNewSubsidiaryToCustomers) && (
            <div className="signup--upload-file-form-group">
              <div className="upload-file-form-group--top-wrapper">
                <div className="file-input-title">Utility Document</div>
                <div className="upload-input-wrapper">
                  <input
                    id="cac-document-file-input"
                    type="file"
                    onChange={(e) => handleUploadDocuments(e, "utilityBill")}
                    required
                  />
                  <label htmlFor="cac-document-file-input">
                    {stageTwoInputStates.utilityBill ? "Change" : "Upload"}
                  </label>
                </div>
              </div>
              {/* Progress Bar Wrapper */}
              {((isLoading && uploadingFileName === "utilityBill") ||
                stageTwoInputStates.utilityBill) && (
                <div className="file-upload-progress-bar-wrapper">
                  <div className="linear-progress-bar-wrapper">
                    <LinearProgressWithLabel
                      value={utilityBillDocumentProgress}
                    />
                  </div>
                  {stageTwoInputStates.utilityBill && (
                    <div className="view-uploaded-file-wrapper">
                      <a
                        href={stageTwoInputStates.utilityBill}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Directors' ID Input Wrapper */}
          {stageTwoInputStates.directorsIdCard?.map((directorsCard, index) => (
            <div key={index + 1} className="signup--upload-file-form-group">
              <div className="upload-file-form-group--top-wrapper">
                <div className="file-input-title">
                  Upload director {index + 1}'s ID card
                </div>

                <div className="upload-input-wrapper">
                  <input
                    id="directors-id-card-file-input"
                    type="file"
                    onChange={(e) =>
                      handleUploadDocuments(e, "directorsIdCard", index)
                    }
                    required
                  />
                  <label htmlFor="directors-id-card-file-input">
                    {directorsCard ? "Change" : "Upload"}
                  </label>
                </div>
              </div>
              {/* Progress Bar Wrapper */}
              {((isLoading &&
                uploadingFileName === "directorsIdCard" &&
                uploadingFileIndex === index) ||
                stageTwoInputStates.directorsIdCard[index]) && (
                <div className="file-upload-progress-bar-wrapper">
                  <div className="linear-progress-bar-wrapper">
                    <LinearProgressWithLabel
                      value={directorsIdCardProgress[index]}
                    />
                  </div>
                  {stageTwoInputStates.directorsIdCard[index] && (
                    <div className="view-uploaded-file-wrapper">
                      <a
                        href={stageTwoInputStates.directorsIdCard[index]}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}

          {/* NAICOM Input Wrapper ||TODO ONLY FOR BROKERS */}
          {user?.customerType === "Broker" && !isAddNewCompanyToBrokers && (
            <div className="signup--upload-file-form-group">
              <div className="upload-file-form-group--top-wrapper">
                <div className="file-input-title">Upload NAICOM License</div>

                <div className="upload-input-wrapper">
                  <input
                    id="naicom-license-file-input"
                    type="file"
                    onChange={(e) => handleUploadDocuments(e, "naicomLicense")}
                    required
                  />
                  <label htmlFor="naicom-license-file-input">
                    {stageTwoInputStates.naicomLicense ? "Change" : "Upload"}
                  </label>
                </div>
              </div>
              {/* Progress Bar Wrapper */}
              {((isLoading && uploadingFileName === "naicomLicense") ||
                stageTwoInputStates.naicomLicense) && (
                <div className="file-upload-progress-bar-wrapper">
                  <div className="linear-progress-bar-wrapper">
                    <LinearProgressWithLabel value={naicomLicenseProgress} />
                  </div>
                  {stageTwoInputStates.naicomLicense && (
                    <div className="view-uploaded-file-wrapper">
                      <a
                        href={stageTwoInputStates.naicomLicense}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="onboarding-form-page-bottom-wrapper">
            {/* Fields mandatory wrapper */}
            <div className="field-are-mandatory-wrapper">
              All fields are mandatory*
            </div>

            {/* Submit Onboarding Form Button Wrapper */}
            <div className="submit-onboarding-form-button-wrapper">
              <button onClick={(e) => handleNavigateToPageThree(e)}>
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OnboardingStageTwo;
