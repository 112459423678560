import React from "react";
import "./PolicyStageTwo.css";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import { IAllCargosInputStates, SetErrorHandlerType } from "../../../../Types";

// Interfaces
interface IProps {
  allCargosInputStates: IAllCargosInputStates[];
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  currentActiveCargoIndex: number;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

export const methodOfCargoPayment = [
  {
    name: "I am raising an LC through the bank",
    pageValue: "raising-lc-through-bank",
  },
  { name: "I am sourcing FOREX myself", pageValue: "sourcing-forex-myself" },
];

function PolicyStageTwo({
  allCargosInputStates,
  setAllCargosInputStates,
  currentActiveCargoIndex,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables

  // States
  //   Functions
  // handle Navigate To Payment page Two / Navigate to pay by selected mode of payment
  const handleNavigateToNextPage = function (paymentMethodValue: string) {
    if (paymentMethodValue === "raising-lc-through-bank") {
      // Move on to fill bank and credit number details
      setPageContent("stage-two-five");
    } else {
      // Set current cargo LC details to default empty
      const updatedCurrentCargo: IAllCargosInputStates = {
        ...allCargosInputStates[currentActiveCargoIndex],
        bankName: "0",
      };

      // Make a copy of all cargo input states
      const allCargoInputStatesDuplicate = [...allCargosInputStates];

      // Splice the updated cargo into the duplicate cargo array
      allCargoInputStatesDuplicate.splice(
        currentActiveCargoIndex,
        1,
        updatedCurrentCargo
      );

      // Update the all cargo state with the new updated cargo array
      setAllCargosInputStates(allCargoInputStatesDuplicate);

      // Move to page three if payment method value === sourcing FOREX myself
      setPageContent("stage-three");
    }
  };

  return (
    // Payment stage one page
    <div className="policy-stage-container policy-stage-one-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          <button onClick={() => setPageContent("stage-one")}>
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">2/7</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        {/* Ploicy Plan Selection Container */}
        <div className="policy-plan-selection-container">
          {/* Title Wrapper */}
          <div className="policy-plan-selection-title-wrapper">
            Cargo payment method
          </div>

          {/* Boxes Wrapper */}
          <div className="payment-method-selection-boxes-wrapper">
            {methodOfCargoPayment?.map((paymentMethod, index) => (
              <div
                key={index + 1}
                className="payment-method-button-box-wrapper"
              >
                <button
                  onClick={() =>
                    handleNavigateToNextPage(paymentMethod.pageValue)
                  }
                  className="payment-method-button-box-wrapper--inner"
                >
                  {paymentMethod.name}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyStageTwo;
