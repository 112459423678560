import React, { MouseEvent, useEffect, useState } from "react";
import "./ChangePassword.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../hooks";
import { changePassword } from "../../redux/actions/authActions";

function ChangePassword() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  //   States
  const [prevPassword, setPrevPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPrevPasswordVisible, setIsPrevPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //   Functions
  const handleChangePassword = function (e: MouseEvent<HTMLButtonElement>) {
    // Set Error and Success Obj to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    const isPasswordSame = prevPassword === password;
    if (prevPassword !== "" && password !== "") {
      e.preventDefault();

      //   Check if Password is identical
      if (isPasswordSame) {
        setErrorHandlerObj({
          hasError: true,
          message: "Password must not be the same as the previous password!",
        });
      } else {
        const data = {
          prevPassword,
          password,
        };
        //   Call the dispatch fxn to call the change password API
        dispatch(
          changePassword(
            data,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsLoading
          )
        );
      }
    }
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return (
    <div className="change-password-container">
      <Top
        pageSummaryMessage="Change your password here."
        isOnChangePasswordPage={true}
      />

      <div className="change-password-container--inner">
        <Sidebar activeName={"Dashboard"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app-error-success-message-wrapper"
        />
        <section className="change-password-container-main-section">
          <div className="onboarding-form-title-wrapper">
            Complete the form below
          </div>

          {/* Form Wrapper */}
          <div className="change-password-form-wrapper">
            <form>
              {/* Previous Password form group */}
              <div className="form-group password-form-group confirm-password-form-group">
                <input
                  type={isPrevPasswordVisible ? "text" : "password"}
                  className={"password-form-group"}
                  value={prevPassword}
                  onChange={(e) => {
                    setPrevPassword(e.target.value);
                  }}
                  required
                  autoComplete="new-password"
                />
                <span className="placeholder">Previous Password</span>

                <img
                  src={isPrevPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                  alt=""
                  className="password-icon-wrapper"
                  onClick={() =>
                    setIsPrevPasswordVisible(!isPrevPasswordVisible)
                  }
                />
              </div>

              {/* Password form group */}
              <div className="form-group password-form-group">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  className={"password-form-group"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span className="placeholder">New Password</span>

                <img
                  src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                  alt=""
                  className="password-icon-wrapper"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                />
              </div>

              {/* Submit Auth Form Button Wrapper */}
              <div className="submit-auth-form-button-wrapper">
                <button onClick={(e) => handleChangePassword(e)}>
                  Change Password
                </button>
                <div className="auth-loading-spinner-wrapper">
                  {isLoading && <LoadingSpinner />}
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ChangePassword;
