import React, { useEffect } from "react";
import "./AuthNavbar.css";
import { useNavigate } from "react-router-dom";
// import companyLogo from "../../images/heirs-insurance-logo.png";
import companyLogo from "../../images/heirs-general.png";

interface IProps {
  page: string;
}
function AuthNavbar({ page }: IProps) {
  // States, Functions and Variables
  const navigate = useNavigate();
  // States

  const handleNavigateHome = function () {
    navigate("/");
  };

  return (
    <div className="navbar-container">
      <div className="navbar-container--inner">
        {/* Company Logo section / Left Section  */}
        <div className="company-logo-wrapper" onClick={handleNavigateHome}>
          <img src={companyLogo} alt="" />
        </div>

        {page !== "signin" && page !== "home" && (
          <div className="auth-navbar--signin-button-wrapper">
            <button onClick={() => navigate("/signin")}>Sign in</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AuthNavbar;
