import React, { useContext, useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { allNavLinks, IAllNavLinks } from "../../utils/allNavLinks";
import companyLogo from "../../images/heirs-general.png";
import { UserContext } from "../contexts/UserContext";

interface IProps {
  activeName: string;
}

const Sidebar = function ({ activeName }: IProps) {
  // Function, states and variables
  const navigate = useNavigate();
  const { user }: any = useContext(UserContext);
  // States
  const [navLinksArr, setNavLinksArr] = useState<IAllNavLinks[] | null>(null);

  // Functions
  const handleNavigateHome = function () {
    if (user?.isApproved === true) navigate("/dashboard");
  };
  // UseEffects
  useEffect(() => {
    // Get users approval status from local storage
    const isUserApproved = localStorage.getItem("approvalStatus");
    const customerType = localStorage.getItem("customerType");

    // Set the nav links arr state
    setNavLinksArr((prevNavLinks) => {
      if (!allNavLinks) return null;
      // Get a duplicate of the initial all nav links
      let newAllNavLinks: IAllNavLinks[] = allNavLinks?.filter((links) => {
        if (customerType === "Broker") {
          // FIX
          return links.name !== "Subsidiaries";
          // return links;
        } else {
          // Filter out the false values in the array
          return links.name !== "Companies";
        }
      });
      // Filter links based on approval status
      let filteredNewAllNavLinks = newAllNavLinks?.filter((links) => {
        if (isUserApproved === "true") {
          // If user is approved, return all links
          return links;
        } else {
          // If user is NOT approved, return only mandatory links
          return links.mandatoryStatus === true;
        }
      });
      // Get current link index based on filtered links
      const index = filteredNewAllNavLinks?.findIndex(
        (links) => links.name === activeName
      );

      if (index >= 0) {
        // Update current active link
        let currentActiveLink = filteredNewAllNavLinks
          ?.filter((links) => links.name === filteredNewAllNavLinks[index].name)
          ?.map((links) => {
            return {
              ...links,
              active: true,
            };
          });
        // Replace the active link
        filteredNewAllNavLinks.splice(index, 1, currentActiveLink[0]);
      }
      return filteredNewAllNavLinks;
    });
  }, [user, activeName]);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (!token) navigate("/signin");
  }, []);

  return (
    <div className="sidebar-container">
      <div className="sidebar-container--inner">
        <div
          className="company-logo-wrapper sidebar--company-logo-wrapper"
          onClick={handleNavigateHome}
        >
          <img src={companyLogo} alt="" />
        </div>

        {/* Navigation Links Container */}
        <nav className="navigation-links-container">
          {/* Dashboard Link Wrapper */}
          {navLinksArr?.map((link, index) => (
            <div
              key={index + 1}
              className={`nav-link-container ${link?.name.toLowerCase()}-link-wrapper  ${
                link.active ? "active-link-wrapper" : ""
              }`}
            >
              <Link to={link.link}>
                <img src={link.active ? link.activeIcon : link.icon} alt="" />
                {link.name}
              </Link>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
