import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

// Interfaces
interface ISubsidiaryData {
  name: string;
  email: string;
  phone: string;
  tin: string;
  rcNumber: string;
  directors: string[];
  address: string;
}
const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;

// Onboard a new Subsidiary
export const onboardNewSubsidiary =
  (
    data: ISubsidiaryData[],
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/customer/add-sub-company`, data)
      .then((res) => {
        setIsLoading(false);
        //  Navigate to companies where success message will be displayed based on link
        navigate("/subsidiaries", { state: { isNewSubsidiaryAdded: true } });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
