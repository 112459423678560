import axios from "axios";

const setAuthToken = (token: string | boolean) => {
  if (token) {
    // Apply to every request
    // var access_token = token;
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["authorization"];
  }
};
export default setAuthToken;
