import { combineReducers } from "redux";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import customerPoliciesReducer from "./customerPoliciesReducer";
import errorReducer from "./errorReducer";
import sailLocationReducer from "./sailLocationReducer";

export default combineReducers({
  user: authReducer,
  errors: errorReducer,
  policies: customerPoliciesReducer,
  sailLocations: sailLocationReducer,
  companies: companyReducer,
});
