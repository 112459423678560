import React from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { ISingleUser } from "../../../components/contexts/UserContext";

interface IProps {
  currentPosts: ISingleUser[] | null;
  isLoading: boolean;
}

const CompaniesTable = function ({ currentPosts, isLoading }: IProps) {
  // Functions and States
  const navigate = useNavigate();
  // States

  return (
    <>
      {/*  Companies Table Container */}
      <div className="app-table-container companies-table-container">
        <Table responsive="lg" className="app--table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "Company Name",
                "TIN number",
                "Wallet Balance",
                "Status",
                "Policy History",
                "Wallet History",
                "Unit History",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((company, index) => (
              <tr key={index + 1}>
                {/* Company Name Column */}
                <td className="td-company-name">
                  <div className="td-company-name--wrapper">
                    {company.companyName}
                  </div>
                </td>

                {/*  CAC Number Column */}
                <td className="td-cac-no">
                  <div className="td-cac-no--wrapper">{company.tin}</div>
                </td>

                {/*  Wallet Balance Column */}
                <td className="td-walllet-balance">
                  <div className="td-walllet-balance--wrapper">
                    &#8358;{company?.walletBalance?.toLocaleString()}
                  </div>
                </td>

                {/*  Status Column */}
                <td className="td-status">
                  <div
                    className={`td-status--wrapper  ${
                      company?.status === "Approved"
                        ? "completed-status-wrapper"
                        : "in-transit-status-wrapper"
                    }`}
                  >
                    {company?.status === "Rejected"
                      ? "Rejected"
                      : company?.status === "Approved"
                      ? "Approved"
                      : company?.status === "Queried"
                      ? "Queried"
                      : "Pending"}
                  </div>
                </td>

                {/*  View Certificate History Button Column */}
                <td className="td-view-policy-history">
                  <div className="td-view-policy-history--wrapper">
                    <button
                      onClick={() =>
                        navigate("/company/certificates-history", {
                          state: {
                            isCompaniesHistory: true,
                            companyId: company.id,
                            companyName: company.companyName,
                          },
                        })
                      }
                    >
                      View policy history
                    </button>
                  </div>
                </td>

                {/*  View Wallet History Button Column */}
                <td className="td-view-policy-history">
                  <div className="td-view-policy-history--wrapper">
                    <button
                      onClick={() =>
                        navigate("/company/wallet-history", {
                          state: {
                            subsidiaryId: company.id,
                            subsidiaryName: company.companyName,
                            walletBalance: company?.walletBalance,
                          },
                        })
                      }
                    >
                      View wallet history
                    </button>
                  </div>
                </td>

                {/*  View Unit History Button Column */}
                <td className="td-view-policy-history">
                  <div className="td-view-policy-history--wrapper">
                    <button
                      onClick={() =>
                        navigate("/company/unit-history", {
                          state: {
                            subsidiaryId: company.id,
                            subsidiaryName: company.companyName,
                            unit: company?.unit,
                          },
                        })
                      }
                    >
                      View unit history
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default CompaniesTable;
