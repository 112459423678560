import React, { useEffect, useState } from "react";
import "./Signup.css";
import hexalSpiralImg from "../../images/hexal-spiral.svg";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import OnboardingStageOne from "./onboarding-stages/onboarding-stage-one/OnboardingStageOne";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import SignupSuccessful from "./onboarding-stages/signup-successful/SignupSuccessful";
import { useLocation } from "react-router-dom";

// Interfaces
export interface IStageOneInputStates {
  companyName: string;
  email: string;
  phoneNumber: string;
  rcNumber: string;
  password: string;
  confirmPassword: string;
  customerType: string;
}

function Signup() {
  // Functions, States and Variables
  const location = useLocation();
  // States
  const [pageContent, setPageContent] = useState("stage-one");

  // Stage One States
  const [stageOneInputStates, setStageOneInputStates] =
    useState<IStageOneInputStates>({
      email: "",
      phoneNumber: "",
      companyName: "",
      rcNumber: "",
      password: "",
      customerType: "Customer",
      confirmPassword: "",
    });

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);

  useEffect(() => {
    if (location.state) {
      const { customerType }: any = location.state;
      const updatedStageOneInputStates: IStageOneInputStates = {
        ...stageOneInputStates,
        customerType,
      };
      setStageOneInputStates(updatedStageOneInputStates);
    }
  }, [location]);
  return (
    <div className="signup-container">
      <AuthNavbar page="signup" />

      <div className="signup-container--inner">
        {/* Hexal Spiral Wrapper */}
        <div className="hexal-spiral-wrapper">
          <img src={hexalSpiralImg} alt="" />
        </div>

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        {/* Auth Left Section */}
        <section className="auth-left-section">
          {/* Primary Text Line */}
          <div className="auth-left--primary-text-line">
            You can’t predict the journey
          </div>

          {/* Secondary Text Line */}
          <div className="auth-left--secondary-text-line">
            But we can help you <span>protect it</span>
          </div>
        </section>
        {/* Auth Right Section */}
        <section className="auth-right-section">
          {pageContent === "stage-one" ? (
            <OnboardingStageOne
              stageOneInputStates={stageOneInputStates}
              setStageOneInputStates={setStageOneInputStates}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "signup-successful" ? (
            <SignupSuccessful />
          ) : (
            ""
          )}
        </section>
      </div>
    </div>
  );
}

export default Signup;
