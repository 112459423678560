import React, { useState } from "react";
import "./Homepage.css";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import hexalSpiralImg from "../../images/hexal-spiral.svg";
import heroImgOne from "../../images/hero-img-one.png";
import dropdownIcon from "../../images/dropdown-icon-white.svg";
import { useNavigate } from "react-router-dom";

function Homepage() {
  // Function, states and Variables
  const navigate = useNavigate();
  // States
  const [isSignupModalOpened, setIsSignupModalOpened] = useState(false);
  return (
    <div className="homepage-container">
      <AuthNavbar page="home" />

      {/* Hexal Spiral Wrapper */}
      <div className="hexal-spiral-wrapper">
        <img src={hexalSpiralImg} alt="" />
      </div>
      <div className="homepage-container--inner">
        {/* Home Row One Section  */}
        <section className="homepage-row-one-section">
          {/* Row One Left Wrapper */}
          <div className="homepage-row-one--left-wrapper">
            <div className="marine-policy-frame-wrapper">MARINE POLICY</div>
            <div className="homepage-row-one-main-text">
              Better insurance for a better journey
            </div>

            <div className="homepage-row-one-sub-text">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo
              orci, venenatis, suspendisse donec aliquet justo, erat sem hac. A
              malesuada ornare orci facilisi nibh metus. Mi adipiscing laoreet
              sapien lectus consectetur condimentum magna. Convallis ac eu at
              amet non. Pretium urna leo netus vitae sit faucibus vitae et
              turpis aenean semper enim.s */}
            </div>

            <div className="home-auth-action-buttons-wrapper">
              {/* Signup Button Wrapper */}
              <div className="home--signup-button-wrapper">
                <button
                  onClick={() => setIsSignupModalOpened(!isSignupModalOpened)}
                >
                  Sign up{" "}
                  <img
                    src={dropdownIcon}
                    className={isSignupModalOpened ? "rotate-icon" : ""}
                    alt=""
                  />
                </button>

                <div
                  className={`home-sign-up-link-dropdown-wrapper ${
                    isSignupModalOpened ? "" : "collapsed"
                  }`}
                >
                  {/* Signup as a broker */}
                  <div className="home-signup-as-broker-button-wrapper signup-child-button-wrapper">
                    <button
                      onClick={() =>
                        navigate("/signup", {
                          state: { customerType: "Broker" },
                        })
                      }
                    >
                      As a broker
                    </button>
                  </div>
                  {/* Signup as a customer */}
                  <div className="home-signup-as-broker-button-wrapper signup-child-button-wrapper">
                    <button
                      onClick={() =>
                        navigate("/signup", {
                          state: { customerType: "Customer" },
                        })
                      }
                    >
                      As a customer
                    </button>
                  </div>
                </div>
              </div>

              {/* Signin Button Wrapper */}
              <div className="home--signin-button-wrapper">
                <button
                  onClick={() => {
                    setIsSignupModalOpened(false);
                    navigate("/signin");
                  }}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>

          {/* Row One Right Wrapper  */}
          <div className="homepage-row-one--right-wrapper">
            <div className="hero-img-one-wrapper">
              <img src={heroImgOne} alt="" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Homepage;
