import React, { MouseEvent, useEffect, useState } from "react";
import "./OnboardingStageOne.css";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import { IStageOneInputStates } from "../../Signup";
import eyeOpenIcon from "../../../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../../../images/eye-close-icon.svg";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../../hooks";
import { registerUser } from "../../../../redux/actions/userActions";
import { useLocation, useNavigate } from "react-router-dom";

// Interfaces
interface IProps {
  stageOneInputStates: IStageOneInputStates;
  setStageOneInputStates: React.Dispatch<
    React.SetStateAction<IStageOneInputStates>
  >;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
}
function OnboardingStageOne({
  stageOneInputStates,
  setStageOneInputStates,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //   States
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referrerId, setReferrerId] = useState("");

  // Functions
  // handle Navigate To Page Two
  const handleSubmitSignupForm = function (e: MouseEvent<HTMLButtonElement>) {
    // Set error message to default false state
    setErrorHandlerObj({ hasError: false, message: "" });
    const isFormEmpty = Object.keys(stageOneInputStates)?.some(
      (inputKeys) =>
        stageOneInputStates[inputKeys as keyof typeof stageOneInputStates] ===
        ""
    );

    const isPasswordSame =
      stageOneInputStates.password === stageOneInputStates.confirmPassword;

    if (!isFormEmpty) {
      e.preventDefault();
      //   Check if password is identical
      if (!isPasswordSame) {
        setErrorHandlerObj({
          hasError: true,
          message: "Passwords don't match!",
        });
      } else {
        const data = {
          email: stageOneInputStates.email,
          companyName: stageOneInputStates.companyName,
          phone: stageOneInputStates.phoneNumber,
          rcNumber: stageOneInputStates.rcNumber,
          customerType: stageOneInputStates.customerType,
          password: stageOneInputStates.password,
          ref_id: referrerId,
        };

        //  Call the API to Create User Account
        dispatch(
          registerUser(
            data,
            setErrorHandlerObj,
            setIsLoading,
            navigate,
            setPageContent
          )
        );
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      const currentReferrerId = location.search.split("=")[1];
      setReferrerId(currentReferrerId);
    }
  }, [location]);
  return (
    <div className="onboarding-stage-one-container">
      {/* Auth Form Box Container / Stage One */}
      <div className="auth-form-box-container onboarding-stage-one-box">
        {/* Welcome Wrapper */}
        <div className="auth-welcome-wrapper">Welcome🖐</div>

        {/* Auth Title Wrapper */}
        <div className="auth-title-wrapper">Sign up to get started</div>

        {/* Form Progress Line */}
        <div className="form-progress-line-wrapper stage-one-progress-wrapper"></div>

        {/* Auth Form Wrapper */}
        <div className="signup-form-wrapper">
          <form autoComplete="off">
            {/* Dummy Input to switch off autocomplete */}
            <input
              type="text"
              autoComplete="false"
              name="hidden"
              style={{ display: "none" }}
            />

            {/* Email form group */}
            <div className="form-group email-form-group">
              <TextInput
                type={"email"}
                className={"company-name-text-input"}
                placeholder={"Email address"}
                value={stageOneInputStates.email}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneInputStates = {
                      ...prevStateInputs,
                      email: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Company Name form group */}
            <div className="form-group company-name-form-group">
              <TextInput
                type={"text"}
                className={"company-name-text-input"}
                placeholder={"Company Name"}
                value={stageOneInputStates.companyName}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneInputStates = {
                      ...prevStateInputs,
                      companyName: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Phone Number form group */}
            <div className="form-group phone-number-form-group">
              <TextInput
                type={"tel"}
                className={"phone-number-text-input"}
                placeholder={"Phone number"}
                value={stageOneInputStates.phoneNumber}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneInputStates = {
                      ...prevStateInputs,
                      phoneNumber: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* RC Number form group */}
            <div className="form-group rc-number-form-group">
              <TextInput
                type={"text"}
                className={"rc-number-text-input"}
                placeholder={"RC number"}
                value={stageOneInputStates.rcNumber}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneInputStates = {
                      ...prevStateInputs,
                      rcNumber: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Password form group */}
            <div className="form-group password-form-group">
              <input
                type={isPasswordVisible ? "text" : "password"}
                className={"password-form-group"}
                value={stageOneInputStates.password}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneInputStates = {
                      ...prevStateInputs,
                      password: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required
                autoComplete="new-password"
              />
              <span className="placeholder">Set Password</span>

              <img
                src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                alt=""
                className="password-icon-wrapper"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            </div>

            {/* Confirm Password form group */}
            <div className="form-group password-form-group confirm-password-form-group">
              <input
                type={isConfirmPasswordVisible ? "text" : "password"}
                className={"password-form-group"}
                value={stageOneInputStates.confirmPassword}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneInputStates = {
                      ...prevStateInputs,
                      confirmPassword: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required
                autoComplete="new-password"
              />
              <span className="placeholder">Repeat Password</span>

              <img
                src={isConfirmPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                alt=""
                className="password-icon-wrapper"
                onClick={() =>
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                }
              />
            </div>

            {/* Submit Auth Form Button Wrapper */}
            <div className="submit-auth-form-button-wrapper">
              <button onClick={(e) => handleSubmitSignupForm(e)}>
                Continue
              </button>
              <div className="auth-loading-spinner-wrapper">
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OnboardingStageOne;
