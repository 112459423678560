import axios from "axios";
import { api } from "../../api/config";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER } from "../Constants";
import { Navigate, NavigateFunction } from "react-router-dom";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";

interface ILoginData {
  email: string;
  password: string;
}

interface IResetPasswordData {
  email: string;
  token: string;
  password: string;
}

interface IChangePasswordData {
  prevPassword: string;
  password: string;
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;
// Login - Get User Token
export const loginUser =
  (
    userData: ILoginData,
    navigate: NavigateFunction,
    setErrorHandlerObj: React.Dispatch<
      React.SetStateAction<{
        hasError: boolean;
        message: string;
      }>
    >,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setUser: any
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/login`, userData)
      .then((res) => {
        // Save to local storage
        const { token } = res.data;
        // Set token to local storage
        localStorage.setItem("jwtToken", token);
        // Set token to Auth Header
        setAuthToken(token);
        // Decode token to get user data
        const decoded: any = jwt_decode(token);
        // Set current User
        dispatch(setCurrentUser(decoded));
        axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
        axios
          .get(`${url}/getcustomer`)
          .then((res) => {
            // console.log(res.data);
            // // Set User Context to user / customer data
            setUser(res.data);
            // Set users approval status in local storage
            localStorage.setItem("approvalStatus", res.data.isApproved);
            localStorage.setItem("customerType", res.data.customerType);
            // //Route to dashboard
            setIsLoading(false);
            // If onboarding data is empty
            // console.log(res.data.tin);
            if (res.data.isApproved === false) {
              navigate("/onboarding-status");
            } else {
              navigate("/dashboard");
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAPIError(err, dispatch, setErrorHandlerObj);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Set Logged in user
export const setCurrentUser = (decoded: any) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log Out User
export const logoutUser =
  (
    navigate: NavigateFunction,
    setUser: React.Dispatch<React.SetStateAction<null>>
  ) =>
  (dispatch: any) => {
    // Remove token from localstorage
    localStorage.removeItem("jwtToken");
    // Remove approval status from local storage
    localStorage.removeItem("approvalStatus");
    // Remove auth header for futere requests
    setAuthToken(false);
    // Set current user to {} which will set is authenticated to false
    dispatch(setCurrentUser({}));
    // Set User Context to null
    setUser(null);
    navigate("/");
  };

// Forgot Password
export const forgotPassword =
  (
    data: { email: string },
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/forgot-password`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "A reset token has been sent to your email.",
        });
        setTimeout(() => {
          navigate("/reset-password", { state: { email: data.email } });
        }, 10000);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Reset Password
export const resetPassword =
  (
    data: IResetPasswordData,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/reset-password`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Your password has been reset, kindly sign in.",
        });
        setTimeout(() => {
          navigate("/signin");
        }, 10000);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Change Password
export const changePassword =
  (
    data: IChangePasswordData,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/change-password`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Your password has been changed successfully.",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
