import React from "react";
import { IUnitHistory } from "../../../../Types";
import { Table } from "react-bootstrap";
import moment from "moment";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";

interface IProps {
  currentPosts: IUnitHistory[] | null;
  companyUnitBalance: string;
  isLoading: boolean;
}

function UnitHistoryTable({
  currentPosts,
  companyUnitBalance,
  isLoading,
}: IProps) {
  return (
    <>
      <div className="app-table-container companies-table-container">
        <Table responsive="lg" className="app--table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "Transaction Type",
                "Amount",
                // "Wallet Balance",
                "Transaction Date",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              currentPosts?.map((transaction, index) => (
                <tr key={index + 1}>
                  {/* Transaction type Column */}
                  <td className="td-type">
                    <div
                      className={`td-type--wrapper ${
                        transaction.unitType === "Credit"
                          ? "success-text-wrapper"
                          : "danger-text-wrapper"
                      }`}
                    >
                      {transaction.unitType}
                    </div>
                  </td>

                  {/*  Transaction Amount Column */}
                  <td className="td-amount">
                    <div className="td-amount--wrapper">
                     {transaction.value?.toLocaleString()}
                    </div>
                  </td>

                  {/*  Wallet Balance Column */}
                  {/* <td className="td-amount">
                    <div className="td-amount--wrapper">
                      &#8358;{companyWalletBalance?.toLocaleString()}
                    </div>
                  </td> */}

                  {/*  Transaction date Column */}
                  <td className="td-walllet-balance">
                    <div className="td-walllet-balance--wrapper">
                      {moment(transaction.createdAt.date).format("L")}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
}

export default UnitHistoryTable;
