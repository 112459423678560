import { ChangeEvent } from "react";
import * as XLSX from "xlsx";
import { SetErrorHandlerType } from "../Types";
import isEmpty from "../validation/isEmpty";
import { compulsoryExcelAttributes } from "./compulsoryExcelAttributes";

export const handleParseXlsxToJson = function (
  e: ChangeEvent<HTMLInputElement>,
  setCargoJSONArr: React.Dispatch<React.SetStateAction<any[]>>,
  setErrorHandlerObj: SetErrorHandlerType,
  setPageContent: React.Dispatch<React.SetStateAction<string>>
) {
  // files is an array of file
  if (e.target.files) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const allCargoJSON: any[] = XLSX.utils.sheet_to_json(worksheet);
        // Check If all the necessary values are filled, else throw an error
        let rowWithEmptyAttributes = 0;
        let isAttributeEmpty = false;

        for (let index = 0; index < allCargoJSON.length; index++) {
          // Update the index of the json currently being checked
          rowWithEmptyAttributes = index;
          let currentEmptyAttribute;
          isAttributeEmpty = compulsoryExcelAttributes.some(
            (cargoAttribute) => {
              // Return true for empty attributes or attributes with functional error within spreadsheet application used("#ERROR")
              if (
                isEmpty(allCargoJSON[index][cargoAttribute]) ||
                allCargoJSON[index][cargoAttribute] === "#ERROR"
              ) {
                currentEmptyAttribute = cargoAttribute;
                return true;
              }
            }
          );
          // Break out of the for loop if an attribute has been detected to be empty or has error
          if (isAttributeEmpty) {
            // console.log(
            //       "broke",
            //       isAttributeEmpty,
            //       rowWithEmptyAttributes + 1,
            //       currentEmptyAttribute
            //     );
            setErrorHandlerObj({
              hasError: true,
              message: `Row ${
                rowWithEmptyAttributes + 1
              } has an empty or error* value on ${currentEmptyAttribute}!`,
            });
            break;
          }
        }
        // If for loop iterartion has been completed and no attribute is empty
        if (!isAttributeEmpty) {
          // console.log(allCargoJSON, "json");
          setCargoJSONArr(allCargoJSON);
          // Set Page content to xlsx policy summary page
          setPageContent("policy-json-process");
        }
      }
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  }
};
