import React from "react";
import "./UserPaused.css";

function UserPaused() {
  return (
    <div className="user-paused-container">
      <div className="user-paused-main-text-wrapper">
        This account has been paused by the Heirs Insurance Team. Kindly contact
        the admin for more information.
      </div>
    </div>
  );
}

export default UserPaused;
