import React, { MouseEvent, useEffect, useState } from "react";
import "./ResetPassword.css";
import { useLocation, useNavigate } from "react-router-dom";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import ErrorHandler from "../../components/error/ErrorHandler";
import hexalSpiralImg from "../../images/hexal-spiral.svg";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import TextInput from "../../components/input-components/text-input/TextInput";
import { useAppDispatch } from "../../hooks";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import { resetPassword } from "../../redux/actions/authActions";
import SuccessHandler from "../../components/success/SuccessHandler";

function ResetPassword() {
  // Functions, States and variables
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // States
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  const handleChangePassword = function (e: MouseEvent<HTMLButtonElement>) {
    // Set Error and Success Obj to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    const isPasswordSame = password === confirmPassword;
    if (token !== "" && password !== "" && confirmPassword !== "") {
      e.preventDefault();
      //   Check if Password is identical
      if (!isPasswordSame) {
        setErrorHandlerObj({
          hasError: true,
          message: "Passwords don't match!",
        });
      } else {
        const data = {
          email,
          token,
          password,
        };

        // Call the dispatch function to call the Forgot Password
        dispatch(
          resetPassword(
            data,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsLoading,
            navigate
          )
        );
      }
    }
  };

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);

  useEffect(() => {
    if (location.state) {
      const { email }: any = location.state;
      if (email) setEmail(email);
    } else {
      // if there isn't any state in the location
      navigate("/forgot-password");
    }
  }, [location]);

  return (
    <div className="reset-password-container">
      <AuthNavbar page="reset-password" />

      <div className="reset-password-container--inner">
        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="auth-error-success-message-wrapper"
        />
        {/* Hexal Spiral Wrapper */}
        <div className="hexal-spiral-wrapper">
          <img src={hexalSpiralImg} alt="" />
        </div>
        {/* Auth Left Section */}
        <section className="auth-left-section">
          {/* Primary Text Line */}
          <div className="auth-left--primary-text-line">
            You can’t predict the journey
          </div>

          {/* Secondary Text Line */}
          <div className="auth-left--secondary-text-line">
            But we can help you <span>protect it</span>
          </div>
        </section>
        {/* Auth Right Section */}
        <section className="auth-right-section">
          {/* Auth Form Box Container */}
          <div className="auth-form-box-container">
            {/* Welcome Wrapper */}
            <div className="auth-welcome-wrapper">Hello🖐</div>

            {/* Auth Title Wrapper */}
            <div className="auth-title-wrapper">Reset Password</div>

            {/* Form Progress Line */}
            <div className="form-progress-line-wrapper"></div>

            {/* Forgot Password Text Message */}
            <div className="reset-password-header-message">
              Fill in your token and new password
            </div>

            {/* Auth Form Wrapper */}
            <div className="signin-form-wrapper reset-password-form-wrapper">
              <form>
                {/* Token form group */}
                <div className="form-group">
                  <TextInput
                    type={"text"}
                    className={"token-text-input"}
                    placeholder={"Token"}
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    required={true}
                    maxLength={6}
                  />
                </div>

                {/* Password form group */}
                <div className="form-group password-form-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className={"password-form-group"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="placeholder">Password</span>

                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                </div>

                {/* Confirm Password form group */}
                <div className="form-group password-form-group confirm-password-form-group">
                  <input
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    className={"password-form-group"}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    required
                    autoComplete="new-password"
                  />
                  <span className="placeholder">Repeat Password</span>

                  <img
                    src={isConfirmPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  />
                </div>

                {/* Submit Auth Form Button Wrapper */}
                <div className="submit-auth-form-button-wrapper">
                  <button onClick={(e) => handleChangePassword(e)}>
                    Change Password
                  </button>
                  <div className="auth-loading-spinner-wrapper">
                    {isLoading && <LoadingSpinner />}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ResetPassword;
