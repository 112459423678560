import React from "react";
import "./TextareaInput.css";

interface IProps {
  id?: string;
  className: string;
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  onFocus?: (e: any) => void;
}
// Textarea Input
const TextareaInput = ({
  id,
  className,
  placeholder,
  value,
  onChange,
  required,
  disabled,
  onFocus,
}: IProps) => {
  return (
    <>
      <textarea
        id={className}
        className={className}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
      />
      <span className="placeholder">{placeholder}</span>
    </>
  );
};

export default TextareaInput;
