import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LatestCertificatesTable.css";
import { Table } from "react-bootstrap";
import moment from "moment";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleCertificate } from "../../certificates/Certificates";
import eyeIcon from "../../../images/eye-open-icon.svg";
import playIcon from "../../../images/play-icon.svg";
import CertificateInfoModal from "../../../components/modals/certificate-info-modal/CertificateInfoModal";

interface IProps {
  latestCertificates: ISingleCertificate[] | null;
  isLoading: boolean;
}

const LatestCertificatesTable = function ({
  latestCertificates,
  isLoading,
}: IProps) {
  // Function, States and variables
  const navigate = useNavigate();
  // Cerficate View Modal
  const [isCertificateInfoModalOpened, setIsCertificateInfoModalOpened] =
    useState(false);
  const [selectedCertificateForModalView, setSelectedCertificateForModalView] =
    useState<ISingleCertificate | null>(null);

  // Functions

  // Handle Open Info Modal For Current Certificate
  const handleOpenCertificateInfoModal = function (
    certificate: ISingleCertificate
  ) {
    setSelectedCertificateForModalView(certificate);
    setIsCertificateInfoModalOpened(true);
  };

  return (
    <>
      {/* Certificate Info Modal */}
      <CertificateInfoModal
        isModalOpened={isCertificateInfoModalOpened}
        setIsModalOpened={setIsCertificateInfoModalOpened}
        selectedCertificate={selectedCertificateForModalView}
      />
      {/* Latest Certificates Table Container */}
      <div className="app-table-container latest-certificates-table-container">
        <Table responsive="lg" className="app--table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "Date",
                "Cargo Description",
                "Shipping",
                // "Invoice Number",
                // "Sum Insured",
                "Status",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {latestCertificates?.map((certificate, i) => (
              <tr key={i + 1}>
                {/* Date Column */}
                <td className="td-date">
                  <div className="td-date--wrapper">
                    {moment(certificate.createdAt.date).format("L")}
                  </div>
                </td>

                {/*  Cargo description Column */}
                <td className="td-description">
                  <div className="td-description--wrapper">
                    {certificate.cargoDescription.substring(0, 20).trim()}
                    {certificate.cargoDescription.length > 20 && "..."}
                  </div>
                </td>

                {/*  Cargo Shipping Column */}
                <td className="td-shipping">
                  <div className="td-shipping--wrapper">
                    {certificate.loadingPort} - {certificate.destinationPort}
                  </div>
                </td>

                {/*  Invoice Number Column */}
                {/* <td className="td-invoice-number">
                  <div className="td-invoice-number--wrapper">
                    {certificate.invoiceNumber}
                  </div>
                </td> */}

                {/*  Sum Insured Column */}
                {/* <td className="td-sum-insured">
                  <div className="td-sum-insured--wrapper">
                    &#8358;{certificate.sumInsured.toLocaleString()}
                  </div>
                </td> */}

                {/*  Status Column */}
                <td className="td-status">
                  <div
                    className={`td-status--wrapper  ${
                      certificate.status === "Processed"
                        ? "completed-status-wrapper"
                        : certificate.status === "Cancelled"
                        ? "cancelled-status-wrapper"
                        : // : "rejected-status-wrapper"
                          "in-transit-status-wrapper"
                    }`}
                  >
                    {certificate.status === "Processed"
                      ? "Processed"
                      : certificate.status === "Cancelled"
                      ? "Cancelled"
                      : // : "Rejected"
                        "Pending"}
                  </div>
                </td>

                {/*  View Certificate Info Modal Column */}
                <td className="td-view-certificate">
                  <div className="td-dashboard-view-certificate-wrapper">
                    <div className="td-view-certificate--wrapper">
                      <button
                        onClick={() =>
                          handleOpenCertificateInfoModal(certificate)
                        }
                      >
                        <img src={eyeIcon} alt="" />
                      </button>
                    </div>

                    {/* Register certificate (for pending certificates) */}
                    {certificate.status === "Pending" && (
                      <div className="td-resume-certificate--wrapper">
                        <button
                          onClick={() =>
                            navigate("/marine-policy", {
                              state: {
                                certificate,
                              },
                            })
                          }
                        >
                          <img src={playIcon} alt="" />
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default LatestCertificatesTable;
