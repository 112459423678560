import React, { useContext, useEffect, useState } from "react";
import "./OnboardingStatus.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import OnboardingStageOne from "./onboarding-stages/onboarding-stage-one/OnboardingStageOne";
import OnboardingStageTwo from "./onboarding-stages/onboarding-stage-two/OnboardingStageTwo";
import OnboardingStageThree from "./onboarding-stages/onboarding-stage-three/OnboardingStageThree";
import OnboardingSuccessful from "./onboarding-successful/OnboardingSuccessful";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import { UserContext } from "../../components/contexts/UserContext";
import OnboardingStatusTable from "./onboarding-status-table/OnboardingStatusTable";
import isEmpty from "../../validation/isEmpty";
import { useLocation, useNavigate } from "react-router-dom";
import arrowLeftIcon from "../../images/arrow-left-icon.svg";

// Interfaces
export interface IStageOneOnboardingStates {
  companyName?: string;
  companyEmail?: string;
  companyPhoneNumber?: string;
  rcNumber?: string;
  tinNumber: string;
  naicomLicenseNumber?: string;
  nameOfDirectors: string[] | [];
  estimatedAnnualSuminsured: string;
  limitPerCarriage: string;
  // streetNumber: string;
  // country: string;
  // street: string;
  // postCode: string;
  city: string;
  state: string;
  address: string;
}

export interface IStageTwoOnboardingStates {
  cacDocument: string;
  utilityBill?: string;
  directorsIdCard: string[] | [];
  naicomLicense?: string;
}

function OnboardingStatus() {
  // Functions, states and variables
  const { user }: any = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [isAddNewCompanyToBrokers, setIsAddNewCompanyToBrokers] =
    useState(false);
  const [isAddNewSubsidiaryToCustomers, setIsAddNewSubsidiaryToCustomers] =
    useState(false);
  const [isToEditExistingData, setIsToEditExistingData] = useState(false);
  const [pageContent, setPageContent] = useState("");
  // Stage One Inputs
  const [stageOneInputStates, setStageOneInputStates] =
    useState<IStageOneOnboardingStates>({
      ...(user?.customerType === "Broker" && {
        naicomLicenseNumber: "",
      }),
      tinNumber: "",
      estimatedAnnualSuminsured: "",
      limitPerCarriage: "",
      nameOfDirectors: [""],
      // streetNumber: "",
      // street: "",
      // country: "",
      // postCode: "",
      city: "",
      state: "",
      address: "",
    });

  // Stage Two Inputs
  const [stageTwoInputStates, setStageTwoInputStates] =
    useState<IStageTwoOnboardingStates>({
      cacDocument: "",
      ...(user?.customerType === "Customer" && {
        utilityBill: "",
      }),
      directorsIdCard: [""],
      ...(user?.customerType === "Broker" &&
        !isAddNewCompanyToBrokers && {
          naicomLicense: "",
        }),
    });

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);

  useEffect(() => {
    setIsAddNewCompanyToBrokers(false);
    if (!isEmpty(user)) {
      if (user?.cac !== "" && user?.tin !== "") {
        // If Current User is Onboarded
        if (location.state) {
          // Check if its navigated from companies or subsidiaries page
          const { isFromCompaniesPage, isFromSubsidiariesPage }: any =
            location.state;
          // For Brokers, adding new companies
          if (isFromCompaniesPage) {
            // Set the necessary states for adding a new company with the onboarding status component
            setIsAddNewCompanyToBrokers(true);
            setPageContent("stage-one");
          }
          // For Customers, adding new subsidiaries
          if (isFromSubsidiariesPage) {
            // Set the necessary states for adding a new subsidiary with the onboarding status component
            setIsAddNewSubsidiaryToCustomers(true);
            setPageContent("stage-one");
          }
        } else {
          // If link is not from companies to add new company
          setPageContent("completed-onboarding");
        }
      } else {
        setPageContent("stage-one");
      }
    }
  }, [user, location]);

  useEffect(() => {
    // For Add New Company Input states
    if (isAddNewCompanyToBrokers) {
      setStageOneInputStates({
        companyName: "",
        companyEmail: "",
        companyPhoneNumber: "",
        rcNumber: "",
        tinNumber: "",
        estimatedAnnualSuminsured: "",
        limitPerCarriage: "",
        nameOfDirectors: [""],
        city: "",
        state: "",
        address: "",
      });

      setStageTwoInputStates({
        cacDocument: "",
        utilityBill: "",
        directorsIdCard: [""],
      });
    }
  }, [isAddNewCompanyToBrokers]);

  useEffect(() => {
    // For Add New Subsidiary Input states
    if (isAddNewSubsidiaryToCustomers) {
      setStageOneInputStates({
        companyName: "",
        companyEmail: "",
        companyPhoneNumber: "",
        rcNumber: "",
        tinNumber: "",
        estimatedAnnualSuminsured: "",
        limitPerCarriage: "",
        nameOfDirectors: [""],
        city: "",
        state: "",
        address: "",
      });

      setStageTwoInputStates({
        cacDocument: "",
        utilityBill: "",
        directorsIdCard: [""],
      });
    }
  }, [isAddNewSubsidiaryToCustomers]);

  useEffect(() => {
    if (isToEditExistingData && !isEmpty(user)) {
      // For Stage one Inputs
      const addressArr = user.address?.split(",");
      let addressValue = "";
      for (let i = 0; i < addressArr.length - 2; i++) {
        addressValue += addressArr[i];
      }
      const stateValue = addressArr[addressArr.length - 1];
      const cityValue = addressArr[addressArr.length - 2];
      const updatedStageOneInputStates = {
        ...(user?.customerType === "Broker" && {
          naicomLicenseNumber: user.naicomLicence,
        }),
        tinNumber: user.tin,
        estimatedAnnualSuminsured: user.estimatedAnnualSuminsured?.toString(),
        limitPerCarriage: user.limitPerCarriage?.toString(),
        nameOfDirectors: user.directors,
        city: cityValue,
        state: stateValue,
        address: addressValue,
      };
      setStageOneInputStates(updatedStageOneInputStates);

      // For Stage 2 Inputs
      const updatedStageTwoInputStates = {
        cacDocument: user.cacImageUrl,
        ...(user?.customerType === "Customer" && {
          utilityBill: user.utilityImageUrl,
        }),
        directorsIdCard: user.directorsIDCardImageUrl,
        ...(user?.customerType === "Broker" &&
          !isAddNewCompanyToBrokers && {
            naicomLicense: user.naicomLicenseImageUrl,
          }),
      };
      setStageTwoInputStates(updatedStageTwoInputStates);
    }
  }, [isToEditExistingData, user]);

  return (
    <div className="onboarding-container">
      <Top
        pageSummaryMessage={
          isAddNewCompanyToBrokers
            ? "Onboard a company here"
            : isAddNewSubsidiaryToCustomers
            ? "Onboard a subsidiary here"
            : "Onboard your company here"
        }
      />
      <div className="onboarding-container--inner">
        <Sidebar
          activeName={
            isAddNewCompanyToBrokers
              ? "Companies"
              : isAddNewSubsidiaryToCustomers
              ? "Subsidiaries"
              : "Onboarding status"
          }
        />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app-error-success-message-wrapper"
        />

        <section className="onboarding-container-main-section">
          {pageContent === "stage-one" ? (
            <OnboardingStageOne
              isToEditExistingData={isToEditExistingData}
              stageOneInputStates={stageOneInputStates}
              setStageOneInputStates={setStageOneInputStates}
              stageTwoInputStates={stageTwoInputStates}
              setStageTwoInputStates={setStageTwoInputStates}
              isAddNewCompanyToBrokers={isAddNewCompanyToBrokers}
              isAddNewSubsidiaryToCustomers={isAddNewSubsidiaryToCustomers}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-two" ? (
            <OnboardingStageTwo
              stageOneInputStates={stageOneInputStates}
              stageTwoInputStates={stageTwoInputStates}
              setStageTwoInputStates={setStageTwoInputStates}
              pageContent={pageContent}
              isAddNewCompanyToBrokers={isAddNewCompanyToBrokers}
              isAddNewSubsidiaryToCustomers={isAddNewSubsidiaryToCustomers}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-three" ? (
            <OnboardingStageThree
              stageOneInputStates={stageOneInputStates}
              stageTwoInputStates={stageTwoInputStates}
              setStageOneInputStates={setStageOneInputStates}
              isAddNewCompanyToBrokers={isAddNewCompanyToBrokers}
              isAddNewSubsidiaryToCustomers={isAddNewSubsidiaryToCustomers}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "onboarding-successful" ? (
            <OnboardingSuccessful />
          ) : pageContent === "completed-onboarding" ? (
            <OnboardingStatusTable
              setPageContent={setPageContent}
              setIsToEditExistingData={setIsToEditExistingData}
            />
          ) : (
            ""
          )}
        </section>
      </div>
    </div>
  );
}

export default OnboardingStatus;
