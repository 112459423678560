import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../api/config";

// Interfaces
export interface ISingleUser {
  id: string;
  rcNumber: string;
  refId: string;
  cac: string;
  companyName: string;
  directors: string[];
  directorsIDCardImageUrl: string[];
  cacImageUrl: string;
  tin: string;
  naicomLicenseImageUrl: string;
  naicomLicence: string;
  queryReason: string;
  rejectReason: string;
  createdAt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  paymentAccess: {
    isWalletEnabled: boolean;
    isUnitEnabled: boolean;
    isCreditNoteEnabled: boolean;
    isRealTimePaymentEnabled: boolean;
  };
  rate: {
    clauseAir: number;
    clauseA: number;
    clauseB: number;
    clauseC: number;
    bulkOil: number;
    frozenFood: number;
  };
  email: string;
  status: string;
  customerType: string;
  phone: string;
  estimatedAnnualSuminsured: string;
  limitPerCarriage: string;
  excessClauseAmount: number;
  excessClausePercent: number;
  isOpenCoverPaused: boolean;
  isQueried: boolean;
  isApproved: boolean;
  isRejected: boolean;
  unit: number;
  walletBalance: number;
  address: string;
}

interface IUserContextProviderProps {
  children: React.ReactNode;
}

export interface IUserContextType {
  user: ISingleUser | null;
  setUser: React.Dispatch<React.SetStateAction<ISingleUser | null>>;
}

export const UserContext = createContext<IUserContextType | null>(null);
function UserContextProvider({ children }: IUserContextProviderProps) {
  //   const navigate = useNavigate();
  const [user, setUser] = useState<ISingleUser | null>(null);

  const fetchUser = async function () {
    const url = `${api}/marineportal`;
    const { data } = await axios.get(`${url}/getcustomer`);
    // console.log("got-hereeeee", data);
    setUser(data);
    // Set users approval status in local storage
    localStorage.setItem("approvalStatus", data.isApproved);
    localStorage.setItem("customerType", data.customerType);
  };
  const token = localStorage.getItem("jwtToken");
  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      // Navigate to signin
    }
  }, [token]);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
