import axios from "axios";
import { api } from "../../api/config";
import {
  SetErrorHandlerType,
  SetIsLoadingType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

interface IContactAdminData {
  message: string;
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;
// Contact Admin
export const contactAdmin =
  (
    data: IContactAdminData,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsLoading: SetIsLoadingType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/contact-admin`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Message sent to admin successfully!",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
