import dashboardIcon from "../images/dashboard-icon.svg";
import dashboardIconActive from "../images/dashboard-icon-active.svg";
import onboardingIcon from "../images/onboarding-icon.svg";
import onboardingIconActive from "../images/onboarding-icon-active.svg";
import marineIcon from "../images/marine-icon.svg";
import marineIconActive from "../images/marine-icon-active.svg";
import certificateIcon from "../images/certificate-icon.svg";
import certificateIconActive from "../images/certificate-icon-active.svg";
import contactIcon from "../images/contact-icon.svg";
import contactIconActive from "../images/contact-icon-active.svg";
import companiesIcon from "../images/companies-icon.svg";
import companiesIconActive from "../images/companies-icon-active.svg";
import jwt_decode from "jwt-decode";

export interface IAllNavLinks {
  name: string;
  active: boolean;
  icon: string;
  activeIcon: string;
  link: string;
  mandatoryStatus: boolean;
}

let user: any = {};

const token = localStorage.getItem("jwtToken");
if (token) {
  const decoded = jwt_decode(token);
  if (decoded) {
    // FIX Set User Back to default decoded and uncomment the line below;
    user = decoded;
    // user = dummyUser;
  }
}

export const allNavLinks = [
  {
    name: "Dashboard",
    active: false,
    icon: dashboardIcon,
    activeIcon: dashboardIconActive,
    link: "/dashboard",
    mandatoryStatus: false,
  },
  {
    name: "Onboarding status",
    active: false,
    icon: onboardingIcon,
    activeIcon: onboardingIconActive,
    link: "/onboarding-status",
    mandatoryStatus: true,
  },
  {
    name: "Marine policy",
    active: false,
    icon: marineIcon,
    activeIcon: marineIconActive,
    link: "/marine-policy",
    mandatoryStatus: false,
  },
  {
    name: "Companies",
    active: false,
    icon: companiesIcon,
    activeIcon: companiesIconActive,
    link: "/companies",
    mandatoryStatus: false,
  },
  {
    name: "Subsidiaries",
    active: false,
    icon: companiesIcon,
    activeIcon: companiesIconActive,
    link: "/subsidiaries",
    mandatoryStatus: false,
  },
  {
    name: "Certificates",
    active: false,
    icon: certificateIcon,
    activeIcon: certificateIconActive,
    link: "/certificates",
    mandatoryStatus: false,
  },
  {
    name: "Reports",
    active: false,
    icon: contactIcon,
    activeIcon: contactIconActive,
    link: "/reports",
    mandatoryStatus: false,
  },
  {
    name: "Contact admin",
    active: false,
    icon: contactIcon,
    activeIcon: contactIconActive,
    link: "/contact-admin",
    mandatoryStatus: true,
  },
];
