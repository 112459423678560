import React, { useContext, useEffect } from "react";
import {
  ISingleUser,
  UserContext,
} from "../../../../../../components/contexts/UserContext";
import DropdownInput from "../../../../../../components/input-components/dropdown-input/DropdownInput";
import {
  IAllCargosInputStates,
  IDropdownOption,
} from "../../../../../../Types";
import isEmpty from "../../../../../../validation/isEmpty";
import { allClauseTypes } from "../../../../../../utils/allClauseTypes";
import { ISinglePolicyPlan } from "../../../policy-stage-three/PolicyStageThree";
import { getInsurancePremium } from "../../../../../../utils/generatePremium";
// interfaces
interface IProps {
  companies: [] | ISingleUser[];
  policyType: string;
  companyName: string;
  subsidiaryName: string;
  allCargosInputStates: IAllCargosInputStates[];
  allCompaniesNames: IDropdownOption[] | null;
  allSubsidiariesNames: IDropdownOption[] | null;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setSubsidiaryName: React.Dispatch<React.SetStateAction<string>>;
  setBrokerCompanyId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCompanyFromBroker: React.Dispatch<
    React.SetStateAction<ISingleUser | null>
  >;
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  selectedCompanyFromBroker: ISingleUser | null;
}

function CargoOwnerForm({
  companies,
  policyType,
  companyName,
  subsidiaryName,
  allCargosInputStates,
  allCompaniesNames,
  allSubsidiariesNames,
  setCompanyName,
  setSubsidiaryName,
  setBrokerCompanyId,
  setSelectedCompanyFromBroker,
  setAllCargosInputStates,
  selectedCompanyFromBroker,
}: IProps) {
  // Functions, States and variables
  const { user }: any = useContext(UserContext);
  // States

  // UseEffects
  useEffect(() => {
    if (
      user?.customerType === "Broker" &&
      companyName &&
      selectedCompanyFromBroker
    ) {
      // Get the clause rate for that company under the broker and replace all cargo's rate value with that rate(the rate value is initally defaultly set to the broker's rate value)
      if (!isEmpty(user)) {
        if (policyType === "SingleTransit") {
          // DO NOTHINGS
          // IF user is a broker but policy type is SINGLE TRANSIT, use the BROKERS's rate
          // Since the broker's rate is already in use, we change nothing(bra)
        } else {
          // IF user is a broker but policy type is OPEN COVER, use the SELECTED CUSTOMER's rate
          // Generate clauses available to selected broker's customer
          const allCompanyClauses = allClauseTypes?.map(
            (clauseType: IDropdownOption) => {
              const companyRates = selectedCompanyFromBroker.rate;
              return {
                value: clauseType.value,
                key: clauseType.key,
                rate:
                  companyRates[clauseType.key as keyof typeof companyRates] ||
                  0,
              };
            }
          );
          // Updaste clause rate for cargos based on the rate set for selected company
          const updatedCargoInputsArr = allCargosInputStates?.map(
            (cargoObject) => {
              // Selected Insurance Clause Object
              const selectedPlan = allCompanyClauses.filter(
                (clause: ISinglePolicyPlan) => clause.key === cargoObject.clause
              )[0];
              //   Premium Amount
              const currentCargoPremiumAmount = getInsurancePremium(
                Number(cargoObject.sumInsured),
                selectedPlan.rate
              );
              const updatedCargoObject = {
                ...cargoObject,
                premium: Number(currentCargoPremiumAmount.toFixed()),
                selectedClauseRate: selectedPlan.rate,
                rate: selectedPlan.rate.toString(),
              };
              return updatedCargoObject;
            }
          );
          // Update cargo input states
          setAllCargosInputStates(updatedCargoInputsArr);
        }
      }
    }
  }, [companyName, selectedCompanyFromBroker]);

  return (
    <form autoComplete="off" className="summary-page-form-wrapper">
      {/* Company Name form group */}
      {user?.customerType === "Broker" && (
        <div className="form-group company-name-form-group">
          <DropdownInput
            className={"company-name-text-input"}
            placeholder={"Company name"}
            value={companyName}
            onChange={(e) => {
              setCompanyName(e.target.value);
              const selectedCompany: ISingleUser[] = companies?.filter(
                (company: ISingleUser) => company.companyName === e.target.value
              );
              setBrokerCompanyId(selectedCompany[0].id);
              setSelectedCompanyFromBroker(selectedCompany[0]);
            }}
            optionArray={allCompaniesNames ? allCompaniesNames : []}
            required={true}
          />
        </div>
      )}

      {/* Subsidiary Name form group */}
      {user?.customerType === "Customer" && (
        <div className="form-group subsidiary-name-form-group">
          <DropdownInput
            className={"subsidiary-name-text-input"}
            placeholder={"Subsidiary name"}
            value={subsidiaryName}
            onChange={(e) => {
              setSubsidiaryName(e.target.value);
            }}
            optionArray={allSubsidiariesNames ? allSubsidiariesNames : []}
            required={true}
          />
        </div>
      )}
    </form>
  );
}

export default CargoOwnerForm;
