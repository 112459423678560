/* eslint-disable import/no-anonymous-default-export */
import { GET_SAIL_LOCATION } from "../Constants";

const initialState = {
  sailLocations: [],
};

export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_SAIL_LOCATION:
      return {
        ...state,
        sailLocations: action.payload,
      };
    default:
      return state;
  }
}
