import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import moment from "moment";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleCertificate } from "../Certificates";
import moreIcon from "../../../images/more-icon.svg";
import eyeIcon from "../../../images/eye-open-icon.svg";
import { UserContext } from "../../../components/contexts/UserContext";
import { PolicyDownloadTemplateForTable } from "../../../utils/PolicyDownloadTemplateForTable";
import { cancelACertificate } from "../../../redux/actions/customerPoliciesActions";
import { useAppDispatch } from "../../../hooks";
import {
  ISingleSubsidiary,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../Types";
import {
  downloadHTMLAsPDF,
  printHTMLString,
} from "../../../utils/handleFileDownloadsAsPdf";

interface IProps {
  currentPosts: ISingleCertificate[] | null;
  isLoading: boolean;
  isCertificateOptionsModalOpened: boolean;
  setIsCertificateOptionsModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsCertificateInfoModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedCertificateForModalView: React.Dispatch<
    React.SetStateAction<ISingleCertificate | null>
  >;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
}

const CertificatesTable = function ({
  currentPosts,
  isLoading,
  isCertificateOptionsModalOpened,
  setIsCertificateOptionsModalOpened,
  setIsCertificateInfoModalOpened,
  setSelectedCertificateForModalView,
  setSuccessHandlerObj,
  setErrorHandlerObj,
}: IProps) {
  // Functions and States
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user }: any = useContext(UserContext);
  // States
  const [selectedCertificate, setSelectedCertificate] =
    useState<ISingleCertificate | null>(null);
  const [selectedCertificateIndex, setSelectedCertificateIndex] = useState(0);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  // Handle Save HTML
  const handleSaveHTML = function (fileName: string, html: string) {
    // const blob = new Blob([html], { type: "text/html" });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.download = fileName;
    // link.href = url;
    // link.click();
    // downloadHTMLAsPDF(html);
    printHTMLString(html);
  };

  // Handle Download Certificate for Cargo
  // const handleDownloadCertificateForCargo = function (index: number) {
  const handleDownloadCertificateForCargo = function (id: string) {
    window.open(`${process.env.REACT_APP_CERTIFICATE_URL}/api/v1/general/download/marineportal/${id}`)
    // if (currentPosts && user) {
    //   // Get the subsidiary owning the certificate if the subsididary exists and pass it to the certificate data to get subsidiary info accordingly
    //   const subsidiaryOwningCertificate = user?.subCompanies?.find(
    //     (subsidiary: ISingleSubsidiary) =>
    //       subsidiary.id === currentPosts[index]?.subsidiaryId
    //   );
    //   handleSaveHTML(
    //     "Policy_Certificate",
    //     PolicyDownloadTemplateForTable(
    //       currentPosts[index],
    //       user,
    //       subsidiaryOwningCertificate
    //     )
    //   );
    // }
  };

  // Handle Preview Certificate for Cargo
  // const handlePreviewCertificateForCargo = function (index: number) {
  const handlePreviewCertificateForCargo = function (id: string) {
    window.open(`${process.env.REACT_APP_CERTIFICATE_URL}/api/v1/general/html/marineportal/${id}`)
    // if (currentPosts && user) {
    //   // Get the subsidiary owning the certificate if the subsididary exists and pass it to the certificate data to get subsidiary info accordingly
    //   const subsidiaryOwningCertificate = user?.subCompanies?.find(
    //     (subsidiary: ISingleSubsidiary) =>
    //       subsidiary.id === currentPosts[index]?.subsidiaryId
    //   );
    //   // console.log(currentPosts[index], subsidiaryOwningCertificate);
    //   const htmlString = PolicyDownloadTemplateForTable(
    //     currentPosts[index],
    //     user,
    //     subsidiaryOwningCertificate
    //   );
    //   localStorage.setItem("htmlPreviewString", htmlString);
    //   window.open("/certificate/preview", "_blank", "noopener,noreferrer");
    // }
  };

  // handle Cancel Certificate
  const handleCancelCertificate = function (certificate: ISingleCertificate) {
    const data = {
      certificateId: certificate.id,
    };
    dispatch(
      cancelACertificate(
        data,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsCancelLoading,
        setIsCertificateOptionsModalOpened
      )
    );
  };

  // Handle Open Info Modal For Current Certificate
  const handleOpenCertificateInfoModal = function (
    certificate: ISingleCertificate
  ) {
    setSelectedCertificateForModalView(certificate);
    setIsCertificateInfoModalOpened(true);
  };

  useEffect(() => {
    // Sets local storage html string value to default empty(removed)
    localStorage.removeItem("htmlPreviewString");
  }, []);
  return (
    <>
      {/*  Certificates Table Container */}
      <div className="app-table-container certificates-table-container">
        <Table responsive="lg" className="app--table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["Date", "Cargo Description", "Shipping", "Status", "", ""].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              currentPosts?.map((certificate, index) => (
                <tr key={index + 1}>
                  {/* Date Column */}
                  <td className="td-date">
                    <div className="td-date--wrapper">
                      {moment(certificate.createdAt.date).format("L")}
                    </div>
                  </td>

                  {/*  Cargo description Column */}
                  <td className="td-description">
                    <div className="td-description--wrapper">
                      {certificate.cargoDescription.substring(0, 20).trim()}
                      {certificate.cargoDescription.length > 20 && "..."}
                    </div>
                  </td>

                  {/*  Cargo Shipping Column */}
                  <td className="td-shipping">
                    <div className="td-shipping--wrapper">
                      {certificate.loadingPort} - {certificate.destinationPort}
                    </div>
                  </td>

                  {/*  Status Column */}
                  <td className="td-status">
                    <div
                      className={`td-status--wrapper  ${
                        certificate.status === "Processed"
                          ? "completed-status-wrapper"
                          : certificate.status === "In-Trade-Portal" ||
                            certificate.status === "Cancellation initiated"
                          ? "cancelled-status-wrapper"
                          : // : "rejected-status-wrapper"
                            "in-transit-status-wrapper"
                      }`}
                    >
                      {certificate.status === "Processed"
                        ? "Processed"
                        : certificate.status === "In-Trade-Portal"
                        ? "In-Trade-Portal"
                        : certificate.status === "Cancellation initiated"
                        ? "Cancelled"
                        : "Pending"}
                    </div>
                  </td>

                  {/*  View Certificate Info Modal Column */}
                  <td className="td-view-certificate">
                    <div className="td-view-certificate--wrapper">
                      <button
                        onClick={() =>
                          handleOpenCertificateInfoModal(certificate)
                        }
                      >
                        <img src={eyeIcon} alt="" />
                      </button>
                    </div>
                  </td>

                  {/*  Options Column */}
                  <td className="td-options">
                    <div className="td-options--wrapper">
                      {certificate.status !== "Pending" && (
                        <button
                          onClick={() => {
                            setIsCertificateOptionsModalOpened(true);
                            setSelectedCertificate(certificate);
                            setSelectedCertificateIndex(index);
                          }}
                          className="more-options-button"
                        >
                          <img src={moreIcon} alt="" />
                        </button>
                      )}

                      {/* Register certificate (for pending certificates) */}
                      {certificate.status === "Pending" && (
                        <div className="td-view-policy-history--wrapper">
                          <button
                            onClick={() =>
                              navigate("/marine-policy", {
                                state: {
                                  certificate,
                                },
                              })
                            }
                          >
                            Register certificate
                          </button>
                        </div>
                      )}

                      {/* More Option Modal */}
                      {isCertificateOptionsModalOpened &&
                        selectedCertificateIndex === index && (
                          <div
                            className={`certificate-more-options-wrapper ${
                              (index + 1) % 5 === 0
                                ? "fifth-multiple-wrapper"
                                : ""
                            }`}
                          >
                            {/* View Button Wrapper */}
                            {certificate.status !== "Pending" && (
                              <div className="more-options-modal-button-wrapper">
                                <button
                                  onClick={() => {
                                    // handlePreviewCertificateForCargo(index);
                                    handlePreviewCertificateForCargo(certificate.id);
                                    setIsCertificateOptionsModalOpened(false);
                                  }}
                                >
                                  View
                                </button>
                              </div>
                            )}
                            {/* Download Button Wrapper */}
                            {certificate.status !== "Pending" &&
                              certificate.status !==
                                "Cancellation initiated" && (
                                <div className="more-options-modal-button-wrapper">
                                  <button
                                    onClick={() => {
                                      handleDownloadCertificateForCargo(certificate.id);
                                      setIsCertificateOptionsModalOpened(false);
                                    }}
                                  >
                                    Download
                                  </button>
                                </div>
                              )}
                            {/* Request Cancellation Button Wrapper */}

                            {certificate.status !==
                              "Cancellation initiated" && (
                              <div className="more-options-modal-button-wrapper request-cancellation-button-wrapper">
                                <button
                                  onClick={() => {
                                    handleCancelCertificate(certificate);
                                    setIsCertificateOptionsModalOpened(false);
                                  }}
                                >
                                  Request cancellation
                                  {isCancelLoading && <LoadingSpinner />}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default CertificatesTable;
