export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_ADMIN_USERS = "GET_ALL_ADMIN_USERS";
// Customer Policies
export const GET_CUSTOMER_CERTIFICATES = "GET_CUSTOMER_CERTIFICATES";
export const GET_BROKER_CERTIFICATES = "GET_BROKER_CERTIFICATES";
export const GET_A_COMPANY_CERTIFICATES_FOR_BROKER =
  "GET_A_COMPANY_CERTIFICATES_FOR_BROKER";
export const GET_CERTIFICATE_WITH_INTERESTS = "GET_CERTIFICATE_WITH_INTERESTS";
// Sail Location
export const GET_SAIL_LOCATION = "GET_SAIL_LOCATION";
// Companies
export const GET_ALL_COMPANIES_FOR_BROKER = "GET_ALL_COMPANIES_FOR_BROKER";
export const GET_COMPANY_WALLET_HISTORY = "GET_COMPANY_WALLET_HISTORY";
