import React, { useEffect } from "react";
import "./App.css";
import "./styles/Table.css";
import "./styles/bootstrap-css/bootstrap.min.css?v=1";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import Signin from "./pages/signin/Signin";
import Signup from "./pages/signup/Signup";
import Certificates from "./pages/certificates/Certificates";
import Dashboard from "./pages/dashboard/Dashboard";
import OnboardingStatus from "./pages/onboarding-status/OnboardingStatus";
import MarinePolicy from "./pages/marine-policy/MarinePolicy";
import ContactAdmin from "./pages/contact-admin/ContactAdmin";
import { Provider } from "react-redux";
import store from "./store";
import UserContextProvider from "./components/contexts/UserContext";
// import CertificatesCargo from "./pages/certificates/certificates-cargo/CertificatesCargo";
import { PolicyDownloadTemplateForTablePreview } from "./utils/PolicyDownloadTemplateForTablePreview";
import Companies from "./pages/companies/Companies";
import Subsidiaries from "./pages/subsidiaries/Subsidiaries";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import ChangePassword from "./pages/change-password/ChangePassword";
import AddNewSubsidiary from "./pages/subsidiaries/add-new-subsidiary/AddNewSubsidiary";
import WalletHistory from "./pages/companies/wallet-history/WalletHistory";
import UnitHistory from "./pages/companies/unit-history/UnitHistory";
import Reports from "./pages/reports/Reports";
function App() {
  return (
    <div className="app">
      <Provider store={store}>
        <UserContextProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/signup" element={<Signup />} />
              {/* Dashboard */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/onboarding-status" element={<OnboardingStatus />} />
              <Route path="/marine-policy" element={<MarinePolicy />} />
              {/* Companies */}
              <Route path="/companies" element={<Companies />} />
              <Route
                path="/company/certificates-history"
                element={<Certificates />}
              />
              <Route
                path="/company/wallet-history"
                element={<WalletHistory />}
              />
              <Route path="/company/unit-history" element={<UnitHistory />} />
              <Route path="/company/add-new" element={<OnboardingStatus />} />
              {/* Subsidiaries */}
              <Route path="/subsidiaries" element={<Subsidiaries />} />
              <Route
                path="/subsidiary/certificates-history"
                element={<Certificates />}
              />
              <Route
                path="/subsidiary/add-new"
                element={<AddNewSubsidiary />}
              />
              <Route path="/certificates" element={<Certificates />} />
              <Route
                path="/certificate/preview"
                element={<PolicyDownloadTemplateForTablePreview />}
              />
              {/* <Route
                path="/certificates/cargos"
                element={<CertificatesCargo />}
              /> */}
              <Route path="/reports" element={<Reports />} />
              <Route path="/contact-admin" element={<ContactAdmin />} />
              {/* Forgot Password */}
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Routes>
          </Router>
        </UserContextProvider>
      </Provider>
    </div>
  );
}

export default App;
