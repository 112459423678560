import React, { useState, useEffect, FC } from "react";
import cancelImg from "../../images/cancel.svg";
import successIcon from "../../images/success-icon.svg";
import "./SuccessHandler.css";

interface IProps {
  successHandlerObj: {
    isSuccess: boolean;
    message: string;
  };
  className?: string;
}
const SuccessHandler: React.FC<IProps> = function ({
  successHandlerObj,
  className,
}) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
    if (successHandlerObj.isSuccess) {
      setShow(true);
    }
  }, [successHandlerObj]);

  return (
    <>
      {show ? (
        <div className={`success-handler-container ${className || ""}`}>
          {/* {className} */}
          <div className="success-handler-header">
            <div className="success-icon-wrrapper">
              <img src={successIcon} alt="" />
            </div>
            <div className="success-handler-title">
              {successHandlerObj.message}
            </div>

            <div className="cancel-error-buutton-wrrapper">
              <button onClick={() => setShow(false)}>
                <img src={cancelImg} alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SuccessHandler;
