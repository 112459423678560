import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../../components/contexts/UserContext";
import {
  IAllCargosInputStates,
  IDropdownOption,
} from "../../../../../../Types";
import { allBanks } from "../../../../../../utils/allBanksinNigeria";
import { allClauseTypes } from "../../../../../../utils/allClauseTypes";
import { ISinglePolicyPlan } from "../../../policy-stage-three/PolicyStageThree";

// Interfaces
interface IProps {
  pageContent: string;
  allCargosInputStates: IAllCargosInputStates[];
  isUploadedFromSpreadsheet: boolean;
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  setCurrentActiveCargoIndex: React.Dispatch<React.SetStateAction<number>>;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

function CargoSummaryWrapper({
  pageContent,
  allCargosInputStates,
  isUploadedFromSpreadsheet,
  setAllCargosInputStates,
  setCurrentActiveCargoIndex,
  setPageContent,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  //   States
  const [allPremiumPlans, setAllPremiumPlans] = useState<
    ISinglePolicyPlan[] | null
  >(null);

  // Functions
  // Get Premium plan name from selected plan
  const getSelectedPremiumPlanName = function (selectedPremiumPlanId: string) {
    if (allPremiumPlans) {
      const selectedPremiumPlanArr = allPremiumPlans.filter(
        (policyPlan) => policyPlan.key === selectedPremiumPlanId
      );
      return selectedPremiumPlanArr[0]?.value || "";
    }
  };

  // Handle Remove Cargo
  const handleRemoveCargo = function (index: number) {
    const allCargosArr = [...allCargosInputStates];
    // Splice out the cargo to be removed
    allCargosArr.splice(index, 1);
    // Update the cargo state
    setAllCargosInputStates(allCargosArr);
    // Set the current active cargo to initial cargo to avoid index bugs
    setCurrentActiveCargoIndex(0);
  };

  // UseEffects
  useEffect(() => {
    if (pageContent === "stage-four") {
      const plans = allClauseTypes?.map((clauseType: IDropdownOption) => {
        return {
          value: clauseType.value,
          key: clauseType.key,
          rate: user?.rate[clauseType.key] || 0,
        };
      });
      setAllPremiumPlans(plans);
    }
  }, [pageContent]);

  return (
    <div className="cargos-summary-container">
      {/*  Loop through all cargos and display summary*/}
      {allCargosInputStates?.map((cargoInput, index) => (
        <div key={index + 1}>
          <div className="summary-section-title-wrapper cargo-summary-section-title-wrapper">
            Cargo {index + 1} summary{" "}
            {/* Only Remove Cargos if there are more than one cargo */}
            {allCargosInputStates.length > 1 && (
              <button onClick={() => handleRemoveCargo(index)}>
                Remove cargo
              </button>
            )}
          </div>

          {/* Form Value Edit Box  || Premium plan*/}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">Premium plan:</div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {getSelectedPremiumPlanName(cargoInput.selectedPremiumPlan)}
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Sum Insured*/}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">Sum insured:</div>
            {/* total sum insured value */}
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {/* &#8358; */}
                <span className="currency">
                  {user?.currency === "USD" ? "$" : "₦"}
                </span>
                {user?.currency === "USD"
                  ? (Math.round((Number(cargoInput.sumInsured) / user?.exchangeRate) * 10) / 10).toLocaleString()
                  : Number(cargoInput.sumInsured).toLocaleString()}
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Premium*/}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">Premium:</div>
            {/* total sum insured value */}
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {/* &#8358; */}
                <span className="currency">
                  {user?.currency === "USD" ? "$" : "₦"}
                </span>
                {user?.currency === "USD"
                  ? (Math.round((cargoInput.premium / user?.exchangeRate) * 10) / 10).toLocaleString()
                  : cargoInput.premium.toLocaleString()}
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Exchange rate */}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">
              Exchange rate:
            </div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {user?.exchangeRate ? "1" : cargoInput.exchangeRates}
              </div>

              <div className="edit-input-button-wrapper">
                <button
                  onClick={() => {
                    setPageContent("stage-one");
                    setCurrentActiveCargoIndex(index);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Invoice number*/}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">
              Invoice number:
            </div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {cargoInput.invoiceNumber}
              </div>

              <div className="edit-input-button-wrapper">
                <button
                  onClick={() => {
                    setCurrentActiveCargoIndex(index);
                    setPageContent("stage-one");
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Cargo description*/}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">
              Cargo description:
            </div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {cargoInput.cargoDescription}
              </div>

              <div className="edit-input-button-wrapper">
                <button
                  onClick={() => {
                    setCurrentActiveCargoIndex(index);
                    setPageContent("stage-one");
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Value of goods */}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">
              Value of goods:
            </div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {/* &#8358; */}
                <span className="currency">
                  {user?.currency === "USD" ? "$" : "₦"}
                </span>
                { user?.currency === "USD" ? 
                (Math.round( (
                  Number(cargoInput.valueOfGoods) *
                  Number(cargoInput.exchangeRates)
                  / user?.exchangeRate
                ) * 10)/10)
               ?.toLocaleString() :
                (
                  Number(cargoInput.valueOfGoods) *
                  Number(cargoInput.exchangeRates)
                )?.toLocaleString()}
              </div>

              <div className="edit-input-button-wrapper">
                <button
                  onClick={() => {
                    setCurrentActiveCargoIndex(index);
                    setPageContent("stage-one");
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          {/* Form Value Edit Box  || Port of loading */}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">
              Port of loading:
            </div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {cargoInput.loadingPort}
              </div>

              <div className="edit-input-button-wrapper">
                <button
                  onClick={() => {
                    setCurrentActiveCargoIndex(index);
                    setPageContent("stage-one");
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          {/* Form Value Edit Box  || Port of destination */}
          <div className="policy-form-value-edit-wrapper">
            <div className="policy-form-edit--title-wrapper">
              Port of destination:
            </div>
            <div className="policy-form-edit--value-wrapper">
              <div className="policy-form-edit--value">
                {cargoInput.destinationPort}
              </div>

              <div className="edit-input-button-wrapper">
                <button
                  onClick={() => {
                    setCurrentActiveCargoIndex(index);
                    setPageContent("stage-one");
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>

          {/* Form Value Edit Box  || Bank Name --- Muted on spreadsheet import (bulk upload) */}
          {!isUploadedFromSpreadsheet && cargoInput.bankName !== "0" && (
            <div className="policy-form-value-edit-wrapper">
              <div className="policy-form-edit--title-wrapper">Bank:</div>
              <div className="policy-form-edit--value-wrapper">
                <div className="policy-form-edit--value">
                  {
                    allBanks.filter(
                      (bank) => bank.key === cargoInput.bankName
                    )[0]?.value
                  }
                </div>

                <div className="edit-input-button-wrapper">
                  <button
                    onClick={() => {
                      setPageContent("stage-two-five");
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default CargoSummaryWrapper;
