import React, { MouseEvent, useEffect, useState } from "react";
import "./PolicyStageTwoFive.css";
import DropdownInput from "../../../../components/input-components/dropdown-input/DropdownInput";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import { allBanks } from "../../../../utils/allBanksinNigeria";
import {
  IAllCargosInputStates,
  IStageTwoPolicyFormStates,
} from "../../../../Types";

// Interfaces
interface IProps {
  allCargosInputStates: IAllCargosInputStates[];
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  currentActiveCargoIndex: number;
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: React.Dispatch<
    React.SetStateAction<{
      hasError: boolean;
      message: string;
    }>
  >;
}

function PolicyStageTwoFive({
  allCargosInputStates,
  setAllCargosInputStates,
  currentActiveCargoIndex,
  pageContent,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables

  // States
  const [currentCargoLcDetails, setCurrentCargoLcDetails] =
    useState<IStageTwoPolicyFormStates>({
      bankName: "",
    });

  //   Functions
  // handle Navigate To Page Three
  const handleNavigateToPageThree = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    const isFormEmpty = Object.keys(currentCargoLcDetails)?.some(
      (inputKeys) =>
        currentCargoLcDetails[
          inputKeys as keyof typeof currentCargoLcDetails
        ] === ""
    );
    if (!isFormEmpty) {
      // Update the active cargo with the filled bank name and credit number detail
      e.preventDefault();
      //
      const updatedCurrentCargo: IAllCargosInputStates = {
        ...allCargosInputStates[currentActiveCargoIndex],
        bankName: currentCargoLcDetails.bankName,
      };

      // Make a copy of all cargo input states
      const allCargoInputStatesDuplicate = [...allCargosInputStates];

      // Splice the updated cargo into the duplicate cargo array
      allCargoInputStatesDuplicate.splice(
        currentActiveCargoIndex,
        1,
        updatedCurrentCargo
      );

      // Update the all cargo state with the new updated cargo array
      setAllCargosInputStates(allCargoInputStatesDuplicate);
      // Navigate to page two
      setPageContent("stage-three");
    }
  };

  useEffect(() => {
    if (pageContent === "stage-two-five") {
      setCurrentCargoLcDetails((prevCurrentLcDetails) => {
        const updatedCargoLcDetails: IStageTwoPolicyFormStates = {
          bankName:
            allCargosInputStates[currentActiveCargoIndex].bankName === "0"
              ? ""
              : allCargosInputStates[currentActiveCargoIndex].bankName,
        };
        return updatedCargoLcDetails;
      });
    }
  }, [pageContent]);

  return (
    // Policy form 2.5, Bank and credit number for Lc and Forex
    <div className="policy-stage-container policy-stage-two-five-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          <button onClick={() => setPageContent("stage-two")}>
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">2.5/6</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        {/* Form Two Header Box Wrapper */}
        <div className="policy-form-two-header-box-wrapper">
          I am generating LC through?
        </div>

        <form autoComplete="off" className="policy-two-five-form-container">
          {/* Loading port form group */}
          <div className="form-group bank-name-form-group">
            <DropdownInput
              className={"bank-name-text-input"}
              placeholder={"Select bank"}
              value={currentCargoLcDetails.bankName}
              onChange={(e) => {
                setCurrentCargoLcDetails((prevStateInputs) => {
                  const updatedStateInputs: IStageTwoPolicyFormStates = {
                    ...prevStateInputs,
                    bankName: e.target.value,
                  };
                  return updatedStateInputs;
                });
              }}
              optionArray={allBanks}
              required={true}
            />
          </div>

          {/* Letter of credit number form group */}
          {/* <div className="form-group credit-number-form-group">
            <TextInput
              type={"number"}
              className={"credit-number-text-input"}
              placeholder={"Letter of credit number"}
              value={currentCargoLcDetails.creditNumber}
              onChange={(e) => {
                setCurrentCargoLcDetails((prevStateInputs) => {
                  const updatedStateInputs: IStageTwoPolicyFormStates = {
                    ...prevStateInputs,
                    creditNumber: e.target.value,
                  };
                  return updatedStateInputs;
                });
              }}
              required={true}
            />
          </div> */}

          <div className="policy-form-page-bottom-wrapper">
            {/* Fields mandatory wrapper */}
            <div className="field-are-mandatory-wrapper">
              All fields are mandatory*
            </div>

            {/* Submit Policy Form Button Wrapper */}
            <div className="submit-onboarding-form-button-wrapper">
              <button onClick={(e) => handleNavigateToPageThree(e)}>
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PolicyStageTwoFive;
