import React, { useContext, useEffect, useState } from "react";
import "./Top.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import companyLogo from "../../images/heirs-insurance-logo.png";
import notifIcon from "../../images/notification-icon.svg";
import powerIcon from "../../images/power-icon.svg";
import { UserContext } from "../contexts/UserContext";
import jwtDecode from "jwt-decode";
import { useAppDispatch } from "../../hooks";
import { logoutUser } from "../../redux/actions/authActions";

// Interfcaes
interface IProps {
  pageSummaryMessage: string;
  hasOnboardingButton?: boolean;
  isOnChangePasswordPage?: boolean;
}
function Top({
  pageSummaryMessage,
  hasOnboardingButton,
  isOnChangePasswordPage,
}: IProps) {
  // Functions, States and Variables
  const { user, setUser }: any = useContext(UserContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // States
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleLogoutUser = function () {
    dispatch(logoutUser(navigate, setUser));
  };

  // UseEffects
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Check if User exists || is logged in
    if (token) {
      // console.log("user coming from top test", user);
      // Get Current User data
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="app-top-container">
      <div className="app-top-container--inner">
        {/* Hello message / Left Section */}
        <div className="app-top--left-wrapper">
          <div className="top-hello-message-wrapper">
            {pageSummaryMessage !== ""
              ? `Hello ${user?.companyName || ""}`
              : ""}
          </div>
          <div className="top-page-summary-message">{pageSummaryMessage}</div>
        </div>

        {/* Action Buttons/ Right Section */}
        <div className="app-top--right-wrapper">
          {/* Onboard button */}
          {/* {hasOnboardingButton && (
            <div className="onboard-button-wrapper">
              <button onClick={() => navigate("/onboarding-status")}>
                Onboard
              </button>
            </div>
          )} */}

          <div className="user-guide-button-wrapper">
            <Link
              to={process.env.PUBLIC_URL + "/doc/Marine_Portal_User_Manual.pdf"}
              target="_blank"
            >
              Download manual guide
            </Link>
          </div>
          {/* Change Password Link */}
          <div className="change-password-button-wrapper">
            {!isOnChangePasswordPage && (
              <button onClick={() => navigate("/change-password")}>
                Change Password
              </button>
            )}
          </div>

          {/* Notification Button */}
          <div className="notification-button-wrapper">
            {/* <button>
              <img src={notifIcon} alt="" />
            </button> */}
          </div>
          {/* Logout Button */}
          <div className="power-button-wrapper">
            <button title="Log Out" onClick={() => handleLogoutUser()}>
              <img src={powerIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top;
