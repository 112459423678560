import axios from "axios";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import { GET_SAIL_LOCATION } from "../Constants";

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;

// Get All Sail Locations
export const getSailLocations =
  (setErrorHandlerObj: SetErrorHandlerType, setIsLoading: SetIsLoadingType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/get-sail-location`)
      .then((res) => {
        setIsLoading(false);
        // Dispatch the data
        dispatch({
          type: GET_SAIL_LOCATION,
          payload: res.data,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
