import React, { useEffect, useState } from "react";
import "./AddNewSubsidiary.css";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import Top from "../../../components/app-top/Top";
import ErrorHandler from "../../../components/error/ErrorHandler";
import SuccessHandler from "../../../components/success/SuccessHandler";
import AddNewSubsidiaryForm from "./form/AddNewSubsidiaryForm";

// Interfaces
export interface ISubsidiaryFormInputStates {
  subsidiaryName: string;
  subsidiaryEmail: string;
  subsidiaryPhone: string;
  tin: string;
  rcNumber: string;
  nameOfDirectors: string[];
  city: string;
  state: string;
  address: string;
}

function AddNewSubsidiary() {
  // Functions, States and Variables

  //   States
  const [subsidiaryFormInputStates, setSubsidiaryFormInputStates] =
    useState<ISubsidiaryFormInputStates>({
      subsidiaryName: "",
      subsidiaryEmail: "",
      subsidiaryPhone: "",
      tin: "",
      rcNumber: "",
      nameOfDirectors: [""],
      city: "",
      state: "",
      address: "",
    });

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);

  return (
    <div className="onboarding-container">
      <Top pageSummaryMessage={"Onboard a subsidiary here"} />
      <div className="onboarding-container--inner">
        <Sidebar activeName={"Subsidiaries"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        <section className="onboarding-container-main-section">
          <AddNewSubsidiaryForm
            subsidiaryFormInputStates={subsidiaryFormInputStates}
            setSubsidiaryFormInputStates={setSubsidiaryFormInputStates}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        </section>
      </div>
    </div>
  );
}

export default AddNewSubsidiary;
