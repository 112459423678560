import React, { MouseEvent, useContext, useEffect, useState } from "react";
import "./Reports.css";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import useDisplayMessage from "../../utils/hooks/useDisplayMessage";
import TextInput from "../../components/input-components/text-input/TextInput";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import {
  ISingleUser,
  UserContext,
} from "../../components/contexts/UserContext";
import { IDropdownOption } from "../../Types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCompaniesForBroker } from "../../redux/actions/companyActions";

const policyTypeOptions = [
  { key: "open-cover", value: "Open Cover" },
  { key: "single-transit", value: "Single Transit" },
];
const reportTypeOptions = [
  { key: "policy", value: "Policy" },
  { key: "transaction_history", value: "Transaction History" },
  { key: "unit_history", value: "Unit History" },
];

function Reports() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const { user }: any = useContext(UserContext);
  const companies: ISingleUser[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  const { errorHandlerObj, successHandlerObj, setErrorHandlerObj } =
    useDisplayMessage();
  //   States
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState("policy"); // policy, transaction_history, unit_history
  // Form Input States
  const [formInputStates, setFormInputStates] = useState({
    startDate: "",
    endDate: "",
    policyType: "open-cover",
    customerId: "all",
  });
  // Subsidiary and Companies for brokers options array
  const [allCompaniesNames, setAllCompaniesNames] = useState<
    IDropdownOption[] | null
  >(null);

  //   Functions
  const handleUpdateFormInputState = function (
    fieldName: string,
    fieldValue: string
  ) {
    const updatedFormObject = {
      ...formInputStates,
      [fieldName]: fieldValue,
    };
    setFormInputStates(updatedFormObject);
  };

  //   Handle Generate report
  const handleGenerateReport = function (e: MouseEvent<HTMLButtonElement>) {
    const isFormEmpty = Object.keys(formInputStates).some(
      (inputKey) =>
        formInputStates[inputKey as keyof typeof formInputStates] === ""
    );
    if (!isFormEmpty) {
      e.preventDefault();

      if (reportType === "policy") {
        // FOR POLICY REPORTS
        const customerIdParam =
          user?.customerType === "Customer"
            ? `customerId=${user?.id}`
            : formInputStates.customerId !== "all"
            ? `customerId=${formInputStates.customerId}`
            : "";

        const brokerIdParam =
          user?.customerType === "Broker" &&
          formInputStates.customerId === "all"
            ? `brokerId=${user?.id}`
            : "";
        //   console.log(
        //     `${process.env.REACT_APP_REPORT_URL}/api/v1/general/marineportal/${formInputStates.policyType}/report?startDate=${formInputStates.startDate}&endDate=${formInputStates.endDate}&${customerIdParam}&${brokerIdParam}`
        //   );
        window.open(
          `${process.env.REACT_APP_REPORT_URL}/api/v1/general/marineportal/${formInputStates.policyType}/report?startDate=${formInputStates.startDate}&endDate=${formInputStates.endDate}&${customerIdParam}&${brokerIdParam}`,
          "_blank"
        );
      } else if (reportType === "transaction_history") {
        // FOR TRANSACTION REPORTS
        window.open(
          `${process.env.REACT_APP_REPORT_URL}/api/v1/general/marineportal/wallet-history/report?startDate=${formInputStates.startDate}&endDate=${formInputStates.endDate}&customerId=${user?.id}`,
          "_blank"
        );
      } else if (reportType === "unit_history") {
        // FOR UNIT HISTORY REPORTS
        window.open(
          `${process.env.REACT_APP_REPORT_URL}/api/v1/general/marineportal/unit-history/report?startDate=${formInputStates.startDate}&endDate=${formInputStates.endDate}&customerId=${user?.id}`,
          "_blank"
        );
      }
    }
  };

  //   UseEffects
  useEffect(() => {
    // Display only approved companies in dropdown
    const companiesArr: IDropdownOption[] = companies
      ?.filter((company: ISingleUser) => company.isApproved === true)
      ?.map((company: ISingleUser) => {
        return {
          key: company.id,
          value: company.companyName,
        };
      });
    companiesArr.unshift({
      key: "all",
      value: "All",
      default: "default",
    });
    setAllCompaniesNames(companiesArr);
  }, [companies]);

  useEffect(() => {
    user?.customerType === "Broker" &&
      dispatch(getAllCompaniesForBroker(setErrorHandlerObj, setIsLoading));
  }, [user]);

  return (
    <div className="reports-container">
      <Top pageSummaryMessage="Generate your reports" />
      <div className="reports-container--inner">
        <Sidebar activeName={"Reports"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        <section className="reports-container-main-section">
          <div className="onboarding-form-title-wrapper">
            Select the date range below
          </div>

          {/* Form Wrapper */}
          <div className="reports-form-wrapper">
            <form>
              <div className="reports-form-group-wrapper">
                {/* Report Type */}
                <div className="form-group report-type-form-group">
                  <DropdownInput
                    className={"report-type-text-input"}
                    placeholder={"Report Type"}
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    optionArray={reportTypeOptions}
                    required={true}
                  />
                </div>

                {/* Policy Type form groups */}
                {reportType === "policy" && (
                  <>
                    <div className="form-group">
                      <DropdownInput
                        className={"policy-type-text-input"}
                        placeholder={"Policy Type"}
                        value={formInputStates.policyType}
                        onChange={(e) =>
                          handleUpdateFormInputState(
                            "policyType",
                            e.target.value
                          )
                        }
                        optionArray={policyTypeOptions}
                        required={true}
                      />
                    </div>

                    {/* Company Name form group */}
                    {user?.customerType === "Broker" && (
                      <div className="form-group company-name-form-group">
                        <DropdownInput
                          className={"company-name-text-input"}
                          placeholder={"Company name"}
                          value={formInputStates.customerId}
                          onChange={(e) => {
                            handleUpdateFormInputState(
                              "customerId",
                              e.target.value
                            );
                          }}
                          optionArray={
                            allCompaniesNames ? allCompaniesNames : []
                          }
                        />
                      </div>
                    )}
                  </>
                )}

                {/* State Date form group */}
                <div className="form-group">
                  <TextInput
                    type="date"
                    className={"start-date-form-group"}
                    value={formInputStates.startDate}
                    onChange={(e) =>
                      handleUpdateFormInputState("startDate", e.target.value)
                    }
                    required
                    placeholder="Start Date"
                  />
                </div>

                {/* End Date form group */}
                <div className="form-group">
                  <TextInput
                    type="date"
                    className={"end-date-form-group"}
                    value={formInputStates.endDate}
                    onChange={(e) =>
                      handleUpdateFormInputState("endDate", e.target.value)
                    }
                    required
                    placeholder="End Date"
                  />
                </div>
              </div>

              {/* Submit Auth Form Button Wrapper */}
              <div className="submit-auth-form-button-wrapper generate-report-form-button-wrapper">
                <button onClick={(e) => handleGenerateReport(e)}>
                  Generate Report{" "}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Reports;
