import React, { MouseEvent, useContext, useEffect, useState } from "react";
import "./Signin.css";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import hexalSpiralImg from "../../images/hexal-spiral.svg";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../../components/input-components/text-input/TextInput";
import eyeOpenIcon from "../../images/eye-open-icon.svg";
import eyeCloseIcon from "../../images/eye-close-icon.svg";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../hooks";
import { UserContext } from "../../components/contexts/UserContext";
import { loginUser } from "../../redux/actions/authActions";
import ErrorHandler from "../../components/error/ErrorHandler";

function Signin() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setUser }: any = useContext(UserContext);
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // Funtions
  const handleSigninUser = function (e: MouseEvent<HTMLButtonElement>) {
    // Set error message to default false state
    setErrorHandlerObj({ hasError: false, message: "" });
    if (email !== "" && password !== "") {
      e.preventDefault();
      const data = {
        email,
        password,
      };
      // Call the API to login user / customer / broker
      dispatch(
        loginUser(data, navigate, setErrorHandlerObj, setIsLoading, setUser)
      );
    }
  };

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);

  return (
    <div className="signin-container">
      <AuthNavbar page="signin" />

      <div className="signin-container--inner">
        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="auth-error-success-message-wrapper"
        />
        {/* Hexal Spiral Wrapper */}
        <div className="hexal-spiral-wrapper">
          <img src={hexalSpiralImg} alt="" />
        </div>
        {/* Auth Left Section */}
        <section className="auth-left-section">
          {/* Primary Text Line */}
          <div className="auth-left--primary-text-line">
            You can’t predict the journey
          </div>

          {/* Secondary Text Line */}
          <div className="auth-left--secondary-text-line">
            But we can help you <span>protect it</span>
          </div>
        </section>
        {/* Auth Right Section */}
        <section className="auth-right-section">
          {/* Auth Form Box Container */}
          <div className="auth-form-box-container">
            {/* Welcome Wrapper */}
            <div className="auth-welcome-wrapper">Welcome🖐</div>

            {/* Auth Title Wrapper */}
            <div className="auth-title-wrapper">Sign In</div>

            {/* Form Progress Line */}
            <div className="form-progress-line-wrapper"></div>
            {/* Auth Form Wrapper */}
            <div className="signin-form-wrapper">
              <form>
                {/* Email form group */}
                <div className="form-group email-form-group">
                  <TextInput
                    type={"text"}
                    className={"email-text-input"}
                    placeholder={"Email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                  />
                </div>

                {/* Password form group */}
                <div className="form-group password-form-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className={"password-form-group"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    // autoComplete="new-password"
                  />
                  <span className="placeholder">Password</span>

                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                </div>
                <div className="forgot-password-link-wrapper">
                  <Link to={"/forgot-password"}>Forgot Password</Link>
                </div>

                {/* Submit Auth Form Button Wrapper */}
                <div className="submit-auth-form-button-wrapper">
                  <button onClick={(e) => handleSigninUser(e)}>Sign In</button>
                  <div className="auth-loading-spinner-wrapper">
                    {isLoading && <LoadingSpinner />}
                  </div>
                </div>
              </form>

              <div className="havent-signup-yet-wrapper">
                Haven’t signed up your company yet?
                <Link to={"/signup"}> Sign Up</Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Signin;
