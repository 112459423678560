import React, { useContext, useState } from "react";
import "./OnboardingStageThree.css";
import {
  IStageOneOnboardingStates,
  IStageTwoOnboardingStates,
} from "../../OnboardingStatus";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import { FilePdfFilled } from "@ant-design/icons";
import { useAppDispatch } from "../../../../hooks";
import { concludeOnboarding } from "../../../../redux/actions/userActions";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { UserContext } from "../../../../components/contexts/UserContext";
import { SetErrorHandlerType } from "../../../../Types";
import { useNavigate } from "react-router-dom";
import { createCompanyForBrokers } from "../../../../redux/actions/companyActions";
// Interfaces
interface IProps {
  stageTwoInputStates: IStageTwoOnboardingStates;
  stageOneInputStates: IStageOneOnboardingStates;
  setStageOneInputStates: React.Dispatch<
    React.SetStateAction<IStageOneOnboardingStates>
  >;
  isAddNewCompanyToBrokers: boolean;
  isAddNewSubsidiaryToCustomers: boolean;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}
function OnboardingStageThree({
  stageTwoInputStates,
  stageOneInputStates,
  setStageOneInputStates,
  isAddNewCompanyToBrokers,
  isAddNewSubsidiaryToCustomers,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // console.log('kkk',user)
  //   States
  const [isLoading, setIsLoading] = useState(false);
  //   Functions
  const handleRemoveDirectorsName = function (index: number) {
    const directorsNameArr = [...stageOneInputStates.nameOfDirectors];
    directorsNameArr.splice(index, 1);
    const updatedStageOneInputStates: IStageOneOnboardingStates = {
      ...stageOneInputStates,
      nameOfDirectors: directorsNameArr,
    };
    setStageOneInputStates(updatedStageOneInputStates);
  };

  const handleSubmitOnboardingForm = function () {
    // If Data Is Current User's Onboarding Data
    if (!isAddNewCompanyToBrokers) {
      // TODO Call the submit onboarding data API
      const data = {
        tin: stageOneInputStates.tinNumber,
        naicomLicence:
          user?.customerType === "Broker" &&
          stageOneInputStates?.naicomLicenseNumber
            ? stageOneInputStates.naicomLicenseNumber
            : "",
        directors: stageOneInputStates.nameOfDirectors,
        cacImageUrl: stageTwoInputStates.cacDocument,
        utilityImageUrl: stageTwoInputStates?.utilityBill || "",
        directorsIDCardImageUrl: stageTwoInputStates.directorsIdCard,
        naicomLicenseImageUrl:
          user?.customerType === "Broker" && stageTwoInputStates?.naicomLicense
            ? stageTwoInputStates.naicomLicense
            : "",
        estimatedAnnualSuminsured: Number(
          stageOneInputStates.estimatedAnnualSuminsured
        ),
        limitPerCarriage: Number(stageOneInputStates.limitPerCarriage),
        address: stageOneInputStates.address,
        city: stageOneInputStates.city,
        state: stageOneInputStates.state,
        streetNumber: "",
        street: "",
        country: "",
        postCode: "",
      };
      // console.log("first data", data);
      // Call the API to conclude onboarding
      dispatch(
        concludeOnboarding(
          data,
          setErrorHandlerObj,
          setIsLoading,
          setPageContent
        )
      );
    } else {
      // If Data Is New Company For Broker
      const data = {
        customerType: "Customer",
        ref_id: user?.id || "",
        email: stageOneInputStates?.companyEmail || "",
        companyName: stageOneInputStates?.companyName || "",
        phone: stageOneInputStates?.companyPhoneNumber || "",
        tin: stageOneInputStates.tinNumber,
        rcNumber: stageOneInputStates?.rcNumber || "",
        // Niacom license is empty because company is defaultly a customer
        naicomLicence: "",
        directors: stageOneInputStates.nameOfDirectors,
        cacImageUrl: stageTwoInputStates.cacDocument,
        utilityImageUrl: stageTwoInputStates?.utilityBill || "",
        directorsIDCardImageUrl: stageTwoInputStates.directorsIdCard,
        naicomLicenseImageUrl: "",
        estimatedAnnualSuminsured: Number(
          stageOneInputStates.estimatedAnnualSuminsured
        ),
        limitPerCarriage: Number(stageOneInputStates.limitPerCarriage),
        address: stageOneInputStates.address,
        city: stageOneInputStates.city,
        state: stageOneInputStates.state,
        streetNumber: "",
        street: "",
        country: "",
        postCode: "",
      };

      // console.log(data);

      // TODO Call the API to add a new company for broker
      dispatch(
        createCompanyForBrokers(
          data,
          setErrorHandlerObj,
          setIsLoading,
          navigate
        )
      );
    }
  };

  return (
    <div className="onboarding-form-page-container onboarding-form-page-two-container">
      {/* Onboardng form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          <button onClick={() => setPageContent("stage-two")}>
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">3/3</div>
      </div>

      {/* Onboarding Form Wrapper */}
      <div className="onboarding-form-wrapper">
        <div className="onboarding-form-title-wrapper">Details Summary</div>

        {/* Details Summary Wrapper */}
        <div className="onboarding-form-details-summary-container">
          {/* Form details summary row */}

          {/* Company Name summary row */}
          {isAddNewCompanyToBrokers && (
            <div className="form-details-summary-row">
              {/* Form Detail Title */}
              <div className="form-detail-title">Company's name</div>

              <div className="form-detail-edit-wrapper">
                <div className="form-detail-value-wrapper">
                  {stageOneInputStates?.companyName || ""}
                </div>

                <div className="edit-input-button-wrapper">
                  <button onClick={() => setPageContent("stage-one")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Company CAC No summary row */}
          {isAddNewCompanyToBrokers && (
            <div className="form-details-summary-row">
              {/* Form Detail Title */}
              <div className="form-detail-title">RC number</div>

              <div className="form-detail-edit-wrapper">
                <div className="form-detail-value-wrapper">
                  {stageOneInputStates?.rcNumber || ""}
                </div>

                <div className="edit-input-button-wrapper">
                  <button onClick={() => setPageContent("stage-one")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* TIN NUmber */}
          <div className="form-details-summary-row">
            {/* Form Detail Title */}
            <div className="form-detail-title">TIN number</div>

            <div className="form-detail-edit-wrapper">
              <div className="form-detail-value-wrapper">
                {stageOneInputStates.tinNumber}
              </div>

              <div className="edit-input-button-wrapper">
                <button onClick={() => setPageContent("stage-one")}>
                  Edit
                </button>
              </div>
            </div>
          </div>

          {/* Company Email summary row */}
          {isAddNewCompanyToBrokers && (
            <div className="form-details-summary-row">
              {/* Form Detail Title */}
              <div className="form-detail-title">Company's email address</div>

              <div className="form-detail-edit-wrapper">
                <div className="form-detail-value-wrapper">
                  {stageOneInputStates?.companyEmail || ""}
                </div>

                <div className="edit-input-button-wrapper">
                  <button onClick={() => setPageContent("stage-one")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Company Phone summary row */}
          {isAddNewCompanyToBrokers && (
            <div className="form-details-summary-row">
              {/* Form Detail Title */}
              <div className="form-detail-title">Comapny’s phone number</div>

              <div className="form-detail-edit-wrapper">
                <div className="form-detail-value-wrapper">
                  {stageOneInputStates?.companyPhoneNumber || ""}
                </div>

                <div className="edit-input-button-wrapper">
                  <button onClick={() => setPageContent("stage-one")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Form details summary row */}
          <div className="form-details-summary-row">
            {/* Form Detail Title */}
            <div className="form-detail-title">Name of directors</div>

            <div className="form-detail-edit-wrapper directors-name-edit-wrapper">
              {/* Directors Name Detail Row */}
              {stageOneInputStates.nameOfDirectors?.map(
                (directorName, index) => (
                  <div key={index + 1} className="directors-name-detail-row">
                    <div className="form-detail-value-wrapper">
                      {directorName}
                    </div>

                    {stageOneInputStates.nameOfDirectors.length > 1 && (
                      <div className="edit-input-button-wrapper">
                        <button
                          onClick={() => handleRemoveDirectorsName(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          {/* Form details summary row */}
          <div className="form-details-summary-row">
            {/* Form Detail Title */}
            <div className="form-detail-title">CAC Document</div>

            <div className="form-detail-edit-wrapper file-form-detail-edit-wrapper">
              <div className="form-detail-value-wrapper">
                <div className="file-img-wrapper">
                  <FilePdfFilled />
                </div>

                <a
                  href={stageTwoInputStates.cacDocument}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  CAC Document.png
                </a>
              </div>

              <div className="edit-input-button-wrapper">
                <button onClick={() => setPageContent("stage-two")}>
                  Change
                </button>
              </div>
            </div>
          </div>

          {/* Form details summary row */}
          {user?.customerType === "Customer" && (
            <div className="form-details-summary-row">
              {/* Form Detail Title */}
              <div className="form-detail-title">Utility Document</div>

              <div className="form-detail-edit-wrapper file-form-detail-edit-wrapper">
                <div className="form-detail-value-wrapper">
                  <div className="file-img-wrapper">
                    <FilePdfFilled />
                  </div>

                  <a
                    href={stageTwoInputStates.utilityBill}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Utility Document.png
                  </a>
                </div>

                <div className="edit-input-button-wrapper">
                  <button onClick={() => setPageContent("stage-two")}>
                    Change
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="form-details-summary-row">
            {/* Form Detail Title */}
            <div className="form-detail-title">Directors ID Card</div>

            <div className="form-detail-edit-wrapper file-form-detail-edit-wrapper id-card--file-form-detail-edit-wrapper">
              {stageTwoInputStates.directorsIdCard?.map((idCard, index) => (
                <div
                  key={index + 1}
                  className="id-card--form-detail-edit-wrapper"
                >
                  <div className="form-detail-value-wrapper">
                    <div className="file-img-wrapper">
                      <FilePdfFilled />
                    </div>
                    <div key={index + 1} className="directors-name-detail-row">
                      <a href={idCard} target={"_blank"} rel="noreferrer">
                        Director {index + 1}'s ID Card.png
                      </a>
                    </div>
                  </div>

                  <div className="edit-input-button-wrapper">
                    <button onClick={() => setPageContent("stage-two")}>
                      Change
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {user?.customerType === "Broker" && (
            <div className="form-details-summary-row">
              {/* Form Detail Title */}
              <div className="form-detail-title">NAICOM License</div>

              <div className="form-detail-edit-wrapper file-form-detail-edit-wrapper">
                <div className="form-detail-value-wrapper">
                  <div className="file-img-wrapper">
                    <FilePdfFilled />
                  </div>
                  <a
                    href={stageTwoInputStates.naicomLicense}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    NAICOM License.png
                  </a>
                </div>

                <div className="edit-input-button-wrapper">
                  <button onClick={() => setPageContent("stage-two")}>
                    Change
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="onboarding-form-page-bottom-wrapper">
          {/* Fields mandatory wrapper */}
          <div className="field-are-mandatory-wrapper"></div>

          {/* Submit Onboarding Form Button Wrapper */}
          <div className="submit-onboarding-form-button-wrapper">
            <button onClick={(e) => handleSubmitOnboardingForm()}>
              Submit
            </button>
            {isLoading && <LoadingSpinner />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingStageThree;
