export const compulsoryExcelAttributes = [
  "Marine Clause",
  // "bankName",
  "cargoDescription",
  "clause",
  "conveyance",
  "conveyanceID",
  "currency",
  "destinationPort",
  "destinationPortID",
  "exchangeRate",
  "invoiceNumber",
  "lcNumber",
  "loadingPort",
  "loadingPortID",
  "valueOfGoods",
];
