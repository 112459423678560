import React, { useState } from "react";
import "./Modals.css";
import cancelIcon from "../../../../../images/cancel-icon.svg";
import { getSumInsured } from "../../../../../utils/getSumInsured";
import { IAllCargosInputStates } from "../../../../../Types";

interface IProps {
  isSumInsuredBreakdownModalOpened: boolean;
  setIsSumInsuredBreakdownModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  allCargosInputStates: IAllCargosInputStates[];
  currentActiveCargoIndex: number;
}
const SumInsuredBreakdownModal: React.FC<IProps> = function ({
  isSumInsuredBreakdownModalOpened,
  setIsSumInsuredBreakdownModalOpened,
  allCargosInputStates,
  currentActiveCargoIndex,
}) {
  // Functions, States, Variables
  // /States
  const [categoryName, setCategoryName] = useState("");

  return (
    <div
      className={`sum-insured-breakdown-modal-container  ${
        isSumInsuredBreakdownModalOpened ? "" : "none"
      }`}
    >
      {/* Container Inner */}
      <div className="sum-insured-breakdown-modal-container--inner">
        <div className="cancel-modal-button-wrapper">
          <button onClick={() => setIsSumInsuredBreakdownModalOpened(false)}>
            <img src={cancelIcon} alt="" />
          </button>
        </div>

        {/* Modal Title Wrapper */}
        <div className="modal-title-wrapper">Sum Insured Breakdown</div>

        <div className="breakdown-container">
          {/* Value of Goods */}
          <div className="breakdown-wrapper">
            <div className="breakdown-title-wrapper">Value of goods:</div>
            <div className="breakdown-value-wrapper">
              {allCargosInputStates[currentActiveCargoIndex].currency}
              {Number(allCargosInputStates[currentActiveCargoIndex].valueOfGoods).toLocaleString()}
            </div>
          </div>
          {/* Exchange Rate */}
          <div className="breakdown-wrapper">
            <div className="breakdown-title-wrapper">
              Exchange Rate to &#8358;:
            </div>
            <div className="breakdown-value-wrapper">
              {allCargosInputStates[currentActiveCargoIndex].exchangeRates}
            </div>
          </div>
          {/* Total in Naira */}
          <div className="breakdown-wrapper">
            <div className="breakdown-title-wrapper">Total in Naira:</div>
            <div className="breakdown-value-wrapper">
              &#8358;
              {(
                Number(
                  allCargosInputStates[currentActiveCargoIndex].valueOfGoods
                ) *
                Number(
                  allCargosInputStates[currentActiveCargoIndex].exchangeRates
                )
              ).toLocaleString()}
            </div>
          </div>
          {/* Inflation rate of */}
          <div className="breakdown-wrapper">
            <div className="breakdown-title-wrapper">Inflation rate of:</div>
            <div className="breakdown-value-wrapper">10%</div>
          </div>
          {/* Sum Insured */}
          <div className="breakdown-wrapper">
            <div className="breakdown-title-wrapper">Sum Insured:</div>
            <div className="breakdown-value-wrapper">
              &#8358;
              {getSumInsured(
                Number(
                  allCargosInputStates[currentActiveCargoIndex].valueOfGoods
                ),
                Number(
                  allCargosInputStates[currentActiveCargoIndex].exchangeRates
                ),
                allCargosInputStates[currentActiveCargoIndex].currency === "NGN"
              ).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SumInsuredBreakdownModal;
