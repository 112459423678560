/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ALL_COMPANIES_FOR_BROKER,
  GET_A_COMPANY_CERTIFICATES_FOR_BROKER,
  GET_BROKER_CERTIFICATES,
  GET_CUSTOMER_CERTIFICATES,
} from "../Constants";

const initialState = {
  certificates: [],
};

export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_CUSTOMER_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };
    case GET_BROKER_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };
    case GET_A_COMPANY_CERTIFICATES_FOR_BROKER:
      return {
        ...state,
        certificates: action.payload,
      };
    default:
      return state;
  }
}
