import React, { useContext } from "react";
import "./PolicyProcessSuccessful.css";
import policySubmittedIcon from "../../../../images/policy-submitted-icon.svg";
import { policyDownloadTemplate } from "../../../../utils/policyDownloadTemplate";
import { UserContext } from "../../../../components/contexts/UserContext";
import {
  ISingleSubsidiary,
  ISubmittedCertificateData,
} from "../../../../Types";
import { printHTMLString } from "../../../../utils/handleFileDownloadsAsPdf";

interface IProps {
  submittedCertificatesData: ISubmittedCertificateData[] | null;
}
function PolicyProcessSuccessful({ submittedCertificatesData }: IProps) {
  // Functions, states and variables
  const { user }: any = useContext(UserContext);

  // States

  // Functions
  // Handle Save HTML
  const handleSaveHTML = function (fileName: string, html: string) {
    // const blob = new Blob([html], { type: "text/html" });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.download = fileName;
    // link.href = url;
    // link.click();
    printHTMLString(html);
  };

  // Handle Download Certificate for Cargo
  const handleDownloadCertificateForCargo = function (index: number) {
    if (submittedCertificatesData && user) {
      // Get the subsidiary owning the certificate if the subsididary exists and pass it to the certificate data to get subsidiary info accordingly
      // const subsidiaryOwningCertificate = user?.subCompanies?.find(
      //   (subsidiary: ISingleSubsidiary) =>
      //     subsidiary.id ===
      //     submittedCertificatesData[index]?.policy?.subsidiaryId
      // );
      // handleSaveHTML(
      //   "Policy_Certificate",
      //   policyDownloadTemplate(
      //     submittedCertificatesData[index],
      //     user,
      //     subsidiaryOwningCertificate
      //   )
      // );
      window.open(
        `${process.env.REACT_APP_CERTIFICATE_URL}/api/v1/general/download/marineportal/${submittedCertificatesData[index].certificate.id}`
      );
    }
  };

  return (
    <div className="policy-process-successful-container">
      <div className="congratulations-img-wrapper">
        <img src={policySubmittedIcon} alt="" />
      </div>

      <div className="policy-submission-success-main-text-wrapper">
        Policy Submitted🎉
      </div>

      {/* Download Certificates button wrapper */}
      <div className="download-certificates-button-container">
        {submittedCertificatesData?.map((cargos: any, index) => (
          <div key={index + 1} className="download-certificates-button-wrapper">
            <button onClick={() => handleDownloadCertificateForCargo(index)}>
              Download certificate {index + 1}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PolicyProcessSuccessful;
