import React from "react";
import "./OnboardingSuccessful.css";
import congratulationsIcon from "../../../images/congratulations-icon.svg";

function OnboardingSuccessful() {
  return (
    <div className="onboarding-successful-container">
      <div className="congratulations-img-wrapper">
        <img src={congratulationsIcon} alt="" />
      </div>

      <div className="signup-success-main-text-wrapper">Completed</div>

      <div className="signup-success-sub-text-wrapper">
        Your details have been sent and awaiting approval. Approval usually
        takes 1 working day. Check back in this section to see onboarding status
      </div>
    </div>
  );
}

export default OnboardingSuccessful;
