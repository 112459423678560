/* eslint-disable import/no-anonymous-default-export */
import isEmpty from "../../validation/isEmpty";
import { GET_CURRENT_USER, SET_CURRENT_USER } from "../Constants";

const initialState = {
  user: {},
};

export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
