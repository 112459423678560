export const getSumInsured = function (
  valueOfGoods: number,
  exchangeRate: number,
  isCurrencyNGN: boolean
) {
  //   Sum Insured = value of goods * exchange rate * inflation rate
  const valueTimeExchangeRate = valueOfGoods * exchangeRate;
  const inflationRate = 0.1 * valueTimeExchangeRate;
  // IF Currency is Naira(NGN), No Inflation rate should be added
  const sumInsured = isCurrencyNGN
    ? valueTimeExchangeRate
    : valueTimeExchangeRate + inflationRate;
  return sumInsured;
};
