import { useEffect, useState } from "react";

export const PolicyDownloadTemplateForTablePreview = function () {
  // Functions, states and variables
  // States
  const [currentHTMLString, setCurrentHTMLString] = useState("");
  // UseEffects
  useEffect(() => {
    const htmlString = localStorage.getItem("htmlPreviewString");
    if (!htmlString) {
      // Close Window if there is no data in the storage
      window.close();
    } else {
      setCurrentHTMLString(htmlString);
    }
  }, []);
  return <div dangerouslySetInnerHTML={{ __html: currentHTMLString }}></div>;
};
