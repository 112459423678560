import React, { MouseEvent, useContext, useEffect, useState } from "react";
import "./OnboardingStageOne.css";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import {
  IStageOneOnboardingStates,
  IStageTwoOnboardingStates,
} from "../../OnboardingStatus";
import plusIcon from "../../../../images/plus-icon.svg";
import minusIcon from "../../../../images/minus-icon.svg";
import { UserContext } from "../../../../components/contexts/UserContext";
import { SetErrorHandlerType } from "../../../../Types";
import { handleFormatNumberInputs } from "../../../../utils/handleFormatNumberInputs";
// Interfaces
interface IProps {
  isToEditExistingData: boolean;
  stageOneInputStates: IStageOneOnboardingStates;
  stageTwoInputStates: IStageTwoOnboardingStates;
  setStageOneInputStates: React.Dispatch<
    React.SetStateAction<IStageOneOnboardingStates>
  >;
  setStageTwoInputStates: React.Dispatch<
    React.SetStateAction<IStageTwoOnboardingStates>
  >;
  isAddNewCompanyToBrokers: boolean;
  isAddNewSubsidiaryToCustomers: boolean;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}
function OnboardingStageOne({
  isToEditExistingData,
  stageOneInputStates,
  stageTwoInputStates,
  setStageOneInputStates,
  setStageTwoInputStates,
  isAddNewCompanyToBrokers,
  isAddNewSubsidiaryToCustomers,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  //   States
  const [currentDirectorsIdCardArr, setCurrentDirectorsIdCardArr] = useState([
    "",
  ]);

  // Functions
  // handle Navigate To Page Two
  const handleNavigateToPageTwo = function (e: MouseEvent<HTMLButtonElement>) {
    const isFormEmpty = Object.keys(stageOneInputStates)?.some(
      (inputKeys) =>
        stageOneInputStates[inputKeys as keyof typeof stageOneInputStates] ===
        ""
    );

    const isDirectorsFieldEmpty = stageOneInputStates.nameOfDirectors.some(
      (directorName) => directorName === ""
    );

    if (!isFormEmpty && !isDirectorsFieldEmpty) {
      e.preventDefault();
      // Navigate to page two
      setPageContent("stage-two");

      // Create empty strings in the dirsctors id card array based on the number directors name
      const directorsIdCardArr = stageOneInputStates.nameOfDirectors?.map(
        (directorsName) => {
          return "";
        }
      );
      setStageTwoInputStates((prevStageTwoInputStates) => {
        let updatedStageTwoInputStates: IStageTwoOnboardingStates = {
          ...prevStageTwoInputStates,
          // ...(!isToEditExistingData && { directorsIdCard: directorsIdCardArr }),
          directorsIdCard: currentDirectorsIdCardArr,
        };
        return updatedStageTwoInputStates;
      });
    }
  };

  // handle Add New Director Field
  const handleAddNewDirectorField = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    setStageOneInputStates((prevStateInputs) => {
      //
      const directorsNameArr = [...prevStateInputs.nameOfDirectors, ""];
      const updatedStateInputs: IStageOneOnboardingStates = {
        ...prevStateInputs,
        nameOfDirectors: directorsNameArr,
      };

      //Directors Id card url
      const updatedDirectorsCardArr = [...currentDirectorsIdCardArr, ""];
      setCurrentDirectorsIdCardArr(updatedDirectorsCardArr);
      return updatedStateInputs;
    });
  };

  // Handle Remove Current director
  const handleRemoveCurrentDirector = function (
    e: MouseEvent<HTMLButtonElement>,
    index: number
  ) {
    e.preventDefault();
    setStageOneInputStates((prevStateInputs) => {
      const directorsNameArr = [...prevStateInputs.nameOfDirectors];
      directorsNameArr.splice(index, 1);
      const updatedStateInputs: IStageOneOnboardingStates = {
        ...prevStateInputs,
        nameOfDirectors: directorsNameArr,
      };

      //Directors Id card url
      let updatedDirectorsCardArr = [...currentDirectorsIdCardArr];
      updatedDirectorsCardArr.splice(index, 1);
      setCurrentDirectorsIdCardArr(updatedDirectorsCardArr);
      return updatedStateInputs;
    });
  };

  useEffect(() => {
    if (stageTwoInputStates)
      setCurrentDirectorsIdCardArr(stageTwoInputStates.directorsIdCard);
  }, [stageTwoInputStates]);
  return (
    <div className="onboarding-form-page-container onboarding-form-page-one-container">
      {/* Onboardng form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          {/* <button>
            <img src={arrowLeftIcon} alt="" />
          </button> */}
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">1/3</div>
      </div>

      {/* Onboarding Form Wrapper */}
      <div className="onboarding-form-wrapper">
        <div className="onboarding-form-title-wrapper">
          Complete the form below
        </div>

        <form autoComplete="off">
          <div className="onboarding-form-page-one--row-one">
            {/* Company Name form group */}
            {isAddNewCompanyToBrokers && (
              <div className="form-group company-name-form-group">
                <TextInput
                  type={"text"}
                  className={"company-name-text-input"}
                  placeholder={"Company name"}
                  value={stageOneInputStates?.companyName || ""}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        companyName: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>
            )}

            {/* Company Email form group */}
            {isAddNewCompanyToBrokers && (
              <div className="form-group company-email-form-group">
                <TextInput
                  type={"email"}
                  className={"company-email-text-input"}
                  placeholder={"Company email address"}
                  value={stageOneInputStates?.companyEmail || ""}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        companyEmail: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>
            )}

            {/* Company Phone form group */}
            {isAddNewCompanyToBrokers && (
              <div className="form-group company-phone-form-group">
                <TextInput
                  type={"tel"}
                  className={"company-phone-text-input"}
                  placeholder={"Company phone number"}
                  value={stageOneInputStates?.companyPhoneNumber || ""}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        companyPhoneNumber: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>
            )}

            {/* Company RC No form group */}
            {isAddNewCompanyToBrokers && (
              <div className="form-group company-cac-form-group">
                <TextInput
                  type={"tel"}
                  className={"company-cac-text-input"}
                  placeholder={"RC number"}
                  value={stageOneInputStates?.rcNumber || ""}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        rcNumber: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>
            )}

            {/* TIN Number form group */}
            <div className="form-group tin-number-form-group">
              <TextInput
                type={"text"}
                className={"tin-number-text-input"}
                placeholder={"TIN Number"}
                value={stageOneInputStates.tinNumber}
                onChange={(e) => {
                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneOnboardingStates = {
                      ...prevStateInputs,
                      tinNumber: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Estimated Annual Sum Insured form group */}
            <div className="form-group easi-form-group">
              <TextInput
                type={"text"}
                className={"easi-text-input"}
                placeholder={"Estimated Annual Sum Insured"}
                value={handleFormatNumberInputs(
                  stageOneInputStates.estimatedAnnualSuminsured
                )}
                onChange={(e) => {
                  // Remove all the commas from the number value
                  let value = e.target.value;
                  let valueWithoutCommas = value.split(",").join("");

                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneOnboardingStates = {
                      ...prevStateInputs,
                      estimatedAnnualSuminsured: valueWithoutCommas,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
                min={"0"}
              />
            </div>

            {/* Limit Per Carriage form group */}
            <div className="form-group limit-per-carriage-form-group">
              <TextInput
                type={"text"}
                className={"limit-per-carriage-text-input"}
                placeholder={"Limit Per Carriage"}
                value={handleFormatNumberInputs(
                  stageOneInputStates.limitPerCarriage
                )}
                onChange={(e) => {
                  // Remove all the commas from the number value
                  let value = e.target.value;
                  let valueWithoutCommas = value.split(",").join("");

                  setStageOneInputStates((prevStateInputs) => {
                    const updatedStateInputs: IStageOneOnboardingStates = {
                      ...prevStateInputs,
                      limitPerCarriage: valueWithoutCommas,
                    };
                    return updatedStateInputs;
                  });
                }}
                required={true}
                min={"0"}
              />
            </div>

            {/* NAICOM License Number form group ||TODO ONLY FOR BROKERS */}
            {user?.customerType === "Broker" && !isAddNewCompanyToBrokers && (
              <div className="form-group naicom-license-number-form-group">
                <TextInput
                  type={"text"}
                  className={"naicom-license-number-text-input"}
                  placeholder={"Naicom License Number"}
                  value={
                    stageOneInputStates?.naicomLicenseNumber
                      ? stageOneInputStates.naicomLicenseNumber
                      : ""
                  }
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        naicomLicenseNumber: e.target.value,
                      };

                      return updatedStateInputs;
                    });
                  }}
                  required={user?.customerType === "Broker" ? true : false}
                />
              </div>
            )}
          </div>

          <div className="onboarding-form-page-one--row-two">
            <div className="onboarding-form--sub-title-wrapper">
              Name of directors
            </div>

            <div className="name-of-directors-form-group-wrapper">
              {stageOneInputStates.nameOfDirectors?.map(
                (directorName, index) => (
                  <div
                    key={index + 1}
                    className="form-group name-of-directors-form-group"
                  >
                    <TextInput
                      type={"text"}
                      className={"name-of-directors-text-input"}
                      placeholder={`Director ${index + 1}`}
                      value={directorName}
                      onChange={(e) => {
                        setStageOneInputStates((prevStateInputs) => {
                          const directorsNameArr = [
                            ...prevStateInputs.nameOfDirectors,
                          ];
                          directorsNameArr.splice(index, 1, e.target.value);
                          const updatedStateInputs: IStageOneOnboardingStates =
                            {
                              ...prevStateInputs,
                              nameOfDirectors: directorsNameArr,
                            };
                          return updatedStateInputs;
                        });
                      }}
                      required={true}
                    />
                    {/* Minus Button */}
                    {stageOneInputStates.nameOfDirectors.length > 1 && (
                      <div className="add-new-director-button-wrapper">
                        <button
                          onClick={(e) => {
                            handleRemoveCurrentDirector(e, index);
                          }}
                        >
                          <img src={minusIcon} alt="" />
                        </button>
                      </div>
                    )}

                    {/* Plus Button */}
                    {index ===
                      stageOneInputStates.nameOfDirectors.length - 1 && (
                      <div className="add-new-director-button-wrapper">
                        <button
                          onClick={(e) => {
                            handleAddNewDirectorField(e);
                          }}
                        >
                          <img src={plusIcon} alt="" />
                        </button>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          <div className="onboarding-form-page-one--row-three">
            <div className="onboarding-form--sub-title-wrapper">
              Location and address
            </div>
            <div className="onboarding-form-page-one--row-three--inner">
              {/* Country form group */}
              {/* <div className="form-group country-form-group">
                <TextInput
                  type={"text"}
                  className={"country-text-input"}
                  placeholder={"Country"}
                  value={stageOneInputStates.country}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        country: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div> */}

              {/* State form group */}
              <div className="form-group state-form-group">
                <TextInput
                  type={"text"}
                  className={"state-text-input"}
                  placeholder={"State"}
                  value={stageOneInputStates.state}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        state: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>

              {/* City form group */}
              <div className="form-group city-form-group">
                <TextInput
                  type={"text"}
                  className={"city-text-input"}
                  placeholder={"City"}
                  value={stageOneInputStates.city}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        city: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>

              {/* Address form group */}
              <div className="form-group address-form-group">
                <TextInput
                  type={"text"}
                  className={"address-text-input"}
                  placeholder={"Address"}
                  value={stageOneInputStates.address}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        address: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>

              {/* Street form group */}
              {/* <div className="form-group street-form-group">
                <TextInput
                  type={"text"}
                  className={"street-text-input"}
                  placeholder={"Street"}
                  value={stageOneInputStates.street}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        street: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div> */}

              {/* Street Number form group */}
              {/* <div className="form-group street-no-form-group">
                <TextInput
                  type={"text"}
                  className={"street-no-text-input"}
                  placeholder={"Street No."}
                  value={stageOneInputStates.streetNumber}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        streetNumber: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div> */}

              {/* Post Code form group */}
              {/* <div className="form-group post-code-form-group">
                <TextInput
                  type={"text"}
                  className={"post-code-text-input"}
                  placeholder={"Post code"}
                  value={stageOneInputStates.postCode}
                  onChange={(e) => {
                    setStageOneInputStates((prevStateInputs) => {
                      const updatedStateInputs: IStageOneOnboardingStates = {
                        ...prevStateInputs,
                        postCode: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div> */}
            </div>
          </div>

          <div className="onboarding-form-page-bottom-wrapper">
            {/* Fields mandatory wrapper */}
            <div className="field-are-mandatory-wrapper">
              All fields are mandatory*
            </div>

            {/* Submit Onboarding Form Button Wrapper */}
            <div className="submit-onboarding-form-button-wrapper">
              <button onClick={(e) => handleNavigateToPageTwo(e)}>
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OnboardingStageOne;
