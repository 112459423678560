import React, { useState } from "react";
import "./Pagination.css"
import arrowLeftIcon from "../../images/arrow-left.svg"
import arrowRightIcon from "../../images/arrow-right.svg"


export const Pagination = ({ rowsPerPage, totalPosts, paginate, currentPage, setCurrentPage }) => {
    // Functions, states and variables

    // States
    const [selectedPageToGoTo, setSelectedPageToGoTo] = useState("")

    // Functions
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    // handle Navigate To Selected Page
    const handleNavigateToSelectedPage = function (pageNum) {
        if (pageNum === "") {
            // Only Update the Input and not the page
            setSelectedPageToGoTo(pageNum)
        } else if (pageNum === "0" || pageNum > pageNumbers.length) {
            // Do Nothing
            return;
        } else {
            // Only Update the Input and  the page
            setSelectedPageToGoTo(pageNum)
            setCurrentPage(parseInt(pageNum))
        }
    }

    return (
        <div className="pagination-section">
            <div className="pages-number-count-container">
                <span>{currentPage}</span> of {pageNumbers[pageNumbers.length - 1]} {pageNumbers.length > 1 ? "pages" : "page"}
            </div>
            <div className="pagination-container">
                <nav>
                    <div className="prev-button-wrapper">
                        <button
                            onClick={() => {
                                if (currentPage > 1) {
                                    paginate(currentPage - 1)
                                }
                            }
                            }>
                            <img src={arrowLeftIcon} alt="" />
                        </button>
                    </div>
                    <ul className="pagination__">
                        {pageNumbers.map(number => (
                            <li className={`pagination--page-item ${number === currentPage ? "active" : number === currentPage - 1 ? "active-sibling" : ""}`} key={number}>
                                <button
                                    onClick={() => paginate(number)}

                                    className="page-link"
                                >
                                    {number}
                                </button>
                            </li>
                        ))}

                    </ul>
                    <div className="next-button-wrapper">
                        <button
                            onClick={() => {
                                if (currentPage * rowsPerPage < totalPosts) {
                                    paginate(currentPage + 1)
                                }
                            }
                            }>
                            <img src={arrowRightIcon} alt="" />
                        </button>
                    </div>
                </nav>
            </div>

            <div className="go-to-page-number-container">
                Go to page
                <div className="select-go-to-page-input-wrapper">
                    <input type="number" value={selectedPageToGoTo} min={"1"} max={(pageNumbers.length).toString()} onChange={(e) => handleNavigateToSelectedPage(e.target.value)} />
                </div>
            </div>
        </div>
    );
};
