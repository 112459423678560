import React from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { ISingleSubsidiary } from "../../../Types";
import { useNavigate } from "react-router-dom";

interface IProps {
  currentPosts: ISingleSubsidiary[] | null;
  isLoading: boolean;
}

const SubsidiariesTable = function ({ currentPosts, isLoading }: IProps) {
  // Functions and States
  const navigate = useNavigate();
  // States
  return (
    <>
      {/*  Subsidiaries Table Container */}
      <div className="app-table-container subsidiaries-table-container">
        <Table responsive="lg" className="app--table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "Subsidiary Name",
                "Subsidiary Email",
                "TIN Number",
                "Status",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((subsidiary, index) => (
              <tr key={index + 1}>
                {/* Subsidiary Name Column */}
                <td className="td-company-name">
                  <div className="td-company-name--wrapper">
                    {subsidiary.name}
                  </div>
                </td>

                {/* Subsidiary Email Column */}
                <td className="td-company-email">
                  <div className="td-company-email--wrapper">
                    {subsidiary.email}
                  </div>
                </td>

                {/*  TIN Number Column */}
                <td className="td-tin-no">
                  <div className="td-tin-no--wrapper">{subsidiary.tin}</div>
                </td>

                {/*  Status Column */}
                <td className="td-status">
                  <div
                    className={`td-status--wrapper  ${
                      subsidiary?.isApproved
                        ? "completed-status-wrapper"
                        : "in-transit-status-wrapper"
                    }`}
                  >
                    {!subsidiary?.isApproved && subsidiary.isRejected
                      ? "Rejected"
                      : subsidiary?.isApproved
                      ? "Approved"
                      : "Pending"}
                  </div>
                </td>

                {/*  View Certificate History Button Column */}
                {/* <td className="td-view-policy-history">
                  <div className="td-view-policy-history--wrapper">
                    <button
                      onClick={() =>
                        navigate("/subsidiary/certificates-history", {
                          state: {
                            isCompaniesHistory: true,
                            subsidiaryId: subsidiary.id,
                            subsidiaryName: subsidiary.name,
                          },
                        })
                      }
                    >
                      View policy history
                    </button>
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default SubsidiariesTable;
