import React from "react";
import "./DropdownInput.css";
import dropdownIcon from "../../../images/angle-down.svg";

interface IProps {
  className: string;
  name?: string;
  id?: string;
  value: string;
  onChange: (e: any) => void;
  label?: string;
  required?: boolean;
  optionArray: {
    key: string | number;
    value: string | number;
    default?: string;
  }[];
  placeholder?: string;
  disabled?: boolean;
}
const DropdownInput: React.FC<IProps> = ({
  className,
  id,
  name,
  optionArray,
  required,
  value,
  onChange,
  label,
  placeholder,
  disabled,
}) => {
  return (
    <>
      <select
        name={name}
        id={id}
        className={
          className + " " + (disabled === true ? "disabled-select-input" : "")
        }
        required={required}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {optionArray.map((optionValue, i) => (
          <option
            key={i + 1}
            disabled={optionValue.default ? true : false}
            value={optionValue.key}
            hidden={optionValue.default ? true : false}
          >
            {optionValue.value}
          </option>
        ))}
      </select>
      <span className="placeholder">{placeholder}</span>
      <div className="dropdown-icon">
        <img src={dropdownIcon} alt="" />
      </div>
    </>
  );
};

export default DropdownInput;
