import React from "react";
import "./TextInput.css";

interface IProps {
  name?: string;
  type: string;
  id?: string;
  className: string;
  placeholder?: string;
  value: string;
  onChange: (e: any) => void;
  required?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  disabled?: boolean;
  onFocus?: (e: any) => void;
  autoComplete?: string;
}
// Can be Text, Password, Email, Number or Url Input
const TextInput: React.FC<IProps> = ({
  name,
  id,
  type,
  className,
  placeholder,
  value,
  onChange,
  required,
  min,
  max,
  maxLength,
  disabled,
  onFocus,
  autoComplete,
}) => {
  return (
    <>
      <input
        type={type}
        id={id}
        className={
          className + " " + (disabled === true ? "disabled-text-input" : "")
        }
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        maxLength={maxLength}
        disabled={disabled}
        onFocus={onFocus}
        autoComplete={autoComplete}
      />
      <span className="placeholder">{placeholder}</span>
    </>
  );
};

export default TextInput;
