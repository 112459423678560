import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { api } from "../../api/config";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";
import {
  GET_ALL_COMPANIES_FOR_BROKER,
  GET_COMPANY_WALLET_HISTORY,
} from "../Constants";

// Interfaces
interface IBrokerCompanyDetails {
  email: string;
  companyName: string;
  phone: string;
  rcNumber: string;
  customerType: string;
  ref_id: string;
  tin: string;
  naicomLicence: string;
  directors: string[];
  cacImageUrl: string;
  directorsIDCardImageUrl: string[];
  naicomLicenseImageUrl: string;
  utilityImageUrl: string;
  estimatedAnnualSuminsured: number;
  limitPerCarriage: number;
  streetNumber: string;
  address: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postCode: string;
}
const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;

// Createn Customer/Company Details for Brokers
export const createCompanyForBrokers =
  (
    data: IBrokerCompanyDetails,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/create-customer-details`, data)
      .then((res) => {
        setIsLoading(false);
        //  Navigate to companies where success message will be displayed based on link
        navigate("/companies", { state: { isNewCompanyAdded: true } });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get All Companies for Broker
export const getAllCompaniesForBroker =
  (setErrorHandlerObj: SetErrorHandlerType, setIsLoading: SetIsLoadingType) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/broker/companies`)
      .then((res) => {
        setIsLoading(false);
        // console.log(res.data);
        // Dispatch the data
        dispatch({
          type: GET_ALL_COMPANIES_FOR_BROKER,
          payload: res.data?.reverse(),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get company wallet balance
export const getCompanyWalletHistory =
  (
    companyId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/wallet-history/${companyId}`)
      .then((res) => {
        setIsLoading(false);
        dispatch({
          type: GET_COMPANY_WALLET_HISTORY,
          payload: res.data?.reverse(),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get company unit balance
export const getCompanyUnitHistory =
  (
    companyId: string,
    setIsLoading: SetIsLoadingType,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/unit-history/${companyId}`)
      .then((res) => {
        setIsLoading(false);
        dispatch({
          type: GET_COMPANY_WALLET_HISTORY,
          payload: res.data?.reverse(),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
