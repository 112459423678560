import React from "react";
import "./SignupSuccessful.css";
import congratulationsIcon from "../../../../images/congratulations-icon.svg";

function SignupSuccessful() {
  return (
    <div className="signup-successful-container">
      {/* Auth Form Box Container / Stage One */}
      <div className="auth-form-box-container onboarding-stage-five-box">
        <div className="congratulations-img-wrapper">
          <img src={congratulationsIcon} alt="" />
        </div>

        <div className="signup-success-main-text-wrapper">Completed</div>

        <div className="signup-success-sub-text-wrapper">
          {/* Your details have been sent and awaiting approval. Approval usually
          takes *-* working days. Please sign in to check approval status */}
          Your account has been created successfully. Kindly go on to sign in
          and complete the onboarding process.
        </div>
      </div>
    </div>
  );
}

export default SignupSuccessful;
