/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ALL_COMPANIES_FOR_BROKER,
  GET_COMPANY_WALLET_HISTORY,
} from "../Constants";

const initialState = {
  companies: [],
  walletHistory: [],
};

export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_COMPANIES_FOR_BROKER:
      return {
        ...state,
        companies: action.payload,
      };
    case GET_COMPANY_WALLET_HISTORY:
      return {
        ...state,
        walletHistory: action.payload,
      };
    default:
      return state;
  }
}
