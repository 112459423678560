import React, { useContext, useEffect, useState } from "react";
import "./Companies.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import searchIcon from "../../images/search-icon.svg";
import isEmpty from "../../validation/isEmpty";
import { Pagination } from "../../components/pagination/Pagination";
import CompaniesTable from "./companies-table/CompaniesTable";
import {
  ISingleUser,
  UserContext,
} from "../../components/contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCompaniesForBroker } from "../../redux/actions/companyActions";

// Interfaces
function Companies() {
  // Functions, states and Variables
  const { user }: any = useContext(UserContext);
  const companies: ISingleUser[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  //   States
  const [allCompanies, setAllCompanies] = useState<ISingleUser[] | null>(null);
  const [currentCompaniesPosts, setCurrentCompaniesPosts] = useState<
    ISingleUser[] | null
  >(null);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  //   Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!allCompanies) return false;
    const companiesData = companies?.filter(
      (company) =>
        company.companyName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        company.tin.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllCompanies(companiesData);
    // Update table state to all certificate rows
    if (!companiesData) return false;
    const currentRows = companiesData.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentCompaniesPosts(currentRows);
  };

  //   handle Navigate To Add New Company
  const handleNavigateToAddNewCompany = function () {
    if (!user?.isApproved) {
      setErrorHandlerObj({
        hasError: true,
        message: "Your account is not yet approved!",
      });
      return;
    }
    // Navigate to add new company page
    navigate("/company/add-new", { state: { isFromCompaniesPage: true } });
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getAllCompaniesForBroker(setErrorHandlerObj, setIsLoading));
  }, []);

  useEffect(() => {
    setAllCompanies(companies);
  }, [companies]);

  useEffect(() => {
    if (allCompanies) {
      const currentRows = allCompanies?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentCompaniesPosts(currentRows);
    }
  }, [allCompanies, currentPage]);

  useEffect(() => {
    handleFilterTable();
  }, [searchedValue]);

  useEffect(() => {
    if (location.state) {
      const { isNewCompanyAdded }: any = location.state;
      if (isNewCompanyAdded)
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Company added successfully!",
        });
    }
  }, [location]);

  return (
    <div className="companies-container">
      <Top pageSummaryMessage="Check companies attached to you" />

      <div className="companies-container--inner">
        <Sidebar activeName={"Companies"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app-error-success-message-wrapper"
        />
        <section className="companies-container-main-section">
          {/* companies table section */}
          <section className="companies-table-section">
            <div className="companies-table--top-wrapper">
              {/* Table Title wrapper */}
              {/* <div className="table-title-wrapper">Certificates</div> */}

              {/* Top Left Wrapper */}
              <div className="companies-table--top--left-wrapper">
                {/* Table Search Input form group */}
                <div className="table-search-form-group">
                  <input
                    type="search"
                    value={searchedValue}
                    onChange={(e) => setSearchedValue(e.target.value)}
                    placeholder="search here"
                  />
                  <img src={searchIcon} alt="" />
                </div>

                {/* Add a New Company button */}
                <div className="add-company-button-wrapper">
                  <button onClick={() => handleNavigateToAddNewCompany()}>
                    Add a company
                  </button>
                </div>
              </div>
            </div>

            {/* Companies Table Container */}
            <CompaniesTable
              currentPosts={currentCompaniesPosts}
              isLoading={isLoading}
            />
            {allCompanies && !isEmpty(currentCompaniesPosts) && (
              <>
                <Pagination
                  paginate={paginate}
                  rowsPerPage={rowsPerPage}
                  totalPosts={allCompanies.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          </section>
        </section>
      </div>
    </div>
  );
}

export default Companies;
