import React, { useEffect, useState } from "react";
import "./WalletHistory.css";
import Sidebar from "../../../components/app-sidebar/Sidebar";
import ErrorHandler from "../../../components/error/ErrorHandler";
import Top from "../../../components/app-top/Top";
import arrowLeftIcon from "../../../images/arrow-left-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from "../../../validation/isEmpty";
import { Pagination } from "../../../components/pagination/Pagination";
import WalletHistoryTable from "./wallet-history-table/WalletHistoryTable";
import { IWalletHistory } from "../../../Types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCompanyWalletHistory } from "../../../redux/actions/companyActions";

function WalletHistory() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const walletHistory: IWalletHistory[] | [] = useAppSelector(
    (state) => state.companies.walletHistory
  );
  //   States
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [companyWalletBalance, setCompanyWalletBalance] = useState("");
  const [currentWalletHistoryPosts, setCurrentWalletHistoryPosts] = useState<
    IWalletHistory[] | null
  >(null);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  // Functions
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   UseEffects
  useEffect(() => {
    if (location.state) {
      const { subsidiaryId, subsidiaryName, walletBalance }: any =
        location.state;
      //   Update Subsidiary name state
      setSelectedCompanyName(subsidiaryName);
      setCompanyWalletBalance(walletBalance);
      //   Call the API to fetch wallet history for subsidiary
      dispatch(
        getCompanyWalletHistory(subsidiaryId, setIsLoading, setErrorHandlerObj)
      );
    } else {
      navigate(-1);
    }
  }, [location]);

  useEffect(() => {
    if (walletHistory) {
      const currentRows = walletHistory?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentWalletHistoryPosts(currentRows);
    }
  }, [walletHistory, currentPage]);

  return (
    <div className={`certificates-container`}>
      <Top pageSummaryMessage={""} />

      <div className="certificates-container--inner">
        <Sidebar activeName={"Companies"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        <div className="selected-company-name-wrapper">
          <div className="certificates--back-button-wrapper">
            <button onClick={() => navigate(-1)}>
            {/* <button onClick={() => navigate("/companies")}> */}
              <img src={arrowLeftIcon} alt="" />
            </button>
          </div>
          {selectedCompanyName}
        </div>

        <section className="certificates-container-main-section">
          {/* Wallet Balance Wrapper */}
          <div className="history--wallet-balance-wrapper">
            <div className="title-wrapper">Wallet Balance:</div>
            <div className="value-wrapper">
              {" "}
              &#8358;{companyWalletBalance?.toLocaleString()}
            </div>
          </div>
          {/* Wallet History Table Container */}
          <WalletHistoryTable
            currentPosts={currentWalletHistoryPosts}
            isLoading={isLoading}
            companyWalletBalance={companyWalletBalance}
          />
          {!isLoading &&
            // walletHistory &&
            !isEmpty(currentWalletHistoryPosts) && (
              <>
                <Pagination
                  paginate={paginate}
                  rowsPerPage={rowsPerPage}
                  totalPosts={walletHistory.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
        </section>
      </div>
    </div>
  );
}

export default WalletHistory;
