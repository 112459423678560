import React, { MouseEvent, useState } from "react";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import { SetErrorHandlerType } from "../../../../Types";
import { ISubsidiaryFormInputStates } from "../AddNewSubsidiary";
import plusIcon from "../../../../images/plus-icon.svg";
import { useAppDispatch } from "../../../../hooks";
import { onboardNewSubsidiary } from "../../../../redux/actions/subsidiaryActions";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";

// Interfaces
interface IProps {
  subsidiaryFormInputStates: ISubsidiaryFormInputStates;
  setSubsidiaryFormInputStates: React.Dispatch<
    React.SetStateAction<ISubsidiaryFormInputStates>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
}

function AddNewSubsidiaryForm({
  subsidiaryFormInputStates,
  setSubsidiaryFormInputStates,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //   States
  const [isLoading, setIsLoading] = useState(false);
  //   Functions
  // handle Add New Director Field
  const handleAddNewDirectorField = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    setSubsidiaryFormInputStates((prevStateInputs) => {
      const directorsNameArr = [...prevStateInputs.nameOfDirectors, ""];
      const updatedStateInputs: ISubsidiaryFormInputStates = {
        ...prevStateInputs,
        nameOfDirectors: directorsNameArr,
      };
      return updatedStateInputs;
    });
  };

  //   handle Add Subsidiary
  const handleAddSubsidiary = function (e: MouseEvent<HTMLButtonElement>) {
    const isFormEmpty = Object.keys(subsidiaryFormInputStates)?.some(
      (inputKeys) =>
        subsidiaryFormInputStates[
          inputKeys as keyof typeof subsidiaryFormInputStates
        ] === ""
    );

    const isDirectorsFieldEmpty =
      subsidiaryFormInputStates.nameOfDirectors.some(
        (directorName) => directorName === ""
      );

    if (!isFormEmpty && !isDirectorsFieldEmpty) {
      e.preventDefault();

      const address = `${subsidiaryFormInputStates.address}, ${subsidiaryFormInputStates.city}, ${subsidiaryFormInputStates.state}`;

      //  Call the API to add subsidiary
      const data = [
        {
          name: subsidiaryFormInputStates.subsidiaryName,
          email: subsidiaryFormInputStates.subsidiaryEmail,
          phone: subsidiaryFormInputStates.subsidiaryPhone,
          tin: subsidiaryFormInputStates.tin,
          rcNumber: subsidiaryFormInputStates.rcNumber,
          directors: subsidiaryFormInputStates.nameOfDirectors,
          address,
        },
      ];

      dispatch(
        onboardNewSubsidiary(data, setErrorHandlerObj, setIsLoading, navigate)
      );
    }
  };
  return (
    <div className="onboarding-form-page-container onboarding-form-page-one-container">
      {/* Onboardng form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper"></div>
      </div>

      {/* Onboarding Form Wrapper */}
      <div className="onboarding-form-wrapper">
        <div className="onboarding-form-title-wrapper">
          Complete the form below
        </div>

        <form autoComplete="off">
          <div className="onboarding-form-page-one--row-one">
            {/* Subsidiary Name form group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                className={"subsidiary-name-text-input"}
                placeholder={"Subsidiary name"}
                value={subsidiaryFormInputStates.subsidiaryName}
                onChange={(e) => {
                  setSubsidiaryFormInputStates((prevStateInputs) => {
                    const updatedStateInputs: ISubsidiaryFormInputStates = {
                      ...prevStateInputs,
                      subsidiaryName: e.target.value,
                    };
                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Subsidiary Email form group */}
            <div className="form-group">
              <TextInput
                type={"email"}
                className={"subsidiary-email-text-input"}
                placeholder={"Subsidiary email address"}
                value={subsidiaryFormInputStates.subsidiaryEmail}
                onChange={(e) => {
                  setSubsidiaryFormInputStates((prevStateInputs) => {
                    const updatedStateInputs: ISubsidiaryFormInputStates = {
                      ...prevStateInputs,
                      subsidiaryEmail: e.target.value,
                    };
                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Subsidiary Phone form group */}
            <div className="form-group">
              <TextInput
                type={"tel"}
                className={"subsidiary-phone-text-input"}
                placeholder={"Subsidiary phone number"}
                value={subsidiaryFormInputStates.subsidiaryPhone}
                onChange={(e) => {
                  setSubsidiaryFormInputStates((prevStateInputs) => {
                    const updatedStateInputs: ISubsidiaryFormInputStates = {
                      ...prevStateInputs,
                      subsidiaryPhone: e.target.value,
                    };
                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* Subsidiary RC No form group */}
            <div className="form-group">
              <TextInput
                type={"tel"}
                className={"Subsidiary-cac-text-input"}
                placeholder={"RC number"}
                value={subsidiaryFormInputStates.rcNumber}
                onChange={(e) => {
                  setSubsidiaryFormInputStates((prevStateInputs) => {
                    const updatedStateInputs: ISubsidiaryFormInputStates = {
                      ...prevStateInputs,
                      rcNumber: e.target.value,
                    };
                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>

            {/* TIN Number form group */}
            <div className="form-group tin-number-form-group">
              <TextInput
                type={"text"}
                className={"tin-number-text-input"}
                placeholder={"TIN Number"}
                value={subsidiaryFormInputStates.tin}
                onChange={(e) => {
                  setSubsidiaryFormInputStates((prevStateInputs) => {
                    const updatedStateInputs: ISubsidiaryFormInputStates = {
                      ...prevStateInputs,
                      tin: e.target.value,
                    };

                    return updatedStateInputs;
                  });
                }}
                required={true}
              />
            </div>
          </div>

          <div className="onboarding-form-page-one--row-two">
            <div className="onboarding-form--sub-title-wrapper">
              Name of directors
            </div>

            <div className="name-of-directors-form-group-wrapper">
              {subsidiaryFormInputStates.nameOfDirectors?.map(
                (directorName, index) => (
                  <div
                    key={index + 1}
                    className="form-group name-of-directors-form-group"
                  >
                    <TextInput
                      type={"text"}
                      className={"name-of-directors-text-input"}
                      placeholder={`Director ${index + 1}`}
                      value={directorName}
                      onChange={(e) => {
                        setSubsidiaryFormInputStates((prevStateInputs) => {
                          const directorsNameArr = [
                            ...prevStateInputs.nameOfDirectors,
                          ];
                          directorsNameArr.splice(index, 1, e.target.value);
                          const updatedStateInputs: ISubsidiaryFormInputStates =
                            {
                              ...prevStateInputs,
                              nameOfDirectors: directorsNameArr,
                            };
                          return updatedStateInputs;
                        });
                      }}
                      required={true}
                    />

                    {index ===
                      subsidiaryFormInputStates.nameOfDirectors.length - 1 && (
                      <div className="add-new-director-button-wrapper">
                        <button
                          onClick={(e) => {
                            handleAddNewDirectorField(e);
                          }}
                        >
                          <img src={plusIcon} alt="" />
                        </button>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          <div className="onboarding-form-page-one--row-three">
            <div className="onboarding-form--sub-title-wrapper">
              Location and address
            </div>
            <div className="onboarding-form-page-one--row-three--inner">
              {/* State form group */}
              <div className="form-group state-form-group">
                <TextInput
                  type={"text"}
                  className={"state-text-input"}
                  placeholder={"State"}
                  value={subsidiaryFormInputStates.state}
                  onChange={(e) => {
                    setSubsidiaryFormInputStates((prevStateInputs) => {
                      const updatedStateInputs: ISubsidiaryFormInputStates = {
                        ...prevStateInputs,
                        state: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>

              {/* City form group */}
              <div className="form-group city-form-group">
                <TextInput
                  type={"text"}
                  className={"city-text-input"}
                  placeholder={"City"}
                  value={subsidiaryFormInputStates.city}
                  onChange={(e) => {
                    setSubsidiaryFormInputStates((prevStateInputs) => {
                      const updatedStateInputs: ISubsidiaryFormInputStates = {
                        ...prevStateInputs,
                        city: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>

              {/* Address form group */}
              <div className="form-group address-form-group">
                <TextInput
                  type={"text"}
                  className={"address-text-input"}
                  placeholder={"Address"}
                  value={subsidiaryFormInputStates.address}
                  onChange={(e) => {
                    setSubsidiaryFormInputStates((prevStateInputs) => {
                      const updatedStateInputs: ISubsidiaryFormInputStates = {
                        ...prevStateInputs,
                        address: e.target.value,
                      };
                      return updatedStateInputs;
                    });
                  }}
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="onboarding-form-page-bottom-wrapper">
            {/* Fields mandatory wrapper */}
            <div className="field-are-mandatory-wrapper">
              All fields are mandatory*
            </div>

            {/* Submit Onboarding Form Button Wrapper */}
            <div className="submit-onboarding-form-button-wrapper">
              <button onClick={(e) => handleAddSubsidiary(e)}>Submit</button>
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddNewSubsidiaryForm;
