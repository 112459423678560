import axios from "axios";
import { api } from "../../api/config";
import { GET_CURRENT_USER } from "../Constants";
import { NavigateFunction } from "react-router-dom";
import { SetErrorHandlerType, SetIsLoadingType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

interface ISignupData {
  email: string;
  companyName: string;
  phone: string;
  customerType: string;
  password: string;
  rcNumber?: string;
  ref_id?: string;
}

interface IOnboardingData {
  tin: string;
  naicomLicence: string;
  directors: string[];
  cacImageUrl: string;
  directorsIDCardImageUrl: string[];
  naicomLicenseImageUrl: string;
  utilityImageUrl: string;
  estimatedAnnualSuminsured: number;
  limitPerCarriage: number;
  streetNumber: string;
  address: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postCode: string;
}

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = `Bearer ${token}`;

const url = `${api}/marineportal`;
// Register user
export const registerUser =
  (
    userData: ISignupData,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType,
    navigate: NavigateFunction,
    setPageContent: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/createcustomer`, userData)
      .then((res) => {
        setPageContent("signup-successful");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get Current User
export const getCurrentUser =
  (
    userId: string,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/getcustomer`)
      .then((res) => {
        setIsLoading(false);
        // Dispatch the data
        dispatch({
          type: GET_CURRENT_USER,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Conclude Onboarding
export const concludeOnboarding =
  (
    data: IOnboardingData,
    setErrorHandlerObj: SetErrorHandlerType,
    setIsLoading: SetIsLoadingType,
    setPageContent: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .post(`${url}/conclude-onboarding`, data)
      .then((res) => {
        setPageContent("onboarding-successful");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };
