import React, { useState } from "react";
import "./PolicyStageZeroFive.css";
import { SetErrorHandlerType } from "../../../../Types";
import openCoverIcon from "../../../../images/open-cover-icon.svg";
import singleTransitIcon from "../../../../images/single-transit-icon.svg";

// Interfaces
interface IProps {
  setPolicyType: React.Dispatch<React.SetStateAction<string>>;
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

export interface ISinglePolicyType {
  _id: string;
  value: string;
  key: string;
  icon: string;
}

function PolicyStageZeroFive({
  setPolicyType,
  pageContent,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  // States
  const [allPolicyTypes, setAllPolicyTypes] = useState<
    ISinglePolicyType[] | null
  >([
    {
      _id: "policy-1",
      key: "OpenCover",
      value: "Open cover policy",
      icon: openCoverIcon,
    },
    {
      _id: "policy-2",
      key: "SingleTransit",
      value: "Single transit",
      icon: singleTransitIcon,
    },
  ]);

  //   Functions
  // handle Navigate To Page One
  const handleNavigateToPageOne = function (selectedPolicyType: string) {
    // Update the selected policy type state
    setPolicyType(selectedPolicyType);
    // console.log(selectedPolicyType);
    // Navigate to next page
    setPageContent("stage-one");
  };

  // UseEffects

  return (
    // Policy Type Selection Page
    <div className="policy-stage-container policy-stage-one-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper"></div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">0.5/7</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        {/* Ploicy Type Selection Container */}
        <div className="policy-plan-selection-container">
          {/* Title Wrapper */}
          <div className="policy-plan-selection-title-wrapper">
            Choose policy type
          </div>

          {/* Boxes Wrapper */}
          <div className="policy-plan-selection-boxes-wrapper">
            {allPolicyTypes?.map((policyType, index) => (
              <div
                key={index + 1}
                className={`policy-type-selection-box-wrapper`}
              >
                <div className="policy-type-selection-box-wrapper--inner">
                  {/* /Icon Wrapper  */}
                  <div className="policy-type-icon-wrapper">
                    <img src={policyType.icon} alt="" />
                  </div>

                  {/* Policy Type Name */}
                  <div className="policy-type-name-wrapper">
                    {policyType.value}
                  </div>

                  <div className="buy-plan-button-wrapper choose-policy-type-button-wrapper">
                    <button
                      onClick={() => handleNavigateToPageOne(policyType.key)}
                    >
                      Choose
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyStageZeroFive;
