import React from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loadingio-spinner-dual-ring-tk7n0yezor">
        <div className="ldio-ths3jlz8hwg">
          <div></div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
