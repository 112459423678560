import React, { MouseEvent, useEffect, useState } from "react";
import "./ForgotPassword.css";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import ErrorHandler from "../../components/error/ErrorHandler";
import hexalSpiralImg from "../../images/hexal-spiral.svg";
import TextInput from "../../components/input-components/text-input/TextInput";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../hooks";
import { forgotPassword } from "../../redux/actions/authActions";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // States
  const [email, setEmail] = useState("");

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });

  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  const handleSubmitMailForPasswordResetLink = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    // Set Error and Success Obj to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    if (email !== "") {
      e.preventDefault();
      //   Call the dispatch function to call the forgot password API
      const data = { email };
      dispatch(
        forgotPassword(
          data,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsLoading,
          navigate
        )
      );
    }
  };

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);

  return (
    <div className="forgot-password-container">
      <AuthNavbar page="forgot-password" />

      <div className="forgot-password-container--inner">
        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="auth-error-success-message-wrapper"
        />
        {/* Hexal Spiral Wrapper */}
        <div className="hexal-spiral-wrapper">
          <img src={hexalSpiralImg} alt="" />
        </div>
        {/* Auth Left Section */}
        <section className="auth-left-section">
          {/* Primary Text Line */}
          <div className="auth-left--primary-text-line">
            You can’t predict the journey
          </div>

          {/* Secondary Text Line */}
          <div className="auth-left--secondary-text-line">
            But we can help you <span>protect it</span>
          </div>
        </section>
        {/* Auth Right Section */}
        <section className="auth-right-section">
          {/* Auth Form Box Container */}
          <div className="auth-form-box-container">
            {/* Welcome Wrapper */}
            <div className="auth-welcome-wrapper">Hello🖐</div>

            {/* Auth Title Wrapper */}
            <div className="auth-title-wrapper">Forgot Password</div>

            {/* Form Progress Line */}
            <div className="form-progress-line-wrapper"></div>

            {/* Forgot Password Text Message */}
            <div className="forgot-password-header-message">
              Submit your email to get a password reset token
            </div>

            {/* Auth Form Wrapper */}
            <div className="signin-form-wrapper forgot-password-form-wrapper">
              <form>
                {/* Email form group */}
                <div className="form-group email-form-group">
                  <TextInput
                    type={"text"}
                    className={"email-text-input"}
                    placeholder={"Email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                  />
                </div>

                {/* Submit Auth Form Button Wrapper */}
                <div className="submit-auth-form-button-wrapper">
                  <button
                    onClick={(e) => handleSubmitMailForPasswordResetLink(e)}
                  >
                    Submit
                  </button>
                  <div className="auth-loading-spinner-wrapper">
                    {isLoading && <LoadingSpinner />}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ForgotPassword;
