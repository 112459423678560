import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { UserContext } from "../../../components/contexts/UserContext";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import "./OnboardingStatusTable.css";

// Interfaces
interface IProps {
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setIsToEditExistingData: React.Dispatch<React.SetStateAction<boolean>>;
}

function OnboardingStatusTable({
  setPageContent,
  setIsToEditExistingData,
}: IProps) {
  // Fucntions, states and variables
  const { user: userInfo }: any = useContext(UserContext);
  // States
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleEditOnboardingForm = function () {
    setPageContent("stage-one");
    setIsToEditExistingData(true);
  };

  // UseEffects
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return (
    <div className="onboarding-status-table-container">
      <div className="app-table-container certificates-table-container">
        <Table responsive="lg" className="app--table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["Date", "Company Name", "TIN Number", "Status", "", ""].map(
                (COLUMN, index) => (
                  <th key={index + 1}>{COLUMN}</th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            <tr>
              {/* Date Column */}
              <td className="td-date">
                <div className="td-date--wrapper">
                  {moment(userInfo?.createdAt?.date || "").format("L")}
                </div>
              </td>

              {/*  Company Name Column */}
              <td className="td-company-name">
                <div className="td-company-name--wrapper">
                  {userInfo?.companyName || ""}
                </div>
              </td>

              {/*  Cargo Type Column */}
              <td className="td-cac">
                <div className="td-cac--wrapper">{userInfo?.tin || ""}</div>
              </td>

              {/*  Status Column */}
              <td className="td-status">
                <div
                  className={`td-status--wrapper  ${
                    userInfo?.status === "Approved"
                      ? "completed-status-wrapper"
                      : "in-transit-status-wrapper"
                  }`}
                >
                  {userInfo?.status === "Rejected"
                    ? "Rejected"
                    : userInfo?.status === "Approved"
                    ? "Approved"
                    : userInfo?.status === "Queried"
                    ? "Queried"
                    : "Pending"}
                </div>
              </td>

              {/*  Edit Onboarding Form Column */}
              <td className="td-edit-button">
                {userInfo?.status !== "Approved" && (
                  <div className="td-edit-button--wrapper">
                    <button
                      onClick={() => {
                        handleEditOnboardingForm();
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </td>

              {/*  Query Reason Column */}
              <td className="td-query-reason">
                {userInfo?.status === "Queried" && (
                  <div className="td-query-reason--wrapper">
                    {userInfo?.queryReason || ""}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}

export default OnboardingStatusTable;
