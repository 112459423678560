export const allCurrencyOptionArr = [
  {
    key: "",
    value: "",
    default: "default",
  },
  {
    key: "AED",
    value: "AED",
  },
  {
    key: "AED",
    value: "AED",
  },
  {
    key: "ALL",
    value: "ALL",
  },
  {
    key: "ANG",
    value: "ANG",
  },
  {
    key: "AON",
    value: "AON",
  },
  {
    key: "AUD",
    value: "AUD",
  },
  {
    key: "AWG",
    value: "AWG",
  },
  {
    key: "AZM",
    value: "AZM",
  },
  {
    key: "BAD",
    value: "BAD",
  },
  {
    key: "BBD",
    value: "BBD",
  },
  {
    key: "BEF",
    value: "BEF",
  },
  {
    key: "BHD",
    value: "BHD",
  },
  {
    key: "BMD",
    value: "BMD",
  },
  {
    key: "BND",
    value: "BND",
  },
  {
    key: "BTN",
    value: "BTN",
  },
  {
    key: "BZD",
    value: "BZD",
  },
  {
    key: "CAD",
    value: "CAD",
  },
  {
    key: "CHF",
    value: "CHF",
  },
  {
    key: "CLP",
    value: "CLP",
  },
  {
    key: "CNY",
    value: "CNY",
  },
  {
    key: "CRC",
    value: "CRC",
  },
  {
    key: "CSK",
    value: "CSK",
  },
  {
    key: "CZK",
    value: "CZK",
  },
  {
    key: "DKK",
    value: "DKK",
  },
  {
    key: "EEK",
    value: "EEK",
  },
  {
    key: "EGP",
    value: "EGP",
  },
  {
    key: "ERN",
    value: "ERN",
  },
  {
    key: "EUR",
    value: "EUR",
  },
  {
    key: "GBP",
    value: "GBP",
  },
  {
    key: "GEL",
    value: "GEL",
  },
  {
    key: "GMD",
    value: "GMD",
  },
  {
    key: "GRD",
    value: "GRD",
  },
  {
    key: "GYD",
    value: "GYD",
  },
  {
    key: "HKD",
    value: "HKD",
  },
  {
    key: "HNL",
    value: "HNL",
  },
  {
    key: "HRD",
    value: "HRD",
  },
  {
    key: "HTG",
    value: "HTG",
  },
  {
    key: "HUF",
    value: "HUF",
  },
  {
    key: "IDR",
    value: "IDR",
  },
  {
    key: "IEP",
    value: "IEP",
  },
  {
    key: "ILS",
    value: "ILS",
  },
  {
    key: "INR",
    value: "INR",
  },
  {
    key: "IQD",
    value: "IQD",
  },
  {
    key: "ITL",
    value: "ITL",
  },
  {
    key: "JMD",
    value: "JMD",
  },
  {
    key: "JOD",
    value: "JOD",
  },
  {
    key: "JPY",
    value: "JPY",
  },
  {
    key: "KWD",
    value: "KWD",
  },
  {
    key: "KYD",
    value: "KYD",
  },
  {
    key: "LAK",
    value: "LAK",
  },
  {
    key: "LBP",
    value: "LBP",
  },
  {
    key: "LL",
    value: "LL",
  },
  {
    key: "LRD",
    value: "LRD",
  },
  {
    key: "LSL",
    value: "LSL",
  },
  {
    key: "LTL",
    value: "LTL",
  },
  {
    key: "LUF",
    value: "LUF",
  },
  {
    key: "LVL",
    value: "LVL",
  },
  {
    key: "LYD",
    value: "LYD",
  },
  {
    key: "MAD",
    value: "MAD",
  },
  {
    key: "MDL",
    value: "MDL",
  },
  {
    key: "MKD",
    value: "MKD",
  },
  {
    key: "MMK",
    value: "MMK",
  },
  {
    key: "MTL",
    value: "MTL",
  },
  {
    key: "MWK",
    value: "MWK",
  },
  {
    key: "MXP",
    value: "MXP",
  },
  {
    key: "MYR",
    value: "MYR",
  },
  {
    key: "MZM",
    value: "MZM",
  },
  {
    key: "NAD",
    value: "NAD",
  },
  {
    key: "NGN",
    value: "NGN",
  },
  {
    key: "NLG",
    value: "NLG",
  },
  {
    key: "NOK",
    value: "NOK",
  },
  {
    key: "NZD",
    value: "NZD",
  },
  {
    key: "OMR",
    value: "OMR",
  },
  {
    key: "PEI",
    value: "PEI",
  },
  {
    key: "PEN",
    value: "PEN",
  },
  {
    key: "PGK",
    value: "PGK",
  },
  {
    key: "PYG",
    value: "PYG",
  },
  {
    key: "QAR",
    value: "QAR",
  },
  {
    key: "SAR",
    value: "SAR",
  },
  {
    key: "SBD",
    value: "SBD",
  },
  {
    key: "SED",
    value: "SED",
  },
  {
    key: "SEK",
    value: "SEK",
  },
  {
    key: "SLL",
    value: "SLL",
  },
  {
    key: "SRG",
    value: "SRG",
  },
  {
    key: "SVC",
    value: "SVC",
  },
  {
    key: "SYL",
    value: "SYL",
  },
  {
    key: "SZL",
    value: "SZL",
  },
  {
    key: "TMM",
    value: "TMM",
  },
  {
    key: "TND",
    value: "TND",
  },
  {
    key: "TTD",
    value: "TTD",
  },
  {
    key: "UAE",
    value: "UAE",
  },
  {
    key: "UAH",
    value: "UAH",
  },
  {
    key: "UAK",
    value: "UAK",
  },
  {
    key: "USD",
    value: "USD",
  },
  {
    key: "UYU",
    value: "UYU",
  },
  {
    key: "YER",
    value: "YER",
  },
  {
    key: "YUD",
    value: "YUD",
  },
];
