import { MouseEvent, useEffect, useState } from "react";
import "./ContactAdmin.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import { contactAdmin } from "../../redux/actions/contactActions";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../hooks";

function ContactAdmin() {
  // Functions, states and variables
  const dispatch = useAppDispatch();
  // states
  const [message, setMessage] = useState("");
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleSubmitMessage = function (e: MouseEvent<HTMLButtonElement>) {
    // Set error && success message to default false state
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    if (message !== "") {
      e.preventDefault();
      // TODO Call the API to submit message
      const data = {
        message,
      };

      dispatch(
        contactAdmin(
          data,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsLoading
        )
      );
    }
  };

  // UseEffects
  useEffect(() => {
    //scroll to page top
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess)
      window.scrollTo({ top: 0 });
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError)
      setSuccessHandlerObj({ isSuccess: false, message: "" });
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      setErrorHandlerObj({ hasError: false, message: "" });
  }, [successHandlerObj]);
  return (
    <div className="contact-admin-container">
      <Top pageSummaryMessage="Get in touch" />
      <div className="contact-admin-container--inner">
        <Sidebar activeName={"Contact admin"} />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        {/* Success Handler Obj Wrapper */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="auth-error-success-message-wrapper"
        />

        <section className="contact-admin-container-main-section">
          {/* Top Container */}
          <div className="contact-admin--top-container">
            {/* Top Box Wrapper / Support Box */}
            <div className="contact-admin-top-box-wrapper">
              <div className="contact-admin-top-box-wrapper--inner">
                <div className="box-title-wrapper">Support</div>
                <div className="box-value-wrapper contact-boxes-value-wrapper">
                  {/* Email */}
                  <div className="company-email">
                    <a
                      href="mailto:wecare@heirsinsurance.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      wecare@heirsinsurance.com
                    </a>
                  </div>
                  {/* Phone */}
                  <div className="company-phone">
                    <a
                      href="tel:07004347746"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      0700 434 7746
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Top Box Wrapper / Address Box */}
            <div className="contact-admin-top-box-wrapper">
              <div className="contact-admin-top-box-wrapper--inner">
                <div className="box-title-wrapper">Address</div>
                <div className="box-value-wrapper contact-boxes-value-wrapper">
                  <div className="div">
                    Plot 107B Ajose, Adeogun Street, Victoria Island, Lagos.
                  </div>
                </div>
              </div>
            </div>
            {/* Top Box Wrapper / Work Hours Box */}
            <div className="contact-admin-top-box-wrapper">
              <div className="contact-admin-top-box-wrapper--inner">
                <div className="box-title-wrapper">Work hours</div>
                <div className="box-value-wrapper contact-boxes-value-wrapper">
                  <div className="div">Monday - Friday: 9 a.m - 5 p.m</div>
                </div>
              </div>
            </div>
          </div>
          {/* Bottom Container */}
          <div className="contact-admin--bottom-container">
            {/* /Form Wrapper */}
            <div className="message-to-admin-form-wrapper">
              <form>
                <div className="message-form-group">
                  <textarea
                    placeholder="or type a direct mail here..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </div>

                <div className="submit-message-button-wrapper">
                  <button onClick={(e) => handleSubmitMessage(e)}>Send</button>
                  <div className="submit-btn-loading-spinner-wrapper">
                    {isLoading && <LoadingSpinner />}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactAdmin;
